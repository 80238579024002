import styled from "styled-components";
import Imagem_Client from "../../img/ClienteImg.png";
import Img_Fundo from "../../img/ImgDeFundo.png";
import "../../fonts/Raleway";
import "../../fonts/Inter";
import "../../fonts/Poppins";

const ClientLoginStyle = {
  Corpo: styled.div`
    background-image: url(${Img_Fundo});
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin: 0;
    padding: 0;
  `,

  Imagens: styled.div`
    display: grid;
    justify-content: start;
    grid-template-rows: 1fr 2fr;
    height: 100vh;
  `,

  ImgCook: styled.div`
    text-align: center;
    background-image: url(${Imagem_Client});
    background-repeat: no-repeat;
    background-size: 100%;
    width: 70vh;
    height: 75vh;
    margin-left: 10vh;
    margin-top: 12vh;
  `,

  Formulario: styled.div`
    display: grid;
    justify-content: center;
    justify-items: center;
    align-content: center;
    height: 100vh;
    margin-top: -6vh;
  `,

  DivDoFrom: styled.div`
    display: grid;
    grid-template-rows: 10% 90%;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    height: 80vh;
    width: 70vh;
  `,

  Titulo1: styled.p`
    text-align: center;
    color: #26874e;
    font-style: normal;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    font-size: 35px;
    margin-top: 6vh;
  `,

  Lbl: styled.label`
    font-weight: 200;
    font-size: 20px;
    color: #4da768;
    font-family: "Poppins", sans-serif;
  `,

  Form1: styled.form`
    display: grid;
    grid-template-rows: 7;
    width: 45vh;
  `,

  TxtName: styled.input`
    width: 400px;
    height: 34px;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 15px 0;
    border: 1px solid #1b2029;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    margin-bottom: 6vh;
    font-family: "Poppins", sans-serif;
    font-size: 14px;

    &:focus {
      border: solid 1px #26874e;
      font-weight: 600;
      outline: none;
    }
  `,

  TxtNum: styled.input`
    width: 400px;
    height: 34px;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 15px 0;
    border: 1px solid #1b2029;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    margin-bottom: 6vh;
    font-family: "Poppins", sans-serif;
    font-size: 14px;

    &:focus {
      border: solid 1px #26874e;
      font-weight: 600;
      outline: none;
    }
  `,

  TxtChave: styled.input`
    width: 400px;
    height: 34px;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 15px 0;
    border: 1px solid #1b2029;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    margin-bottom: 6vh;
    font-family: "Poppins", sans-serif;
    font-size: 14px;

    &:focus {
      border: solid 1px #26874e;
      font-weight: 600;
      outline: none;
    }
  `,

  BtnAcessar: styled.button`
    background-color: #26874e;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    margin-left: -2vh;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 17vh;
    justify-self: center;

    &:hover {
      background-color: #99e89d;
      color: #1b2029;
      transition: all 0.5s;
    }
  `,
};

export default ClientLoginStyle;