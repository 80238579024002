import React, { useState, useEffect } from "react";
import {
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import RodapeStyle from "../../assets/styles/Footer/FooterStyle.js";
import Logo from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";

const {
  Rodape,
  ConteudoRodape,
  CardsRodape,
  FraseDeEfeito,
  TitulosRodape,
  SubtitulosRodape,
  RedesSociaisIcons,
  DireitosAutorais,
  GreenText,
  FooterLink,
} = RodapeStyle;

function Footer() {
  const [ano, setAno] = useState("");

  useEffect(() => {
    const data = new Date();

    setAno(data.getFullYear());
  }, []);

  const navigate = useNavigate();

  const handleNavigation = (route) => {
    if (route === "/contato" || route === "/missaovisaovalores") {
      const targetRoute = "/";
      const targetSectionId = route === "/contato" ? "contato" : "missaovisaovalores";
      localStorage.setItem("targetSection", `${targetSectionId}`);

      const targetSection = document.getElementById(targetSectionId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }

      route = targetRoute;
    }

    navigate(route);
  };

  return (
    <Rodape>
      <ConteudoRodape>
        <CardsRodape>
          <img src={Logo} alt="logo" />
          <FraseDeEfeito>
            <GreenText>Seu pedido, nossa demanda.</GreenText>
          </FraseDeEfeito>
        </CardsRodape>

        <CardsRodape>
          <TitulosRodape>Já sou membro:</TitulosRodape>
          <SubtitulosRodape onClick={() => handleNavigation("/logingarcom")}>
            Entrar como Garçom
          </SubtitulosRodape>
          <SubtitulosRodape
            onClick={() => handleNavigation("/loginrestaurante")}
          >
            Entrar como Restaurante
          </SubtitulosRodape>
          <SubtitulosRodape onClick={() => handleNavigation("/logincozinha")}>
            Entrar como Cozinha
          </SubtitulosRodape>
          <SubtitulosRodape onClick={() => handleNavigation("/")}>
            Termos de Uso e Políticas de Privacidade
          </SubtitulosRodape>
        </CardsRodape>

        <CardsRodape>
          <TitulosRodape>Sobre nós:</TitulosRodape>
          <SubtitulosRodape onClick={() => handleNavigation("/missaovisaovalores")}>
            Visão da empresa
          </SubtitulosRodape>
          <SubtitulosRodape onClick={() => handleNavigation("/missaovisaovalores")}>
            Nossa Missão
          </SubtitulosRodape>
          <FooterLink
            href="mailto:faleconoscoquickfood@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <SubtitulosRodape>
              faleconoscoquickfood@gmail.com
            </SubtitulosRodape>{" "}
          </FooterLink>
          <SubtitulosRodape onClick={() => handleNavigation("/contato")}>
            Fale conosco
          </SubtitulosRodape>
        </CardsRodape>

        <CardsRodape>
          <TitulosRodape>Nossas redes sociais:</TitulosRodape>
          <div>
            <FooterLink
              href="https://www.instagram.com/quickfood_tech/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <RedesSociaisIcons icon={faInstagram} />{" "}
            </FooterLink>
            <FooterLink
              href="https://twitter.com/quickfood_tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <RedesSociaisIcons icon={faTwitter} />{" "}
            </FooterLink>
            <FooterLink
              href="https://www.youtube.com/@QuickFood-Canal"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <RedesSociaisIcons icon={faYoutube} />{" "}
            </FooterLink>
          </div>
        </CardsRodape>
      </ConteudoRodape>
      <DireitosAutorais>
        {`© QuickFood - ${ano}. Todos os direitos reservados.`}
      </DireitosAutorais>
    </Rodape>
  );
}

export default Footer;
