import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import style from "./style.module.css";
import {
  faArrowRight,
  faBuilding,
  faGift,
  faUserTie,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";

function CustomDropdownCadastro() {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <Dropdown id={style.containerDropdown}>
      <FontAwesomeIcon icon={faGift} id={style.icone} />
      <DropdownButton id={style.btnPrincipal} title="CADASTRE-SE">
        <Link
          className={style.link}
          onClick={() => handleNavigation("/cadastrorestaurante")}
        >
          <div className={style.containerIcons}>
            <div className={style.greyContainer}>
              <FontAwesomeIcon icon={faBuilding} className={style.leftIcons} />
            </div>
            <Dropdown.Item href="#" className={style.tiposLogin}>
              Restaurante
            </Dropdown.Item>
            <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
          </div>
        </Link>
        <Link
          className={style.link}
          onClick={() => handleNavigation("/cadastrocozinha")}
        >
          <div className={style.containerIcons}>
            <div className={style.greyContainer}>
              <FontAwesomeIcon icon={faUtensils} className={style.leftIcons} />
            </div>
            <Dropdown.Item href="#" className={style.tiposLogin}>
              Cozinha
            </Dropdown.Item>
            <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
          </div>
        </Link>
        <Link
          className={style.link}
          onClick={() => handleNavigation("/cadastrogarcom")}
        >
          <div className={style.containerIcons}>
            <div className={style.greyContainer}>
              <FontAwesomeIcon icon={faUserTie} className={style.leftIcons} />
            </div>
            <Dropdown.Item href="#" className={style.tiposLogin}>
              Garçom
            </Dropdown.Item>
            <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
          </div>
        </Link>
      </DropdownButton>
    </Dropdown>
  );
}

export default CustomDropdownCadastro;