import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toast } from "bootstrap";
import Logo from "../../assets/img/logo2.png";

const ToastComponent = () => {
  useEffect(() => {
    const toastLocalStorage = localStorage.getItem("toast");
    if (!toastLocalStorage) {
      const toastElement = document.querySelector(".toast");
      const toast = new Toast(toastElement);
      toast.show();
    }

    localStorage.setItem("toast", true);
  }, []);

  return (
    <div
      className="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <img
          src={Logo}
          className="rounded me-2"
          alt="..."
          width="50px"
          height="50px"
        />
        <strong className="me-auto">Administração</strong>
        <small>Now</small>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div className="toast-body">Seja bem-vindo(a) à QuickFood!</div>
    </div>
  );
};

export default ToastComponent;
