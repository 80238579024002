import styled from "styled-components";

const StyleRadioButton = {
  ContainerButton: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
  `,

  TextoSimOuNao: styled.h6`
    margin: 0;
    font-family: Poppins, "sans-serif";
    font-size: 14px;
  `,

  Check: styled.input`
    &:checked {
      background-color: #7da768;
    }
  `,
};

const { ContainerButton, TextoSimOuNao, Check } = StyleRadioButton;

function RadioButton() {
  return (
    <>
      <ContainerButton className="form-check form-check-inline">
        <Check
          className="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio1"
          value="option1"
        />
        <TextoSimOuNao className="form-check-label" for="inlineRadio1">
          Sim
        </TextoSimOuNao>
      </ContainerButton>
      <ContainerButton className="form-check form-check-inline">
        <Check
          className="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio2"
          value="option2"
        />
        <TextoSimOuNao className="form-check-label" for="inlineRadio2">
          Não
        </TextoSimOuNao>
      </ContainerButton>
    </>
  );
}

export default RadioButton;