import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import StyleHeaderDetalhes from "../../../assets/styles/Pedidos/Header/StyleHeaderDetalhes";
import { useNavigate } from "react-router-dom";

const { ConteudoPrincipal, Logo, Icon } = StyleHeaderDetalhes;

function HeaderDetalhes({ imgLogo, backGroundColor }) {
  const navigate = useNavigate();

  const backPage = () => {
    navigate("/cliente/cardapio");
  };

  return (
    <>
      <ConteudoPrincipal backGroundColor={backGroundColor}>
        <Icon icon={faArrowLeft} onClick={backPage} />
        <Logo src={imgLogo} alt="logo" />
      </ConteudoPrincipal>
    </>
  );
}

export default HeaderDetalhes;