import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import style from "./style.module.css";
import {
  faArrowRight,
  faBuilding,
  faMugHot,
  faUser,
  faUserTie,
  faUtensils,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";

function CustomDropdownLogin() {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <Dropdown id={style.containerDropdown}>
      <FontAwesomeIcon icon={faUser} id={style.icone} />
      <DropdownButton id={style.btnPrincipal} title="LOG IN">
        <Link
          className={style.link}
          onClick={() => handleNavigation("/loginrestaurante")}
        >
          <div className={style.containerIcons}>
            <div className={style.greyContainer}>
              <FontAwesomeIcon icon={faBuilding} className={style.leftIcons} />
            </div>
            <Dropdown.Item href="#" className={style.tiposLogin}>
              Restaurante
            </Dropdown.Item>
            <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
          </div>
        </Link>
        <Link
          className={style.link}
          onClick={() => handleNavigation("/logincozinha")}
        >
          <div className={style.containerIcons}>
            <div className={style.greyContainer}>
              <FontAwesomeIcon icon={faUtensils} className={style.leftIcons} />
            </div>
            <Dropdown.Item href="#" className={style.tiposLogin}>
              Cozinha
            </Dropdown.Item>
            <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
          </div>
        </Link>
        <Link
          className={style.link}
          onClick={() => handleNavigation("/logingarcom")}
        >
          <div className={style.containerIcons}>
            <div className={style.greyContainer}>
              <FontAwesomeIcon icon={faUserTie} className={style.leftIcons} />
            </div>
            <Dropdown.Item href="#" className={style.tiposLogin}>
              Garçom
            </Dropdown.Item>
            <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
          </div>
        </Link>
        <Link
          className={style.link}
          onClick={() => handleNavigation("/logincliente")}
        >
          <div className={style.containerIcons}>
            <div className={style.greyContainer}>
              <FontAwesomeIcon icon={faMugHot} className={style.leftIcons} />
            </div>
            <Dropdown.Item href="#" className={style.tiposLogin}>
              Cliente
            </Dropdown.Item>
            <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
          </div>
        </Link>
        <Link
          className={style.link}
          onClick={() => handleNavigation("/loginadministrator")}
        >
          <div className={style.containerIcons}>
            <div className={style.greyContainer}>
              <FontAwesomeIcon icon={faKey} className={style.leftIcons} />
            </div>
            <Dropdown.Item href="#" className={style.tiposLogin}>
              Administrador
            </Dropdown.Item>
            <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
          </div>
        </Link>
      </DropdownButton>
    </Dropdown>
  );
}

export default CustomDropdownLogin;