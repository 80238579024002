import styled from "styled-components";

const CategoriaCode = {
  ContainerTextoLista: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  `,

  ListStyle: styled.div`
    background-color: #99e89d71;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    @media screen and (max-width: 392px) {
      width: 25px;
      height: 25px;
    }
  `,

  NomeCategoria: styled.h4`
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1000px) {
      font-size: 14px;
    }

    @media screen and (max-width: 392px) {
      font-size: 12px;
    }
  `,

  ImgStar: styled.img`
    width: 16px;
    height: 16px;
    object-fit: cover;

    @media screen and (max-width: 392px) {
      width: 14px;
      height: 14px;
    }
  `,
};

function Categoria({ imagem, titulo }) {
  return (
    <CategoriaCode.ContainerTextoLista>
      <CategoriaCode.ListStyle>
        <CategoriaCode.ImgStar src={imagem} />
      </CategoriaCode.ListStyle>
      <CategoriaCode.NomeCategoria>{titulo}</CategoriaCode.NomeCategoria>
    </CategoriaCode.ContainerTextoLista>
  );
}

export default Categoria;