import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StyleNavbar from "../../../assets/styles/Admin/Sidebar/StyleNavbar.js";
import {
  faBellConcierge,
  faPlus,
  faTv,
  faUtensils,
  faBuilding,
  faUser,
  faImage,
  faTags,
  faBacon,
  faUserTie,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/img/logo2.png";
import {
  IconesOpcoes,
  IconesBtns,
} from "../../../assets/styles/Admin/AdminStyle.js";

const {
  Navbar,
  ContainerDono,
  Nome,
  Funcao,
  FotoPerfil,
  BtnNovoFunc,
  Opcoes,
  TextoOpcoes,
  ContainerControles,
  StyledLink,
} = StyleNavbar;

function NavBarAdmin() {
  const location = useLocation();
  const navigate = useNavigate();
  const [nome_usuario, setNomeUsuario] = useState("");
  const [funcao_usuario, setFuncaoUsuario] = useState("");
  const [imagem_perfil, setImagemPerfil] = useState("");

  useEffect(() => {
    const nome = localStorage.getItem("nome");
    const funcao = localStorage.getItem("funcao");
    const imagem_perfil_buffer = localStorage.getItem("imagem_perfil_buffer");
    const imagem_perfil_mimeType = localStorage.getItem(
      "imagem_perfil_mimeType"
    );
    const imagem_perfil_buffer_object = JSON.parse(imagem_perfil_buffer);

    let imageUrl = "";
    if (
      imagem_perfil_buffer !== null &&
      imagem_perfil_mimeType !== null &&
      imagem_perfil_buffer !== "" &&
      imagem_perfil_mimeType !== ""
    ) {
      const uint8Array = new Uint8Array(imagem_perfil_buffer_object.data);
      const blob = new Blob([uint8Array], { type: imagem_perfil_mimeType });
      imageUrl = URL.createObjectURL(blob);
    }

    setNomeUsuario(nome);
    setFuncaoUsuario(funcao);
    setImagemPerfil(imageUrl);
  }, []);

  return (
    <>
      <Navbar>
        <ContainerDono>
          <FotoPerfil
            src={imagem_perfil !== "" ? imagem_perfil : logo}
            alt="perfil"
          />
          <div>
            <Nome>{nome_usuario}</Nome>
            <Funcao>{funcao_usuario}</Funcao>
          </div>
        </ContainerDono>
        <BtnNovoFunc onClick={() => navigate("/admin/administradores")}>
          Novo Administrador <IconesBtns icon={faPlus} />
        </BtnNovoFunc>
        {/* <BtnNovoFunc>Solicitações Cozinha <IconesBtns icon={faBell} /></BtnNovoFunc> */}
        <ContainerControles>
          <StyledLink to={"/admin"}>
            <Opcoes
              style={{
                color: `${location.pathname === "/admin" ? "#4da768" : ""}`,
              }}
            >
              <IconesOpcoes icon={faTv} />
              <TextoOpcoes>Dashboard</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          {/*<Opcoes>
                        <IconesOpcoes icon={faChartLine} />
                        <TextoOpcoes>Gráficos</TextoOpcoes>
                    </Opcoes>
                    <Opcoes>
                        <IconesOpcoes icon={faTable} />
                        <TextoOpcoes>Tabelas</TextoOpcoes>
                    </Opcoes>
                    <Opcoes>
                        <IconesOpcoes icon={faStar} />
                        <TextoOpcoes>Avaliações</TextoOpcoes>
                    </Opcoes>*/}
          <StyledLink to={"/admin/administradores"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/administradores"
                    ? "#4da768"
                    : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faUserTie} />
              <TextoOpcoes>Administradores</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          <StyledLink to={"/admin/restaurantes"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/restaurantes" ? "#4da768" : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faBuilding} />
              <TextoOpcoes>Restaurantes</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          <StyledLink to={"/admin/garcons"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/garcons" ? "#4da768" : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faBellConcierge} />
              <TextoOpcoes>Garçons</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          <StyledLink to={"/admin/cozinhas"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/cozinhas" ? "#4da768" : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faUtensils} />
              <TextoOpcoes>Cozinhas</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          <StyledLink to={"/admin/clientes"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/clientes" ? "#4da768" : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faUser} />
              <TextoOpcoes>Clientes</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          <StyledLink to={"/admin/imagens"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/imagens" ? "#4da768" : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faImage} />
              <TextoOpcoes>Imagens</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          <StyledLink to={"/admin/categorias"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/categorias" ? "#4da768" : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faTags} />
              <TextoOpcoes>Categorias</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          <StyledLink to={"/admin/acrescimos"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/acrescimos" ? "#4da768" : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faBacon} />
              <TextoOpcoes>Acréscimos</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          <StyledLink to={"/admin/itensadicionais"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/itensadicionais"
                    ? "#4da768"
                    : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faUtensils} />
              <TextoOpcoes>Itens Adicionais</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          <StyledLink to={"/admin/qrcodes"}>
            <Opcoes
              style={{
                color: `${
                  location.pathname === "/admin/qrcodes" ? "#4da768" : ""
                }`,
              }}
            >
              <IconesOpcoes icon={faQrcode} />
              <TextoOpcoes>QR Codes</TextoOpcoes>
            </Opcoes>
          </StyledLink>
          {/*<Opcoes>
                            <IconesOpcoes icon={faRefresh} />
                            <TextoOpcoes>Desfazer vínculos</TextoOpcoes>
                        </Opcoes>
                    <Opcoes>
                        <IconesOpcoes icon={faKey} />
                        <TextoOpcoes>Chave de acesso</TextoOpcoes>
                    </Opcoes>
                    <Opcoes>
                        <IconesOpcoes icon={faTrash} />
                        <TextoOpcoes>Excluir Conta</TextoOpcoes>
                    </Opcoes>*/}
        </ContainerControles>
      </Navbar>
    </>
  );
}

export default NavBarAdmin;
