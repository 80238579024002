import styled from "styled-components";
import Img_Fundo from "../../img/ImgDeFundo.png";
import Imagem_waiter from "../../img/img_de_fundo_garcom.png";

const WaiterLoginStyle = {
  Corpo: styled.div`
    background-image: url(${Img_Fundo});
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin: 0;
    padding: 0;
  `,

  Imagens: styled.div`
    display: grid;
    justify-content: start;
    grid-template-rows: 1fr 2fr;
    height: 100vh;
  `,

  Logo: styled.div`
    text-align: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 15vh;
    height: 8vh;
    margin-top: 4vh;
    margin-left: 4vh;
  `,

  ImgWaiter: styled.div`
    text-align: center;
    background-image: url(${Imagem_waiter});
    background-repeat: no-repeat;
    background-size: 100%;
    width: 65vh;
    height: 70vh;
    margin-left: 8vw;
    margin-top: -20vh;
  `,

  Formulario: styled.div`
    display: grid;
    justify-content: center;
    justify-items: center;
    align-content: center;
    height: 100vh;
  `,

  DivDoFrom: styled.div`
    display: grid;
    grid-template-rows: 15% 10% 70%;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 10px;
    height: 80vh;
    width: 70vh;
  `,

  DivInputIcon: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  `,

  Titulo1: styled.p`
    text-align: center;
    color: #26874e;
    font-style: normal;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
  `,

  Pages: styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: center;
    justify-items: center;
    width: 40vh;
    align-items: center;
    justify-self: center;
    border-radius: 6px;
    padding: 8px;
  `,

  Text1: styled.a`
    text-decoration: none;
    color: black;
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-right: 10px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 0;
      height: 2px;
      background-color: #26874e;
      transform: translateX(-50%);
      transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    &:hover::after {
      width: 70%;
      transform: translateX(-50%);
    }

    &:hover {
      color: black;
    }
  `,

  Text2: styled.a`
    text-decoration: none;
    color: black;
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-right: 10px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 0;
      height: 2px;
      background-color: #26874e;
      transform: translateX(-50%);
      transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    &:hover::after {
      width: 70%;
      transform: translateX(-50%);
    }

    &:hover {
      color: black;
    }
  `,

  Text3: styled.a`
    text-decoration: none;
    color: black;
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 0;
      height: 2px;
      background-color: #26874e;
      transform: translateX(-50%);
      transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    &:hover::after {
      width: 70%;
      transform: translateX(-50%);
    }

    &:hover {
      color: black;
    }
  `,

  Lbl: styled.label`
    font-weight: 200;
    font-size: 18px;
    color: #4da768;
    font-family: "Poppins", sans-serif;
  `,

  Form1: styled.form`
    display: grid;
    grid-template-rows: repeat(7, auto);
  `,

  TxtName: styled.input`
    width: 400px;
    height: 34px;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 15px 0;
    border: 1px solid #1b2029;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    margin-bottom: 4vh;
    font-family: "Poppins", sans-serif;
    font-size: 14px;

    &:focus {
      border: solid 1px #26874e;
      font-weight: 600;
      outline: none;
    }
  `,

  TxtSenha: styled.input`
    width: 400px;
    height: 34px;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 15px 0;
    border: 1px solid #1b2029;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-right: 10px;

    &:focus {
      border: solid 1px #26874e;
      font-weight: 600;
      outline: none;
    }
  `,

  ForgetSenha: styled.a`
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-size: 16px;
    color: #4da768;
    text-decoration: none;
    margin-bottom: 4vh;
    transition: all 0.5s;

    &:hover {
      transition: all 0.5s;
      color: #006633;
      text-decoration: underline #4da768;
    }
  `,

  BtnLogar: styled.button`
    background-color: #26874e;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    color: white;
    padding: 5px 5px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 15vh;
    justify-self: center;
    transition: all 0.5s;

    &:hover {
      background-color: #99e89d;
      color: #1b2029;
      transition: all 0.5s;
    }
  `,

  CreateConta: styled.a`
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-size: 18px;
    color: #4da768;
    text-decoration: none;
    justify-self: center;
    transition: all 0.5s;

    &:hover {
      transition: all 0.5s;
      color: #006633;
    }
  `,
};

export default WaiterLoginStyle;