import { faBan, faCheck, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StylePagamentoPendente = {
  ContainerItem: styled.div`
    width: 300px;
    height: 80px;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 60% 40%;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  `,

  ContainerNomeEMesa: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `,

  ContainerButtons: styled.div`
    justify-self: flex-end;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 10px;
  `,

  NomeCliente: styled.h6`
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    font-size: 16px;
    margin-left: 5px !important;
  `,

  NumeroMesaCliente: styled.h6`
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    font-size: 14px;
  `,

  ButtonSucess: styled.button`
    border: solid 1px #4da768;
    background-color: #fff;
    border-radius: 30px;
    width: 40px;
    height: 35px;
    color: #4da768;
  `,

  ButtonCancel: styled.button`
    border: solid 1px #ca6267;
    background-color: #fff;
    border-radius: 30px;
    width: 40px;
    height: 35px;
    color: #ca6267;
  `,

  Icon: styled(FontAwesomeIcon)``,

  ContainerNome: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #1b2029;
  `,

  IconUser: styled(FontAwesomeIcon)`
    font-size: 14px;
    color: #1b2029ae;
  `,

  ValorTotal: styled.h6`
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    font-size: 14px;
  `,
};

const {
  ContainerItem,
  ContainerNome,
  IconUser,
  ValorTotal,
  ContainerNomeEMesa,
  ContainerButtons,
  NomeCliente,
  NumeroMesaCliente,
  ButtonSucess,
  ButtonCancel,
  Icon,
} = StylePagamentoPendente;

function PagamentoPendente({ nomeUsuario, numMesa, valorTotal }) {
  return (
    <>
      <ContainerItem>
        <ContainerNomeEMesa>
          <ContainerNome>
            <IconUser icon={faUser} />
            <NomeCliente>{nomeUsuario}</NomeCliente>
          </ContainerNome>
          <NumeroMesaCliente>Número da mesa: {numMesa}</NumeroMesaCliente>
          <ValorTotal>Valor total: R${valorTotal}</ValorTotal>
        </ContainerNomeEMesa>
        <ContainerButtons>
          <ButtonSucess>
            <Icon icon={faCheck} />
          </ButtonSucess>
          <ButtonCancel>
            <Icon icon={faBan} />
          </ButtonCancel>
        </ContainerButtons>
      </ContainerItem>
    </>
  );
}

export default PagamentoPendente;