import axios from "axios";
import KitchenLoginStyle from "../../../assets/styles/Kitchen/Kitchen_Login_Style";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import BackButton from "../../Props/BackButton";

const {
  Corpo,
  Imagens,
  Logo,
  ImgCook,
  Formulario,
  DivDoFrom,
  DivInputIcon,
  Titulo1,
  Pages,
  Text1,
  Text2,
  Text3,
  Lbl,
  Form1,
  TxtName,
  TxtSenha,
  ForgetSenha,
  BtnLogar,
  CreateConta,
} = KitchenLoginStyle;

function KitchenBody() {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");
  const [exibirSenha, setType] = useState("password");
  const [btnEye, setEye] = useState(faEyeSlash);

  const handleClick = (event) => {
    event.preventDefault();

    const dados = {
      nome: nome,
      senha: senha,
    };

    axios
      .post(
        "https://www.api.qftech.com.br/api/authentication/loginkitchen",
        JSON.stringify(dados),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.error === "Campo NOME inválido") {
          alert(response.data.error);
          $("#nome").focus();
        } else if (response.data.error === "SENHA incorreta") {
          alert(response.data.error);
          $("#senha").focus();
        } else if (response.data.success) {
          // Limpar campos
          setNome("");
          setSenha("");

          localStorage.removeItem("token");
          localStorage.removeItem("nome");
          localStorage.removeItem("funcao");
          localStorage.removeItem("imagem_perfil_buffer");
          localStorage.removeItem("imagem_perfil_mimeType");
          localStorage.removeItem("mesa");
          //setCookie("token", `${response.data.token}`, 1);
          localStorage.setItem("token", `${response.data.token}`);
          localStorage.setItem("nome", `${response.data.nome}`);
          localStorage.setItem("funcao", `${response.data.funcao}`);

          // Redirecionar para a rota do React
          navigate("/cozinha/gestao_pedidos");
        } else {
          alert(response.data.error)
        }
      })
      .catch((err) => {
        if (err.response.data === "Complete o campo NOME!") {
          alert(err.response.data);
          $("#nome").focus();
        } else if (err.response.data === "Complete o campo SENHA!") {
          alert(err.response.data);
          $("#senha").focus();
        } else {
          alert(err.response.data)
        }
      });
  };

  const mostraSenha = () => {
    setType(exibirSenha === "text" ? "password" : "text");
    setEye(btnEye === faEyeSlash ? faEye : faEyeSlash);
  };

  return (
    <Corpo>
      <BackButton />
      <Imagens>
        <Logo />
        <ImgCook />
      </Imagens>
      <Formulario>
        <DivDoFrom>
          <Titulo1>WELCOME BACK!</Titulo1>
          <Pages>
            <Text1 onClick={() => handleNavigation("/loginrestaurante")}>
              Restaurante
            </Text1>
            <Text2 onClick={() => handleNavigation("/logingarcom")}>
              Garçom
            </Text2>
            <Text3 onClick={() => handleNavigation("/logincozinha")}>
              Cozinha
            </Text3>
          </Pages>
          <Form1>
            <Lbl> Nome: </Lbl>
            <TxtName
              type="text"
              name="nome"
              id="nome"
              autoFocus
              placeholder="John Doe"
              onChange={(event) => setNome(event.target.value)}
            />
            <Lbl> Senha: </Lbl>
            <DivInputIcon>
              <TxtSenha
                type={exibirSenha}
                name="senha"
                id="senha"
                placeholder="••••••"
                onChange={(event) => setSenha(event.target.value)}
              />
              <FontAwesomeIcon icon={btnEye} onClick={mostraSenha} />
            </DivInputIcon>
            <ForgetSenha onClick={() => handleNavigation("/esquecisenha")}>
              Esqueci minha senha!
            </ForgetSenha>
            <BtnLogar onClick={handleClick} type="button">
              {" "}
              LOGAR{" "}
            </BtnLogar>
            <CreateConta href="">Crie uma conta agora! </CreateConta>
          </Form1>
        </DivDoFrom>
      </Formulario>
    </Corpo>
  );
}

export default KitchenBody;
