import {
  faChartLine,
  faFileInvoice,
  faGear,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import StyleNavBar from "../../../assets/styles/GestaoPedidos_Cozinha/Navbar/StyleNavbar";

const { ConteudoPrincipal, Icons } = StyleNavBar;

function Navbar() {
  return (
    <>
      <ConteudoPrincipal>
        <Icons icon={faHome} />
        <Icons icon={faFileInvoice} />
        <Icons icon={faChartLine} />
        <Icons icon={faGear} />
      </ConteudoPrincipal>
    </>
  );
}

export default Navbar;