import StyleDetalhes from "../../../assets/styles/Pedidos/Detalhes/StyleDetalhes";
import HeaderDetalhes from "../Header/HeaderDetalhes";
import ImgHamburguer from "../../../assets/img/hamburguer2.png";
import ItensAMaisPedidos from "../../Props/ItensAMaisPedidos";
import ItensOpcionaisPedidos from "../../Props/ItensOpcionaisPedidos";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const {
  ConteudoPrincipal,
  ContainerTamanhoProduto,
  ImgProduto,
  TextoTamanhoProduto,
  ContainerInfoProduto,
  DefaultTitle,
  DefaultSubtitle,
  ContainerPersonalizacaoPedido,
  ContainerImgProduto,
  InputObservacao,
  ContainerAcrescentarItens,
  ContainerOpcionais,
  ContainerRevisarInfos,
  ContainerQtdeEPreco,
  ContainerQtdeItensAMais,
  IconMinusPlus,
  TextoQtdeAMais,
  TextoValorTotal,
  BtnContinuar,
} = StyleDetalhes;

// pensar em algo para filtrar os itens a mais que o usuário pode escolher
// ex: Se for da Categoria Hamburguer -> Bacon, etc... - Se for da Categoria Sorvetes -> Calda de chocolate
// além disso pensar em alguma for de colocar um preço pra cada item a mais

function Detalhes() {
  // const [background, setBackGround] = useState()
  // const [logo, setLogo] = useState()
  // const [imgProduto, setImgProduto] = useState()
  // const [tamanhoProduto, setTamanhoProduto] = useState()
  // const [nomeProduto, setNomeProduto] = useState()
  // const [ingredientes, setIngredientes] = useState()
  // const [itensAMais, setItensAMais] = useState()
  const [qtdeItensAMais, setQtdeItensAMais] = useState(1);
  const [valorTotal, setValorTotal] = useState(0);
  const [imagem_produto, setImagemProduto] = useState("");
  const [nome_produto, setNomeProduto] = useState("");
  const [ingredientes, setIngredientes] = useState("");
  const cor_primaria_rest = localStorage.getItem("cor_primaria_restaurante");
  const imagem_logo_rest = localStorage.getItem("imagem_logo_restaurante");
  const navigate = useNavigate();

  const changePage = () => {
    navigate("/cliente/incrementos_pedido");
  };

  useEffect(() => {
    //setLoading(true);

    let url = window.location.href;
    let urlObj = new URL(url);
    let id_produto_param = urlObj.searchParams.get("id_produto");
    let findProduct = {
      id_produto: id_produto_param,
    };

    axios
      .post(
        "https://www.api.qftech.com.br/api/filter/filterproductmenubyid",
        JSON.stringify(findProduct),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const uint8Array = new Uint8Array(response.data[0].buffer.data);
        const blob = new Blob([uint8Array], {
          type: response.data[0].mimeType,
        });
        const imageUrl = URL.createObjectURL(blob);

        setImagemProduto(imageUrl);
        setNomeProduto(response.data[0].nome_produto);
        setIngredientes(response.data[0].ingredientes);
      })
      .catch((err) => {
        //showErrorSearch();
        //setErrorMessage(err.response.data);
      })
      .finally(() => {
        //setLoading(false);
      });
  }, []);

  return (
    <>
      <HeaderDetalhes
        imgLogo={imagem_logo_rest}
        backGroundColor={cor_primaria_rest}
      />

      <ConteudoPrincipal>
        <ContainerImgProduto>
          <ImgProduto src={imagem_produto} alt="imagem-produto" />
          <ContainerTamanhoProduto>
            <TextoTamanhoProduto>P</TextoTamanhoProduto>
          </ContainerTamanhoProduto>
        </ContainerImgProduto>
        <ContainerInfoProduto>
          <DefaultTitle>{nome_produto}</DefaultTitle>
          <DefaultSubtitle>{ingredientes}</DefaultSubtitle>
        </ContainerInfoProduto>
        <ContainerPersonalizacaoPedido>
          <DefaultTitle>Personalize seu pedido!</DefaultTitle>
          <DefaultSubtitle>
            Gostaria de fazer uma observação no pedido?
          </DefaultSubtitle>
          <InputObservacao type="text" placeholder="Sem tomate" />
        </ContainerPersonalizacaoPedido>
        <ContainerAcrescentarItens>
          <DefaultTitle>Deseja acrescentar algo?</DefaultTitle>
          <DefaultSubtitle>
            Gostaria de adicionar itens extras ao seu pedido?
          </DefaultSubtitle>
          <ItensAMaisPedidos nome={"Bacon extra"} preco={"R$ 3,90"} />
          <ItensAMaisPedidos nome={"Queijo extra"} preco={"R$ 1,90"} />
          <ItensAMaisPedidos nome={"Cebola extra"} preco={"R$ 2,90"} />
          <ItensAMaisPedidos nome={"Cebola extra"} preco={"R$ 2,90"} />
          <ItensAMaisPedidos nome={"Cebola extra"} preco={"R$ 2,90"} />
          <ItensAMaisPedidos nome={"Cebola extra"} preco={"R$ 2,90"} />
          <ItensAMaisPedidos nome={"Cebola extra"} preco={"R$ 2,90"} />
        </ContainerAcrescentarItens>
        <ContainerOpcionais>
          <DefaultTitle>Escolha:</DefaultTitle>
          <ItensOpcionaisPedidos nome={"Sachê de Mostarda"} />
          <ItensOpcionaisPedidos nome={"Sachê de Ketchup"} />
          <ItensOpcionaisPedidos nome={"Guardanapo"} />
        </ContainerOpcionais>
        <ContainerRevisarInfos>
          <ContainerQtdeEPreco>
            <ContainerQtdeItensAMais>
              <IconMinusPlus icon={faMinus} />
              <TextoQtdeAMais>{qtdeItensAMais}</TextoQtdeAMais>
              <IconMinusPlus icon={faPlus} />
            </ContainerQtdeItensAMais>
            <TextoValorTotal>{`R$ ${valorTotal}`}</TextoValorTotal>
          </ContainerQtdeEPreco>
          <BtnContinuar onClick={changePage}>CONTINUAR</BtnContinuar>
        </ContainerRevisarInfos>
      </ConteudoPrincipal>
    </>
  );
}

export default Detalhes;