import styled from "styled-components";

const StyleSucessMoney = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    min-height: 90vh;
    background-color: #4da768;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    gap: 30px;
  `,

  ImagePix: styled.img`
    margin-top: 30px;
  `,

  Title: styled.h2`
    font-family: Poppins, "sans-serif";
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  `,

  Texto: styled.h4`
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: center;
  `,

  BtnCancelar: styled.button`
    width: 80%;
    border-radius: 8px;
    border: 0.8px solid #fff;
    background: #4da768;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    font-family: Poppins, "sans-serif";
    padding: 10px 50px;
    color: #fff;
    font-weight: 500;
    
    &:hover {
      transition: all 0.5s;
      color: #4da768;
      background-color: #fff;
    }
  `,
};

export default StyleSucessMoney;