import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyledRestauranteAdmin = {
  ConteudoPrincipal: styled.main`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  `,

  ContainerOutNav: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,

  TableContainer: styled.div`
    margin: 20px 20px;
    border-radius: 10px;
    width: 75vw;

    table {
      height: auto;
      width: 100%;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 15px 0px;
      text-align: center;
    }
  `,

  Titulo: styled.h1`
    font-family: "Poppins", sans-serif;
    margin: 0;
    margin-bottom: 5px;
    margin-top: 50px;
    font-size: 28px;
    text-shadow: 0.1em 0.1em 0.2em #1b202927;
    font-weight: 600;
  `,

  InputPesquisa: styled.input`
    border: none;
    border-radius: 5px;
    background-color: #fff;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 5px;
    width: 400px;
    margin-top: 20px;
    margin-bottom: 15px;
    font-family: Poppins, "sans-serif";
    font-size: 14px;

    &:focus {
      outline: none;
      font-weight: 500;
    }
  `,

  GreenText: styled.span`
    color: #26874e;
  `,

  Search: styled(FontAwesomeIcon)`
    position: relative;
    left: 91%;
    top: -53px;
    background-color: rgb(77, 167, 104);
    color: white;
    padding: 11px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: rgb(122 195 144);
      transition: all 0.5s;
    }
  `,

  ColumnTitle: styled.th`
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    text-align: center !important;
  `,

  Subtitulo: styled.h4`
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    margin: 0;
  `,

  TableTBody: styled.tbody`
    td {
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      font-size: 14;
      text-align: center;
    }

    th {
      text-align: center !important;
    }

    i {
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      transition: all 0.5s;
      color: #1b2029d9;

      &:hover {
        color: #7da768;
        cursor: pointer;
        transition: all 0.5s;
      }
    }
  `,

  TituloEdit: styled.h5`
    text-align: center;
  `,

  UploadImageContainer: styled.div`
    margin-top: 1vh;
    margin-bottom: 4vh;
    height: 300px;
    width: 250px;
    border-radius: 10px;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 5px;
    background-color: rgba(0, 110, 255, 0.041);
    margin-left: 50px;
  `,

  UploadImageConteudo: styled.div`
    flex: 1;
    width: 100%;
    border: 2px dashed #73c883;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  `,

  BigIcon: styled(FontAwesomeIcon)`
    font-size: 48px;
    color: #4da768;
    margin-bottom: 5px;
  `,

  ImageSelected: styled.img`
    cursor: pointer;
    width: 226px;
    position: absolute;
    height: 213px;
    border-radius: 10px;
  `,

  DescricaoImage: styled.label`
    margin-top: 10px;
    background-color: #99e89d86;
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: black;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,

  SmallIcon: styled(FontAwesomeIcon)`
    font-size: 18px;
    color: #4da768;
  `,

  SubtituloImagem: styled.h4`
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    margin: 0;
    text-align: center;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
  `,

  UploadInput: styled.input`
    display: none;
  `,

  DivCores: styled.div`
    display: flex;
    justify-content: center;
  `,

  Add: styled(FontAwesomeIcon)`
    background-color: rgb(77, 167, 104);
    color: white;
    padding: 11px;
    border-radius: 5px;
    position: relative;
    left: 95%;
    top: -53px;

    &:hover {
      cursor: pointer;
      background-color: rgb(122 195 144);
      transition: all 0.5s;
    }
  `,

  ColImageContainer: styled.div`
    display: flex;
    align-items: center;
  `,

  CheckBox: styled.input`
    margin-right: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 15px;
    height: 15px;
  `,
};

export default StyledRestauranteAdmin;
