import styled from "styled-components";
import ReactPaginate from "react-paginate";

const StyledGeneral = {
  DivLoading: styled.div`
    background-color: rgb(77 167 104 / 31%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
  `,

  StyledPagination: styled(ReactPaginate)`
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0 5px;
      display: inline-block;
      cursor: pointer;

      a {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333;
        text-decoration: none;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }

    .active {
      a {
        font-weight: bold;
      }
    }
  `,
};

export default StyledGeneral;
