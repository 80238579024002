import styled from "styled-components";

const StyleHome = {
  AllContent: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
  `,

  ConteudoPrincipal: styled.div`
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px 30px;
  `,

  ContainerPedidos: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
  `,

  Title: styled.h2`
    font-family: Poppins, "sans-serif";
    font-size: 24px;
    font-weight: 600;
  `,

  ContainerBtnStatusPedido: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  `,

  ContainerPagamentosPendentes: styled.div`
    margin-top: 30px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  `,
};

export default StyleHome;