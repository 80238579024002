import styled from "styled-components";

const CardCode = {
  ContainerOneCard: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px 20px;
    margin-left: 40px;
    margin-right: 40px;
    height: 350px;
    width: 285px;

    @media screen and (max-width: 1120px) {
      margin: 1.5em;
    }

    @media screen and (max-width: 999px) {
      width: 260px;
      height: 320px;
    }
  `,

  ImgCard: styled.img`
    width: 90px;
    height: 90px;

    @media screen and (max-width: 999px) {
      width: 80px;
      height: 80px;
    }
  `,

  TituloCard: styled.h4`
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;

    @media screen and (max-width: 1023px) {
      font-size: 15px;
    }
  `,

  DescricaoCard: styled.h6`
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
    margin: 0;

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  `,
};

function CardEmpresa({ titulo, descricao, imagem }) {
  return (
    <CardCode.ContainerOneCard>
      <CardCode.ImgCard src={imagem} />
      <CardCode.TituloCard>{titulo}</CardCode.TituloCard>
      <CardCode.DescricaoCard>{descricao}</CardCode.DescricaoCard>
    </CardCode.ContainerOneCard>
  );
}

export default CardEmpresa;