import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleDetalhes = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--system-background-light-secondary,  #F2F2F7d9);
  `,

  ContainerImgProduto: styled.div`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25vh;
  `,

  ImgProduto: styled.img`
    max-width: 100px;
    max-height: 100px;
    margin-top: 20px;
  `,

  ContainerTamanhoProduto: styled.div`
    background-color: #73c883;
    border-radius: 10px;
    width: 25px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 100px;
    left: 30px;
  `,

  TextoTamanhoProduto: styled.h6`
    font-family: Poppins, "sans-serif";
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  `,

  ContainerInfoProduto: styled.div`
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20px 0px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    margin-top: 20px;
    padding-bottom: 20px;
  `,

  DefaultTitle: styled.h2`
    font-family: Poppins, "sans-serif";
    font-size: 18px;
    font-weight: 600;
  `,

  DefaultSubtitle: styled.h6`
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    font-weight: 400;
    color: #b4b4b4;
  `,

  ContainerPersonalizacaoPedido: styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 20px 0px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding-bottom: 30px;
  `,

  InputObservacao: styled.textarea`
    max-height: 200px;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 5px;
    padding: 10px 0px 0px 10px;
    font-family: Poppins, "sans-serif";
    font-size: 15px;
    width: 100%;
    height: 100px;
    border: none;
    &:focus {
      outline: none;
    }
  `,

  ContainerAcrescentarItens: styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 20px 0px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding-bottom: 30px;
  `,

  ContainerOpcionais: styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 20px 0px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding-bottom: 30px;
  `,

  ContainerRevisarInfos: styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px 0px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding-bottom: 30px;
  `,

  ContainerQtdeEPreco: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: center;
  `,

  ContainerQtdeItensAMais: styled.div`
    border-radius: 5px;
    border: 0.5px solid #cbcbcb;
    background: #fff;
    box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.15);
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 5px 5px 5px 5px;
  `,

  IconMinusPlus: styled(FontAwesomeIcon)`
    color: #73c883;
    font-size: 10px;
    &:hover {
      cursor: pointer;
    }
  `,

  TextoQtdeAMais: styled.h6`
    font-size: 12px;
    font-family: Poppins, "sans-serif";
    font-weight: 600;
    margin: 0;
  `,

  TextoValorTotal: styled.h4`
    justify-self: end;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  `,

  BtnContinuar: styled.button`
    border-radius: 5px;
    background: #73c883;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    border: none;
    width: 200px;
    height: 30px;
    margin-top: 20px;
    transition: all 0.5s;
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,
};

export default StyleDetalhes;