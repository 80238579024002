import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StylePedidoConcluido = {
  ConteudoPrincipal: styled.div`
    margin-top: 20px;
    width: 250px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  `,

  ContainerInfosMesa: styled.div`
    background: rgba(236, 236, 236, 0.53);
    width: 100%;
    height: 60px;
    border-radius: 5px;
    padding: 10px 10px 5px 10px;
  `,

  ContainerMesaETempo: styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
  `,

  TextoNumMesa: styled.h4`
    font-family: Poppins, "sans-serif";
    font-size: 16px;
  `,

  ContainerTempo: styled.div`
    justify-self: end;
    border-radius: 20px;
    background: #9373c8;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
  `,

  TextoTempo: styled.h6`
    font-family: Poppins, "sans-serif";
    font-size: 12px;
    color: #fff;
  `,

  ContainerInfoUser: styled.div`
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `,

  IconUser: styled(FontAwesomeIcon)`
    font-size: 12px;
    color: rgba(136, 136, 136, 0.71);
  `,

  TextoNomeUser: styled.h6`
    font-size: 13px;
    font-family: Poppins, "sans-serif";
    margin-left: 5px !important;
    color: rgba(136, 136, 136, 0.71);
    font-weight: 500;
  `,

  ContainerItensPedido: styled.div`
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
  `,

  ContainerItemECheckBox: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 90% 10%;
    align-items: center;
  `,

  ContainerAllInfoPedido: styled.div`
    margin-bottom: 5px;
  `,

  BtnPedidoPronto: styled.button`
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background: #b4b4b4;
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    border: none;
    color: #fff;
  `,

  CheckBox: styled.input``,

  Obs: styled.h6`
    color: rgba(136, 136, 136, 0.71);
    font-family: Poppins, "sans-serif";
    font-size: 12px;
    font-weight: 500;
    margin-top: 3px !important;
  `,

  Item: styled.h6`
    font-family: Poppins, "sans-serif";
    color: #1b2029;
    font-size: 14px;
  `,

  TextoValorTotal: styled.h6`
    width: 100%;
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    text-align: left;
  `,
};

const {
  ConteudoPrincipal,
  ContainerInfosMesa,
  TextoNumMesa,
  ContainerMesaETempo,
  ContainerTempo,
  TextoTempo,
  ContainerInfoUser,
  IconUser,
  TextoNomeUser,
  ContainerItensPedido,
  BtnPedidoPronto,
  CheckBox,
  Item,
  Obs,
  ContainerItemECheckBox,
  ContainerAllInfoPedido,
  TextoValorTotal,
} = StylePedidoConcluido;

function PedidoConcluido({
  numMesa,
  tempoCorrido,
  nomeUsuario,
  itensPedido,
  valorTotal,
}) {
  return (
    <>
      <ConteudoPrincipal>
        <ContainerInfosMesa>
          <ContainerMesaETempo>
            <TextoNumMesa>Mesa {numMesa}</TextoNumMesa>
            <ContainerTempo>
              <TextoTempo>{tempoCorrido}min</TextoTempo>
            </ContainerTempo>
          </ContainerMesaETempo>
          <ContainerInfoUser>
            <IconUser icon={faUser} />
            <TextoNomeUser>{nomeUsuario}</TextoNomeUser>
          </ContainerInfoUser>
        </ContainerInfosMesa>
        <ContainerItensPedido>
          {itensPedido.map((item, index) => (
            <ContainerAllInfoPedido key={index}>
              <ContainerItemECheckBox>
                <Item>
                  {item.quantidade}x - {item.nomeProduto}
                </Item>
                <CheckBox type="checkbox" checked={true} />
              </ContainerItemECheckBox>
              <Obs>
                *{item.observacao == null ? "Sem observações" : item.observacao}
              </Obs>
            </ContainerAllInfoPedido>
          ))}
        </ContainerItensPedido>
        <TextoValorTotal>Valor total: R${valorTotal}</TextoValorTotal>
        <BtnPedidoPronto>CONCLUÍDO</BtnPedidoPronto>
      </ConteudoPrincipal>
    </>
  );
}

export default PedidoConcluido;