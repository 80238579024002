import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleCookies = {
  ConteudoPrincipal: styled.div`
    position: fixed;
    bottom: 0;
    width: 80%;
    height: auto;
    background-color: #4da768;
    border-radius: 5px;
    z-index: 5;

    padding: 10px 20px;
    margin-left: 10%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  `,

  ContainerTextoIcons: styled.div`
    display: grid;
    grid-template-columns: 25% 10% 65%;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  `,

  ImageIconCookie: styled.img`
    width: 25px;
    height: 25px;
  `,

  Texto: styled.h4`
    font-family: Poppins, "sans-serif";
    font-size: 16px;
    margin: 0 !important;
    color: #fff;
  `,

  Descricao: styled.h6`
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    color: #fff;
    font-weight: 400;
  `,

  BtnAceitarCookies: styled.button`
    border: none;
    border-radius: 5px;
    background-color: #fff;
    padding: 5px 10px;
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    color: #fff;
    background-color: #44915b;
    &:hover {
      cursor: pointer;
    }
  `,

  IconClose: styled(FontAwesomeIcon)`
    justify-self: flex-end;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  `,
};

export default StyleCookies;