import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const BackButtonStyles = {
  ContainerBackButton: styled.div`
    color: #26874e;
    position: fixed;
    top: 1em;
    left: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,

  BackIcon: styled(FontAwesomeIcon)`
    margin-right: 0.5em;
    font-weight: 100;
    margin-top: 0em;
  `,

  BackText: styled.p`
    margin: 0;
    text-decoration: none;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border-color: #26874e;
    }
  `,
};

function BackButton() {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/");
  };

  return (
    <BackButtonStyles.ContainerBackButton onClick={handleNavigation}>
      <BackButtonStyles.BackIcon icon={faChevronLeft} />
      <BackButtonStyles.BackText>Voltar</BackButtonStyles.BackText>
    </BackButtonStyles.ContainerBackButton>
  );
}

export default BackButton;