import { Dropdown, DropdownButton } from "react-bootstrap";
import style from "./style.module.css";
import {
  faArrowRight,
  faBell,
  faGear,
  faPencil,
  faRightFromBracket,
  faStore,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function UserLogin() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("nome");
    localStorage.removeItem("funcao");
    localStorage.removeItem("imagem_perfil_buffer");
    localStorage.removeItem("imagem_perfil_mimeType");
    localStorage.removeItem("mesa");

    navigate("/logincozinha");
  };

  const nomeCozinha = localStorage.getItem("nome");

  return (
    <Dropdown id={style.containerDropdown}>
      <FontAwesomeIcon icon={faStore} id={style.icone} />
      <DropdownButton id={style.btnPrincipal} title={nomeCozinha}>
        <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon icon={faUser} className={style.leftIcons} />
          </div>
          <Dropdown.Item href="#" className={style.tiposLogin}>
            Olá {nomeCozinha}!
          </Dropdown.Item>
          <span className={style.space}></span>
        </div>

        <hr />

        <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon icon={faBell} className={style.leftIcons} />
          </div>
          <Dropdown.Item href="#" className={style.tiposLogin}>
            Notificações
          </Dropdown.Item>
          <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
        </div>
        <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon icon={faGear} className={style.leftIcons} />
          </div>
          <Dropdown.Item href="#" className={style.tiposLogin}>
            Configurações
          </Dropdown.Item>
          <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
        </div>
        <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon icon={faPencil} className={style.leftIcons} />
          </div>
          <Dropdown.Item className={style.tiposLogin}>Perfil</Dropdown.Item>
          <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
        </div>
        <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon
              icon={faRightFromBracket}
              className={style.leftIcons}
            />
          </div>
          <Dropdown.Item
            className={style.tiposLogin}
            onClick={() => handleLogout()}
          >
            Sair
          </Dropdown.Item>
          <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
        </div>
      </DropdownButton>
    </Dropdown>
  );
}

export default UserLogin;
