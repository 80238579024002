import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleHomePedidos = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    height: 90vh;
    background-color: #4da768;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `,

  Image: styled.img`
    margin-top: 20vh;
    margin-bottom: 20vh;
  `,

  Descricao: styled.h4`
    font-family: Poppins, "sans-serif";
    color: #fff;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
  `,

  BtnFazerPedido: styled.button`
    border: none;
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    border-radius: 8px;
    background: #333;
    box-shadow: 0px -8px 10px 0px rgba(51, 51, 51, 0.2) inset,
      0px 0px 26px 0px rgba(0, 0, 0, 0.25);
    color: #4da768;
    font-size: 18px;
    margin-top: 30px;
    width: 254px;
    height: 47px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;

    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      color: #333;
      background-color: #4da768;
    }
  `,

  Icons: styled(FontAwesomeIcon)`
    font-size: 20px;
    margin-left: 10px;
  `,
};

export default StyleHomePedidos;