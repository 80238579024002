import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const IconesOpcoes = styled(FontAwesomeIcon)`
  margin-right: 15px;
`;

export const IconesBtns = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const StyleNavbar = {
  Navbar: styled.nav`
    height: 100vh;
    background-color: #fff;
    width: 30vw;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `,

  ContainerDono: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    margin-top: 20px;
  `,

  FotoPerfil: styled.img`
    width: 90px;
    height: 90px;
    border-radius: 100%;
    margin-right: 15px;
    margin-bottom: 10px;
  `,

  Nome: styled.h4`
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    margin: 0;
  `,

  Funcao: styled.h6`
    color: #969696;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 15px;
  `,

  BtnNovoFunc: styled.button`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none;
    background: #4da768;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding: 5px 25px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.5s;
    margin-top: 10px;
    width: 260px;
    text-align: center;

    &:hover {
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,

  ContainerControles: styled.div`
    margin-top: 20px;
    width: 100%;
  `,

  Opcoes: styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin-left: 25%;
    color: #1b2029;
    transition: all 0.3s;
    color: ${(props) => (props.noHover ? "#7da768" : "#1b2029")};
    &:hover {
      transition: all 0.3s;
      color: #7da768;
      font-weight: 600;
      cursor: pointer;
    }
  `,

  TextoOpcoes: styled.p`
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    list-style: none;
  `,

  StyledLink: styled(Link)`
    text-decoration: none;
  `,
};

export default StyleNavbar;
