import React, { useState, useEffect, useRef } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import StyledClienteAdmin from "../../../../assets/styles/Admin/Contas/Cliente/StyleClienteAdmin";
import StyledGeneral from "../../../../assets/styles/General/GeneralStyle";
import HeaderAdmin from "../../Header/Header_Admin.jsx";
import NavBarAdmin from "../../Navbar/NavbarAdmin.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faFloppyDisk, faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomModalConfirmCancel from "../../../Common/Modal_Confirm_Cancel.jsx";
import CustomModalOnlyMessage from "../../../Common/Modal_Only_Message.jsx";
import CustomModalInvalidLogin from "../../../Common/Modal_Invalid_Login.jsx";
import ReactLoading from "react-loading";

const { DivLoading, StyledPagination } = StyledGeneral;

const {
  ConteudoPrincipal,
  TableContainer,
  Titulo,
  ContainerOutNav,
  GreenText,
  InputPesquisa,
  Search,
  ColumnTitle,
  Subtitulo,
  TableTBody,
  TituloEdit,
  Add,
} = StyledClienteAdmin;

function ClienteAdmin() {
  const [usuarios, setUsuarios] = useState([]);
  const navigate = useNavigate();
  const access_token = localStorage.getItem("token");
  const closeButtonRegisterRef = useRef(null);
  const closeButtonUpdateRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [numero_mesa, setNumeroMesa] = useState("");
  const [token_restaurante, setTokenRestaurante] = useState("");
  const [valorFiltro, setValorFiltro] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmationRegisterModal, setShowConfirmationRegisterModal] =
    useState(false);
  const [showConfirmationUpdateModal, setShowConfirmationUpdateModal] =
    useState(false);
  const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false);
  const [showSuccessRegisterModal, setShowSuccessRegisterModal] =
    useState(false);
  const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [showErrorDeleteModal, setShowErrorDeleteModal] = useState(false);
  const [showInvalidLoginModal, setShowInvalidLoginModal] = useState(false);
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 5;
  const offset = currentPage * dataPerPage;

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const usersToDisplay =
    usuarios.length > 0
      ? usuarios.slice(offset, offset + dataPerPage).map((item, index) => (
          <tr key={item.id}>
            <td>{index}</td>
            <td>{item.nome}</td>
            <td>{item.numero_mesa}</td>
            <td>{item.token_restaurante}</td>
            <td>
              <i
                className="fa fa-pen"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => handlePenClick(item.id)}
              ></i>
            </td>
            <td>
              <i
                className="fa fa-trash"
                onClick={() => handleTrashClick(item.id)}
              ></i>
            </td>
          </tr>
        ))
      : "";

  const showConfirmationRegister = () => {
    setShowConfirmationRegisterModal(true);
  };

  const hideConfirmationRegister = () => {
    setShowConfirmationRegisterModal(false);
  };

  const showConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(true);
  };

  const hideConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(false);
  };

  const showSuccessUpdate = () => {
    setShowSuccessUpdateModal(true);
  };

  const hideSuccessUpdate = () => {
    setShowSuccessUpdateModal(false);
  };

  const showSuccessRegister = () => {
    setShowSuccessRegisterModal(true);
  };

  const hideSuccessRegister = () => {
    setShowSuccessRegisterModal(false);
  };

  const showErrorUpdate = () => {
    setShowErrorUpdateModal(true);
  };

  const hideErrorUpdate = () => {
    setShowErrorUpdateModal(false);
  };

  const showConfirmationDelete = () => {
    setShowConfirmationDeleteModal(true);
  };

  const hideConfirmationDelete = () => {
    setShowConfirmationDeleteModal(false);
  };

  const showSuccessDelete = () => {
    setShowSuccessDeleteModal(true);
  };

  const hideSuccessDelete = () => {
    setShowSuccessDeleteModal(false);
  };

  const showErrorDelete = () => {
    setShowErrorDeleteModal(true);
  };

  const hideErrorDelete = () => {
    setShowErrorDeleteModal(false);
  };

  const showInvalidLogin = () => {
    setShowInvalidLoginModal(true);
  };

  const hideInvalidLogin = () => {
    setShowInvalidLoginModal(false);
  };

  const showErrorSearch = () => {
    setShowErrorSearchModal(true);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  const handlePenClick = function (id) {
    setLoading(true);
    limpaCampos();

    const usuarioAtual = usuarios.find((usuario) => usuario.id === id);

    if (usuarioAtual) {
      setId(usuarioAtual.id);
      setNome(usuarioAtual.nome);
      setNumeroMesa(usuarioAtual.numero_mesa);
      setTokenRestaurante(usuarioAtual.token_restaurante);
    }

    setLoading(false);
  };

  const handleTrashClick = function (id) {
    setId(id);
    showConfirmationDelete();
  };

  const buscarCliente = () => {
    setLoading(true);

    axios
      .get("https://www.api.qftech.com.br/api/list/listclient", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }
          if (closeButtonRegisterRef.current) {
            closeButtonRegisterRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
        } else if (err.response.data !== "Nenhum cliente encontrado!") {
          showErrorSearch();
          setErrorMessage(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const dados = {
    nome,
    numero_mesa,
    token_restaurante,
  };

  const cadastrarCliente = () => {
    setLoading(true);

    axios
      .post(
        `https://www.api.qftech.com.br/api/register/registerclient`,
        JSON.stringify(dados),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        hideConfirmationRegister();
        showSuccessRegister();

        if (closeButtonRegisterRef.current) {
          closeButtonRegisterRef.current.click();
        }

        buscarCliente();
      })
      .catch((err) => {
        hideConfirmationRegister();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const atualizarCliente = (id) => {
    setLoading(true);

    axios
      .put(
        `https://www.api.qftech.com.br/api/update/updateclient/${id}`,
        JSON.stringify(dados),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        hideConfirmationUpdate();
        showSuccessUpdate();

        if (closeButtonUpdateRef.current) {
          closeButtonUpdateRef.current.click();
        }

        buscarCliente();
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }

        hideConfirmationUpdate();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletarCliente = (id) => {
    setLoading(true);

    axios
      .delete(`https://www.api.qftech.com.br/api/delete/deleteclient/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        hideConfirmationDelete();
        showSuccessDelete();
        buscarCliente();
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }

        hideConfirmationDelete();
        showErrorDelete();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filtro = valorFiltro;
  const filtrarCliente = () => {
    setLoading(true);

    axios
      .post(
        `https://www.api.qftech.com.br/api/filter/filterclient`,
        JSON.stringify({ filtro }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }
        if (err.response.data !== "Nenhum cliente encontrado!") {
          showErrorSearch();
          setErrorMessage(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    
    axios
      .get("https://www.api.qftech.com.br/api/list/listclient", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
        } else if (err.response.data !== "Nenhum cliente encontrado!") {
          showErrorSearch();
          setErrorMessage(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [access_token, navigate]);

  const limpaCampos = () => {
    setId("");
    setNome("");
    setNumeroMesa("");
    setTokenRestaurante("");
    setValorFiltro("");
  };

  return (
    <>
      {loading && (
        <DivLoading>
          <ReactLoading
            type="bars"
            color="#fff"
            width="100px"
            height="100px"
            className="loading"
          />
        </DivLoading>
      )}

      <HeaderAdmin />
      <ConteudoPrincipal>
        <NavBarAdmin />
        <ContainerOutNav>
          <Titulo>
            Seja bem-vindo a seção de <GreenText>clientes!</GreenText>
          </Titulo>
          <Subtitulo>
            Fazemos o possível para que seu dia possa ficar melhor e mais ágil!
          </Subtitulo>
          <div>
            <InputPesquisa
              type="text"
              placeholder="Faça uma pesquisa!"
              className="form-control"
              onChange={(event) => setValorFiltro(event.target.value)}
              value={valorFiltro}
              autoComplete="new-password"
              id="filtroPesquisa"
            />
            <Search icon={faSearch} onClick={() => filtrarCliente()} />
            <Add
              icon={faPlus}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              onClick={() => limpaCampos()}
            />
          </div>
          <TableContainer>
            <table className="table table-hover">
              <thead className="table-light">
                <tr>
                  <ColumnTitle scope="col">#</ColumnTitle>
                  <ColumnTitle scope="col">Nome</ColumnTitle>
                  <ColumnTitle scope="col">Número da Mesa</ColumnTitle>
                  <ColumnTitle scope="col">Token do Restaurante</ColumnTitle>
                  <ColumnTitle scope="col">Editar</ColumnTitle>
                  <ColumnTitle scope="col">Excluir</ColumnTitle>
                </tr>
              </thead>
              <TableTBody className="table-group-divider" id="tbodyCliente">
                {usuarios.length > 0 ? (
                  usersToDisplay
                ) : (
                  <tr>
                    <td colSpan="6">Nenhum cliente encontrado!</td>
                  </tr>
                )}
              </TableTBody>
            </table>
            <StyledPagination
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              breakLabel={"..."}
              pageCount={Math.ceil(usuarios.length / dataPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </TableContainer>
        </ContainerOutNav>
      </ConteudoPrincipal>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edição de Dados</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome_edicao" className="form-label">
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="John Doe"
                      id="nome_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="numero_mesa_edicao" className="form-label">
                      Número da Mesa
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={numero_mesa}
                      onChange={(event) => setNumeroMesa(event.target.value)}
                      placeholder="100"
                      id="numero_mesa_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      for="token_restaurante_edicao"
                      className="form-label"
                    >
                      Token do Restaurante
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={token_restaurante}
                      onChange={(event) =>
                        setTokenRestaurante(event.target.value)
                      }
                      placeholder="abcd2"
                      id="token_restaurante_edicao"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                ref={closeButtonUpdateRef}
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationUpdate}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cadastrar Cliente</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="John Doe"
                      id="nome"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="numero_mesa" className="form-label">
                      Número da Mesa
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={numero_mesa}
                      onChange={(event) => setNumeroMesa(event.target.value)}
                      placeholder="100"
                      id="numero_mesa"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="token_restaurante" className="form-label">
                      Token do Restaurante
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={token_restaurante}
                      onChange={(event) =>
                        setTokenRestaurante(event.target.value)
                      }
                      placeholder="TOKEN"
                      id="token_restaurante"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                ref={closeButtonRegisterRef}
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationRegister}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <CustomModalConfirmCancel
        show={showConfirmationUpdateModal}
        onHide={hideConfirmationUpdate}
        title="Atenção"
        body="Tem certeza que deseja atualizar os dados?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => atualizarCliente(id)}
        onCancel={hideConfirmationUpdate}
      />

      <CustomModalConfirmCancel
        show={showConfirmationRegisterModal}
        onHide={hideConfirmationRegister}
        title="Atenção"
        body="Tem certeza que deseja cadastrar o cliente?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => cadastrarCliente()}
        onCancel={hideConfirmationRegister}
      />

      <CustomModalOnlyMessage
        show={showSuccessUpdateModal}
        onHide={hideSuccessUpdate}
        title="Sucesso"
        body="Os dados foram atualizados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showSuccessRegisterModal}
        onHide={hideSuccessRegister}
        title="Sucesso"
        body="Os dados foram cadastrados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorUpdateModal}
        onHide={hideErrorUpdate}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalConfirmCancel
        show={showConfirmationDeleteModal}
        onHide={hideConfirmationDelete}
        title="Atenção"
        body="Tem certeza que deseja deletar o usuário?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => deletarCliente(id)}
        onCancel={hideConfirmationDelete}
      />

      <CustomModalOnlyMessage
        show={showSuccessDeleteModal}
        onHide={hideSuccessDelete}
        title="Sucesso"
        body="Usuário deletado com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorDeleteModal}
        onHide={hideErrorDelete}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalInvalidLogin
        show={showInvalidLoginModal}
        onHide={hideInvalidLogin}
      />

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />
    </>
  );
}

export default ClienteAdmin;
