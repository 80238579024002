import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HeaderPrincipal from "../components/Common/Header_Principal";
import Home from "./Home/Home";
import Planos from "./Planos/Planos";
import Footer from "../components/Common/Footer_Principal";
import RestaurantRegisterBody from "../components/Company_Register/Restaurant/Restaurant_Register";
import KitchenResgister from "../components/Company_Register/Kitchen/Kitchen_Register";
import WaiterRegisterBody from "../components/Company_Register/Waiter/Waiter_Register";
import KitchenBody from "../components/Company_Login/Kitchen/Kitchen_Body";
import RestaurantLogin from "../components/Company_Login/Restaurant/Restaurant_Login";
import WaiterLogin from "../components/Company_Login/Waiter/Waiter_Login";
import ClientLogin from "../components/Client_Login/Client_Body";
import HomeAdmin from "../pages/Admin/Admin_Body";
import ForgotPassword from "./ForgotPass/ForgotPass";
import GarcomAdmin from "../components/Admin/Contas/Garcom/GarcomAdmin";
import ClienteAdmin from "../components/Admin/Contas/Cliente/ClienteAdmin";
import CozinhaAdmin from "../components/Admin/Contas/Cozinha/CozinhaAdmin";
import RestauranteAdmin from "../components/Admin/Contas/Restaurante/RestauranteAdmin";
import AdministradorAdmin from "../components/Admin/Contas/Administrador/AdministradorAdmin";
import PrivateRoute from "./PrivateRoute";
import ExibicaoCardapio from "../components/Cardapio/Exibição/Exibicao";
import EdicaoCardapio from "../components/Cardapio/Edição/Edicao";
import CadastroCardapio from "../components/Cardapio/Cadastro/Cadastro";
import Imagem from "../components/Admin/Cardapio/Imagem/ImagemAdmin";
import Categoria from "../components/Admin/Cardapio/Categoria/CategoriaAdmin";
import Acrescimo from "../components/Admin/Cardapio/Acrescimo/AcrescimoAdmin";
import ItemAdicional from "../components/Admin/Cardapio/ItemAdicional/ItemAdicionalAdmin";
import HomePedidos from "../components/Pedidos/HomePedidos";
import ClienteCardapio from "../components/Pedidos/Cardapio/ClienteCardapio";
import Detalhes from "../components/Pedidos/Detalhes/Detalhes";
import Incrementos from "../components/Pedidos/Detalhes/Incrementos";
import Sacola from "../components/Pedidos/Sacola/Sacola";
import Pagamento from "../components/Pedidos/Pagamento/Pagamento";
import SucessPix from "../components/Pedidos/Sucess/SucessPix";
import SucessCard from "../components/Pedidos/Sucess/SucessCard";
import SucessMoney from "../components/Pedidos/Sucess/SucessMoney";
import HomeGestaoPedidos from "../components/GestaoPedidos_Cozinha/Home";
import AdministratorLogin from "../components/Admin/Login/Admin_Login";
import QRCode from "../components/Admin/QRCode/QRCodeAdmin";

function AppRoutes() {
  const location = useLocation();

  const showNavbar =
    location.pathname === "/" ||
    location.pathname === "/planos" ||
    location.pathname === "/contato";
  const showFooter = showNavbar;

  return (
    <>
      {showNavbar && <HeaderPrincipal />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/planos" element={<Planos />} />
        <Route path="/sobrenos" />
        <Route
          path="/cadastrorestaurante"
          element={<RestaurantRegisterBody />}
        />
        <Route path="/cadastrocozinha" element={<KitchenResgister />} />
        <Route path="/cadastrogarcom" element={<WaiterRegisterBody />} />
        <Route path="/loginrestaurante" element={<RestaurantLogin />} />
        <Route path="/logincozinha" element={<KitchenBody />} />
        <Route path="/logingarcom" element={<WaiterLogin />} />
        <Route path="/logincliente" element={<ClientLogin />} />
        <Route path="/loginadministrator" element={<AdministratorLogin />} />
        <Route path="/esquecisenha" element={<ForgotPassword />} />
        <Route
          path="/admin/clientes"
          element={<PrivateRoute element={ClienteAdmin} />}
        />
        <Route
          path="/admin/garcons"
          element={<PrivateRoute element={GarcomAdmin} />}
        />
        <Route
          path="/admin/cozinhas"
          element={<PrivateRoute element={CozinhaAdmin} />}
        />
        <Route
          path="/admin/restaurantes"
          element={<PrivateRoute element={RestauranteAdmin} />}
        />
        <Route
          path="/admin/administradores"
          element={<PrivateRoute element={AdministradorAdmin} />}
        />
        <Route path="/admin" element={<PrivateRoute element={HomeAdmin} />} />
        <Route
          path="/admin/exibir_cardapio"
          element={<PrivateRoute element={ExibicaoCardapio} />}
        />
        <Route
          path="/admin/editar_cardapio"
          element={<PrivateRoute element={EdicaoCardapio} />}
        />
        <Route
          path="/admin/cadastrar_cardapio"
          element={<PrivateRoute element={CadastroCardapio} />}
        />
        <Route
          path="/admin/imagens"
          element={<PrivateRoute element={Imagem} />}
        />
        <Route
          path="/admin/categorias"
          element={<PrivateRoute element={Categoria} />}
        />
        <Route
          path="/admin/acrescimos"
          element={<PrivateRoute element={Acrescimo} />}
        />
        <Route
          path="/admin/itensadicionais"
          element={<PrivateRoute element={ItemAdicional} />}
        />
        <Route
          path="/admin/qrcodes"
          element={<PrivateRoute element={QRCode} />}
        />
        {/* <Route path='/cliente/home_pedidos' element={<PrivateRoute element={HomePedidos}/>} />
        <Route path='/cliente/cardapio' element={<PrivateRoute element={ClienteCardapio}/>} />
        <Route path='/cliente/detalhes_produto' element={<PrivateRoute element={Detalhes}/>} />
        <Route path='/cliente/incrementos_pedido' element={<PrivateRoute element={Incrementos}/>} />
        <Route path='/cliente/sacola' element={<PrivateRoute element={Sacola}/>} />
        <Route path='/cliente/pagemento' element={<PrivateRoute element={Pagamento}/>} />
        <Route path='/cliente/pagamento_pix' element={<PrivateRoute element={SucessPix} />} />
        <Route path='/cliente/pagamento_cartao' element={<PrivateRoute element={SucessCard} />} />
        <Route path='/cliente/pagamento_dinheiro' element={<PrivateRoute element={SucessMoney} />} />
        <Route path='/cozinha/gestao_pedidos' element={<PrivateRoute element={HomeGestaoPedidos} />} /> */}

        <Route path="/cliente/home_pedidos" element={<HomePedidos />} />
        <Route path="/cliente/cardapio" element={<ClienteCardapio />} />
        <Route path="/cliente/detalhes_produto" element={<Detalhes />} />
        <Route path="/cliente/incrementos_pedido" element={<Incrementos />} />
        <Route path="/cliente/sacola" element={<Sacola />} />
        <Route path="/cliente/pagamento" element={<Pagamento />} />
        <Route path="/cliente/pagamento_pix" element={<SucessPix />} />
        <Route path="/cliente/pagamento_cartao" element={<SucessCard />} />
        <Route path="/cliente/pagamento_dinheiro" element={<SucessMoney />} />
        <Route
          path="/cozinha/gestao_pedidos"
          element={<PrivateRoute element={HomeGestaoPedidos} />}
        />
      </Routes>
      {showFooter && <Footer />}
    </>
  );
}

export default function Root() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}