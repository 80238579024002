import StyleAdministrator from "../../../assets/styles/Admin/Login/StyledAdministradorLogin";
import BackButton from "../../../components/Props/BackButton";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import iconeEmail from "../../../assets/img/emailverde.png";
import iconeCadeado from "../../../assets/img/lock.png";
import { useState } from "react";
import chave from "../../../assets/img/icone-chave.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomModalOnlyMessage from "../../Common/Modal_Only_Message.jsx";
import ReactLoading from "react-loading";
import StyledGeneral from "../../../assets/styles/General/GeneralStyle";

const { DivLoading } = StyledGeneral;

const {
  ConteudoPrincipal,
  DivSenha,
  DivNome,
  Imagens,
  ImgAdministrador,
  DivInputs,
  Icones,
  Legenda,
  IconeAdministrador,
  Formulario,
  DivDoFrom,
  Titulo,
  Lbl,
  Form,
  TxtName,
  TxtSenha,
  BtnLogar,
  StyledFontAwesomeIcon,
} = StyleAdministrator;

function AdminLogin() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [btnEye, setEye] = useState(faEyeSlash);
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [msgError, setMsgError] = useState("");
  const [showErrorLoginModal, setShowErrorLoginModal] = useState(false);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setInputType(showPassword ? "text" : "password");
    setEye(showPassword ? faEye : faEyeSlash);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setLoading(true);

    const dados = {
      email: email,
      senha: senha,
    };

    axios
      .post(
        "https://www.api.qftech.com.br/api/authentication/loginadmin",
        JSON.stringify(dados),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.error === "Complete o campo EMAIL!") {
          setMsgError(response.data.error);
          showErrorLogin();
        } else if (response.data.error === "Complete o campo SENHA!") {
          setMsgError(response.data.error);
          showErrorLogin();
        } else if (response.data.error === "Campo EMAIL inválido!") {
          setMsgError(response.data.error);
          showErrorLogin();
        } else if (response.data.error === "SENHA incorreta!") {
          setMsgError(response.data.error);
          showErrorLogin();
        } else if (response.data.error === "Usuário inativo!") {
          setMsgError(response.data.error);
          showErrorLogin();
        } else if (response.data.success) {
          limpaCampos();

          const imagem_perfil_buffer_string = JSON.stringify(
            response.data.imagem_perfil_buffer
          );

          localStorage.removeItem("token");
          localStorage.removeItem("nome");
          localStorage.removeItem("funcao");
          localStorage.removeItem("imagem_perfil_buffer");
          localStorage.removeItem("imagem_perfil_mimeType");
          localStorage.removeItem("mesa");
          //setCookie("token", `${response.data.token}`, 1);
          localStorage.setItem("token", `${response.data.token}`);
          localStorage.setItem("nome", `${response.data.nome}`);
          localStorage.setItem("funcao", `${response.data.funcao}`);
          localStorage.setItem(
            "imagem_perfil_buffer",
            `${imagem_perfil_buffer_string}`
          );
          localStorage.setItem(
            "imagem_perfil_mimeType",
            `${response.data.imagem_perfil_mimeType}`
          );

          // Redirecionar para a rota do React
          navigate("/admin");
        } else {
          setMsgError(response.data.error);
          showErrorLogin();
        }
      })
      .catch((err) => {
        if (err.response.data === "Complete o campo EMAIL!") {
          setMsgError(err.response.data);
          showErrorLogin();
        } else if (err.response.data === "Complete o campo SENHA!") {
          setMsgError(err.response.data);
          showErrorLogin();
        } else if (err.response.data === "Campo EMAIL inválido!") {
          setMsgError(err.response.data);
          showErrorLogin();
        } else if (err.response.data === "SENHA incorreta!") {
          setMsgError(err.response.data);
          showErrorLogin();
        } else if (err.response.data === "Usuário inativo!") {
          setMsgError(err.response.data);
          showErrorLogin();
        } else {
          setMsgError(err.response.data);
          showErrorLogin();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showErrorLogin = () => {
    setShowErrorLoginModal(true);
  };

  const hideErrorLogin = () => {
    setShowErrorLoginModal(false);
  };

  const limpaCampos = () => {
    setEmail("");
    setSenha("");
  };

  return (
    <>
      {loading && (
        <DivLoading>
          <ReactLoading
            type="bars"
            color="#fff"
            width="100px"
            height="100px"
            className="loading"
          />
        </DivLoading>
      )}

      <ConteudoPrincipal>
        <BackButton />
        <Imagens>
          <ImgAdministrador />
        </Imagens>
        <Formulario>
          <DivDoFrom>
            <IconeAdministrador>
              <img src={chave} alt="chave" />
            </IconeAdministrador>
            <Titulo>ADMINISTRADOR</Titulo>
            <Legenda>Espaço restrito para diretores</Legenda>
            <Form>
              <Lbl>Email:</Lbl>
              <DivInputs>
                <DivNome>
                  <Icones>
                    <img src={iconeEmail} alt="iconeEmail" />
                  </Icones>
                  <TxtName
                    type="email"
                    name="email"
                    id="email"
                    autoFocus
                    placeholder="email@email.com"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </DivNome>
              </DivInputs>
              <Lbl>Senha:</Lbl>
              <DivInputs>
                <Icones>
                  <img src={iconeCadeado} alt="iconeCadeado" />
                </Icones>
                <DivSenha>
                  <TxtSenha
                    type={inputType}
                    placeholder="••••••"
                    name="senha"
                    id="senha"
                    autoFocus
                    onChange={(event) => setSenha(event.target.value)}
                  />
                  <StyledFontAwesomeIcon
                    icon={btnEye}
                    onClick={togglePasswordVisibility}
                  />
                </DivSenha>
              </DivInputs>
              <BtnLogar onClick={handleClick}>LOGAR</BtnLogar>
            </Form>
          </DivDoFrom>
        </Formulario>
      </ConteudoPrincipal>

      <CustomModalOnlyMessage
        show={showErrorLoginModal}
        onHide={hideErrorLogin}
        title="Atenção"
        body={msgError}
      />
    </>
  );
}

export default AdminLogin;
