import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";

const StyleSacola = {
  ConteudoPrincipal: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #efeff1;
    width: 90%;
    height: auto;
    padding: 10px 10px;
    position: fixed;
    z-index: 2;
    bottom: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 0.5px #cbcbcb solid;

    @media (min-width: 768px) {
      width: 45%;
    }
  `,

  Icon: styled(FontAwesomeIcon)`
    color: #a8a8a8;
  `,

  Texto: styled.h4`
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    color: #b4b4b4;
    text-align: left;
  `,

  Preco: styled.h4`
    font-family: Poppins, "sans-serif";
    font-weight: 700;
    font-size: 14px;
    margin: 0;
    color: black;
  `,
};

const { ConteudoPrincipal, Icon, Texto, Preco } = StyleSacola;

function Sacola({ preco, changePage }) {
  return (
    <>
      <ConteudoPrincipal onClick={changePage}>
        <Icon icon={faBasketShopping} />
        <Texto>Vizualizar sacola</Texto>
        <Preco>{preco}</Preco>
      </ConteudoPrincipal>
    </>
  );
}

export default Sacola;