import React, { useState, useRef, useEffect } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import StyledGeneral from "../../../../assets/styles/General/GeneralStyle";
import StyledAdministradorAdmin from "../../../../assets/styles/Admin/Contas/Administrador/StyledAdministradorAdmin";
import HeaderAdmin from "../../Header/Header_Admin.jsx";
import NavBarAdmin from "../../Navbar/NavbarAdmin.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { InputMask } from "@react-input/mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faFloppyDisk,
  faEye,
  faEyeSlash,
  faImage,
  faFile,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import formatarDataISO from "../../../../assets/scripts/Formater/DateFormaterISO.js";
import buscarCep from "../../../../assets/scripts/Request/buscaCep.mjs";
import CustomModalConfirmCancel from "../../../Common/Modal_Confirm_Cancel.jsx";
import CustomModalOnlyMessage from "../../../Common/Modal_Only_Message.jsx";
import CustomModalInvalidLogin from "../../../Common/Modal_Invalid_Login.jsx";
import ReactLoading from "react-loading";

const { DivLoading, StyledPagination } = StyledGeneral;

const {
  ConteudoPrincipal,
  TableContainer,
  Titulo,
  ContainerOutNav,
  GreenText,
  InputPesquisa,
  Search,
  ColumnTitle,
  Subtitulo,
  TableTBody,
  TituloEdit,
  UploadImageContainer,
  UploadImageConteudo,
  BigIcon,
  ImageSelected,
  DescricaoImage,
  SmallIcon,
  SubtituloImagem,
  UploadInput,
  Add,
  CheckBox,
  ColImageContainer,
} = StyledAdministradorAdmin;

function AdministradorAdmin() {
  const [usuarios, setUsuarios] = useState([]);
  const navigate = useNavigate();
  const inputFileRef = useRef(null);
  const access_token = localStorage.getItem("token");
  const closeButtonRegisterRef = useRef(null);
  const closeButtonUpdateRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [sexo, setSexo] = useState("");
  const [estado_civil, setEstadoCivil] = useState("");
  const [data_nascimento, setDataNascimento] = useState("");
  const [nome_completo_mae, setNomeCompletoMae] = useState("");
  const [nome_completo_pai, setNomeCompletoPai] = useState("");
  const [cargo, setCargo] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [numero_endereco, setNumeroEndereco] = useState("");
  const [complemento, setComplemento] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmar_senha, setConfirmarSenha] = useState("");
  const [aceite_termos, setAceiteTermos] = useState("");
  const [status, setStatus] = useState("");
  const [id_imagem_perfil, setIdImagemPerfil] = useState("");
  const [imagem_perfil, setImagemPerfil] = useState("");
  const [imagem_perfil_selecionada, setImagemPerfilSelecionada] = useState("");
  const [nome_arquivo, setNomeArquivo] = useState(
    "Nenhum arquivo foi selecionado"
  );
  const [valorFiltro, setValorFiltro] = useState({});
  const [user_images, setUserImages] = useState({});
  const [exibirSenha, setType] = useState("password");
  const [btnEye, setEye] = useState(faEyeSlash);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmationRegisterModal, setShowConfirmationRegisterModal] =
    useState(false);
  const [showConfirmationUpdateModal, setShowConfirmationUpdateModal] =
    useState(false);
  const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false);
  const [showSuccessRegisterModal, setShowSuccessRegisterModal] =
    useState(false);
  const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [showErrorDeleteModal, setShowErrorDeleteModal] = useState(false);
  const [showInvalidLoginModal, setShowInvalidLoginModal] = useState(false);
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 5;
  const offset = currentPage * dataPerPage;

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const usersToDisplay =
    usuarios.length > 0
      ? usuarios.slice(offset, offset + dataPerPage).map((item, index) => (
          <tr key={item.id}>
            <td>{index}</td>
            <td>{item.nome}</td>
            <td>{item.email}</td>
            <td>{item.cpf}</td>
            <td>{item.cargo}</td>
            <td>
              <i
                className="fa fa-pen"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => handlePenClick(item.id)}
              ></i>
            </td>
            <td>
              <i
                className="fa fa-trash"
                onClick={() => handleTrashClick(item.id)}
              ></i>
            </td>
          </tr>
        ))
      : "";

  const handleUf = (event) => {
    setUf(event.target.value);
  };

  const handleEstadoCivil = (event) => {
    setEstadoCivil(event.target.value);
  };

  const handleSexo = (event) => {
    setSexo(event.target.value);
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const showConfirmationRegister = () => {
    setShowConfirmationRegisterModal(true);
  };

  const hideConfirmationRegister = () => {
    setShowConfirmationRegisterModal(false);
  };

  const showConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(true);
  };

  const hideConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(false);
  };

  const showSuccessUpdate = () => {
    setShowSuccessUpdateModal(true);
  };

  const hideSuccessUpdate = () => {
    setShowSuccessUpdateModal(false);
  };

  const showSuccessRegister = () => {
    setShowSuccessRegisterModal(true);
  };

  const hideSuccessRegister = () => {
    setShowSuccessRegisterModal(false);
  };

  const showErrorUpdate = () => {
    setShowErrorUpdateModal(true);
  };

  const hideErrorUpdate = () => {
    setShowErrorUpdateModal(false);
  };

  const showConfirmationDelete = () => {
    setShowConfirmationDeleteModal(true);
  };

  const hideConfirmationDelete = () => {
    setShowConfirmationDeleteModal(false);
  };

  const showSuccessDelete = () => {
    setShowSuccessDeleteModal(true);
  };

  const hideSuccessDelete = () => {
    setShowSuccessDeleteModal(false);
  };

  const showErrorDelete = () => {
    setShowErrorDeleteModal(true);
  };

  const hideErrorDelete = () => {
    setShowErrorDeleteModal(false);
  };

  const showInvalidLogin = () => {
    setShowInvalidLoginModal(true);
  };

  const hideInvalidLogin = () => {
    setShowInvalidLoginModal(false);
  };

  const showErrorSearch = () => {
    setShowErrorSearchModal(true);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  const handlePenClick = function (id) {
    setLoading(true);
    limpaCampos();

    const usuarioAtual = usuarios.find((usuario) => usuario.id === id);

    if (usuarioAtual) {
      setId(usuarioAtual.id);
      setNome(usuarioAtual.nome);
      setCpf(usuarioAtual.cpf);
      setRg(usuarioAtual.rg);
      setSexo(usuarioAtual.sexo);
      setEstadoCivil(usuarioAtual.estado_civil);
      setDataNascimento(formatarDataISO(usuarioAtual.data_nascimento));
      setNomeCompletoMae(usuarioAtual.nome_completo_mae);
      setNomeCompletoPai(usuarioAtual.nome_completo_pai);
      setCargo(usuarioAtual.cargo);
      setEmail(usuarioAtual.email);
      setCelular(usuarioAtual.celular);
      setTelefone(usuarioAtual.telefone);
      setCep(usuarioAtual.cep);
      setRua(usuarioAtual.rua);
      setBairro(usuarioAtual.bairro);
      setCidade(usuarioAtual.cidade);
      setUf(usuarioAtual.uf);
      setNumeroEndereco(usuarioAtual.numero_endereco);
      setComplemento(usuarioAtual.complemento);
      setAceiteTermos(usuarioAtual.aceite_termos);
      setStatus(usuarioAtual.status);

      if (user_images[id] === "") {
        handleRemoveFile();
      } else {
        setIdImagemPerfil(usuarioAtual.id_imagem_perfil);
        setImagemPerfilSelecionada(user_images[id]);
        setNomeArquivo(usuarioAtual.title);
      }
    }

    setLoading(false);
  };

  const handleTrashClick = function (id) {
    setId(id);
    showConfirmationDelete();
  };

  const handleCep = async () => {
    setLoading(true);

    try {
      const data = await buscarCep(cep);

      setRua(data.logradouro);
      setBairro(data.bairro);
      setCidade(data.localidade);
      setUf(data.uf);
      setNumeroEndereco("");
      setComplemento(data.complemento);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const mostraSenha = () => {
    setType(exibirSenha === "text" ? "password" : "text");
    setEye(btnEye === faEyeSlash ? faEye : faEyeSlash);
  };

  const handleOpenFilePicker = () => {
    inputFileRef.current.click();
  };

  const handleRemoveFile = () => {
    setImagemPerfilSelecionada(null);
    setIdImagemPerfil("");
    setNomeArquivo("Nenhum arquivo selecionado");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNomeArquivo(file.name);
      setImagemPerfil(file);
      setIdImagemPerfil("");

      const reader = new FileReader();
      reader.onload = () => {
        setImagemPerfilSelecionada(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const buscarAdministrador = () => {
    setLoading(true);

    axios
      .get("https://www.api.qftech.com.br/api/list/listadmin", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setUsuarios(response.data);

        const imageUrls = {};

        response.data.forEach((user) => {
          if (user.buffer === "") {
            imageUrls[user.id] = "";
          } else {
            const uint8Array = new Uint8Array(user.buffer.data);
            const blob = new Blob([uint8Array], { type: user.mimeType });
            const imageUrl = URL.createObjectURL(blob);

            imageUrls[user.id] = imageUrl;
          }
        });

        setUserImages(imageUrls);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }
          if (closeButtonRegisterRef.current) {
            closeButtonRegisterRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
        } else if (err.response.data !== "Nenhum administrador encontrado!") {
          showErrorSearch();
          setErrorMessage(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cadastrarAdministrador = () => {
    setLoading(true);

    const formData = new FormData();

    formData.append("nome", nome);
    formData.append("cpf", cpf);
    formData.append("rg", rg);
    formData.append("sexo", sexo);
    formData.append("estado_civil", estado_civil);
    formData.append("data_nascimento", data_nascimento);
    formData.append("nome_completo_mae", nome_completo_mae);
    formData.append("nome_completo_pai", nome_completo_pai);
    formData.append("cargo", cargo);
    formData.append("email", email);
    formData.append("celular", celular);
    formData.append("telefone", telefone);
    formData.append("cep", cep);
    formData.append("rua", rua);
    formData.append("bairro", bairro);
    formData.append("cidade", cidade);
    formData.append("uf", uf);
    formData.append("numero_endereco", numero_endereco);
    formData.append("complemento", complemento);
    formData.append("senha", senha);
    formData.append("confirmar_senha", confirmar_senha);
    formData.append("aceite_termos", aceite_termos);
    formData.append("status", status);
    formData.append("imagem_perfil", imagem_perfil);
    formData.append("id_imagem_perfil", id_imagem_perfil);
    formData.append("title", "FOTO_PERFIL");
    formData.append("description", "FOTO_PERFIL");
    formData.append("type", "FOTO_PERFIL");

    axios
      .post(`https://www.api.qftech.com.br/api/register/registeradmin`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        hideConfirmationRegister();
        showSuccessRegister();

        if (closeButtonRegisterRef.current) {
          closeButtonRegisterRef.current.click();
        }

        buscarAdministrador();
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonRegisterRef.current) {
            closeButtonRegisterRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }

        hideConfirmationRegister();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const atualizarAdministrador = (id) => {
    setLoading(true);

    const formData = new FormData();

    formData.append("nome", nome);
    formData.append("cpf", cpf);
    formData.append("rg", rg);
    formData.append("sexo", sexo);
    formData.append("estado_civil", estado_civil);
    formData.append("data_nascimento", data_nascimento);
    formData.append("nome_completo_mae", nome_completo_mae);
    formData.append("nome_completo_pai", nome_completo_pai);
    formData.append("cargo", cargo);
    formData.append("email", email);
    formData.append("celular", celular);
    formData.append("telefone", telefone);
    formData.append("cep", cep);
    formData.append("rua", rua);
    formData.append("bairro", bairro);
    formData.append("cidade", cidade);
    formData.append("uf", uf);
    formData.append("numero_endereco", numero_endereco);
    formData.append("complemento", complemento);
    formData.append("senha", senha);
    formData.append("confirmar_senha", confirmar_senha);
    formData.append("aceite_termos", aceite_termos);
    formData.append("status", status);
    formData.append("imagem_perfil", imagem_perfil);
    formData.append("id_imagem_perfil", id_imagem_perfil);
    formData.append("title", "FOTO_PERFIL");
    formData.append("description", "FOTO_PERFIL");
    formData.append("type", "FOTO_PERFIL");

    axios
      .put(`https://www.api.qftech.com.br/api/update/updateadmin/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        hideConfirmationUpdate();
        showSuccessUpdate();

        if (closeButtonUpdateRef.current) {
          closeButtonUpdateRef.current.click();
        }

        buscarAdministrador();
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }

        hideConfirmationUpdate();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletarAdministrador = (id) => {
    setLoading(true);

    axios
      .delete(`https://www.api.qftech.com.br/api/delete/deleteadmin/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        hideConfirmationDelete();
        showSuccessDelete();
        buscarAdministrador();
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }

        hideConfirmationDelete();
        showErrorDelete();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filtro = valorFiltro;
  const filtrarAdministrador = () => {
    setLoading(true);

    axios
      .post(
        `https://www.api.qftech.com.br/api/filter/filteradmin`,
        JSON.stringify({ filtro }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setUsuarios(response.data);

        const imageUrls = {};

        response.data.forEach((user) => {
          if (user.buffer === "") {
            imageUrls[user.id] = "";
          } else {
            const uint8Array = new Uint8Array(user.buffer.data);
            const blob = new Blob([uint8Array], { type: user.mimeType });
            const imageUrl = URL.createObjectURL(blob);

            imageUrls[user.id] = imageUrl;
          }
        });

        setUserImages(imageUrls);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        } 
        if (err.response.data !== "Nenhum administrador encontrado!") {
          showErrorSearch();
          setErrorMessage(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    limpaCampos();

    axios
      .get("https://www.api.qftech.com.br/api/list/listadmin", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setUsuarios(response.data);

        const imageUrls = {};

        response.data.forEach((user) => {
          if (user.buffer === "") {
            imageUrls[user.id] = "";
          } else {
            const uint8Array = new Uint8Array(user.buffer.data);
            const blob = new Blob([uint8Array], { type: user.mimeType });
            const imageUrl = URL.createObjectURL(blob);

            imageUrls[user.id] = imageUrl;
          }
        });

        setUserImages(imageUrls);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
        } else if (err.response.data !== "Nenhum administrador encontrado!") {
          showErrorSearch();
          setErrorMessage(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [access_token, navigate]);

  const limpaCampos = () => {
    setId("");
    setNome("");
    setCpf("");
    setRg("");
    setSexo("");
    setEstadoCivil("");
    setDataNascimento("");
    setNomeCompletoMae("");
    setNomeCompletoPai("");
    setCargo("");
    setEmail("");
    setCelular("");
    setTelefone("");
    setCep("");
    setRua("");
    setBairro("");
    setCidade("");
    setUf("");
    setNumeroEndereco("");
    setComplemento("");
    setAceiteTermos("");
    setStatus("");
    setIdImagemPerfil("");
    setImagemPerfilSelecionada("");
    setNomeArquivo("Nenhum arquivo foi selecionado");
    setValorFiltro("");
    setSenha("");
    setConfirmarSenha("");
  };

  return (
    <>
      {loading && (
        <DivLoading>
          <ReactLoading
            type="bars"
            color="#fff"
            width="100px"
            height="100px"
            className="loading"
          />
        </DivLoading>
      )}

      <HeaderAdmin />
      <ConteudoPrincipal>
        <NavBarAdmin />
        <ContainerOutNav>
          <Titulo>
            Seja bem-vindo a seção de <GreenText>administradores!</GreenText>
          </Titulo>
          <Subtitulo>
            Fazemos o possível para que seu dia possa ficar melhor e mais ágil!
          </Subtitulo>
          <div>
            <InputPesquisa
              type="text"
              placeholder="Faça uma pesquisa!"
              className="form-control"
              onChange={(event) => setValorFiltro(event.target.value)}
              value={valorFiltro}
              autoComplete="new-password"
              id="filtroPesquisa"
            />
            <Search icon={faSearch} onClick={() => filtrarAdministrador()} />
            <Add
              icon={faPlus}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              onClick={() => limpaCampos()}
            />
          </div>
          <TableContainer>
            <table className="table table-hover">
              <thead className="table-light">
                <tr>
                  <ColumnTitle scope="col">#</ColumnTitle>
                  <ColumnTitle scope="col">Nome</ColumnTitle>
                  <ColumnTitle scope="col">Email</ColumnTitle>
                  <ColumnTitle scope="col">CPF</ColumnTitle>
                  <ColumnTitle scope="col">Cargo</ColumnTitle>
                  <ColumnTitle scope="col">Editar</ColumnTitle>
                  <ColumnTitle scope="col">Excluir</ColumnTitle>
                </tr>
              </thead>
              <TableTBody
                className="table-group-divider"
                id="tbodyAdministrador"
              >
                {usuarios.length > 0 ? (
                  usersToDisplay
                ) : (
                  <tr>
                    <td colSpan="7">Nenhum administrador encontrado!</td>
                  </tr>
                )}
              </TableTBody>
            </table>
            <StyledPagination
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              breakLabel={"..."}
              pageCount={Math.ceil(usuarios.length / dataPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </TableContainer>
        </ContainerOutNav>
      </ConteudoPrincipal>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ overflow: "hidden" }}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edição de Dados</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações Pessoais</TituloEdit>
              <hr />
              <div className="row">
                <ColImageContainer className="col-md-4">
                  <UploadImageContainer>
                    <UploadImageConteudo onClick={handleOpenFilePicker}>
                      {imagem_perfil_selecionada && (
                        <ImageSelected
                          src={imagem_perfil_selecionada}
                          alt="Imagem Selecionada"
                        />
                      )}
                      <BigIcon icon={faImage} />
                    </UploadImageConteudo>
                    <DescricaoImage htmlFor="file_edicao" ref={inputFileRef}>
                      <SmallIcon icon={faFile} />
                      <SubtituloImagem>{nome_arquivo}</SubtituloImagem>
                      <SmallIcon icon={faTrashAlt} onClick={handleRemoveFile} />
                    </DescricaoImage>
                    <UploadInput
                      id="file_edicao"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </UploadImageContainer>
                </ColImageContainer>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Nome Completo
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="James Carter"
                      id="nome_edicao"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="rg_edicao" className="form-label">
                      RG
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={rg}
                      onChange={(event) => setRg(event.target.value)}
                      placeholder="XX-XX.XXX.XXX"
                      id="rg_edicao"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="estado_civil_edicao" className="form-label">
                      Estado Civil
                    </label>
                    <select
                      className="form-select"
                      id="estado_civil_edicao"
                      value={estado_civil}
                      onChange={handleEstadoCivil}
                    >
                      <option value="">Selecione um Estado Civil</option>
                      <option value="Solteiro">Solteiro</option>
                      <option value="Casado">Casado</option>
                      <option value="Divorciado">Divorciado</option>
                      <option value="Viúvo">Viúvo</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      for="nome_completo_mae_edicao"
                      className="form-label"
                    >
                      Nome Completo da Mãe
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome_completo_mae}
                      onChange={(event) =>
                        setNomeCompletoMae(event.target.value)
                      }
                      placeholder="Maria Conceição"
                      id="nome_completo_mae_edicao"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="cargo_edicao" className="form-label">
                      Cargo
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={cargo}
                      onChange={(event) => setCargo(event.target.value)}
                      placeholder="Coordenador de Marketing"
                      id="cargo_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cpf_edicao" className="form-label">
                      CPF
                    </label>
                    <InputMask
                      mask="___.___.___-__"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={cpf}
                      onChange={(event) => setCpf(event.target.value)}
                      placeholder="XXX.XXX.XXX-XX"
                      id="cpf_edicao"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="sexo_edicao" className="form-label">
                      Sexo
                    </label>
                    <select
                      className="form-select"
                      id="sexo_edicao"
                      value={sexo}
                      onChange={handleSexo}
                    >
                      <option value="">Selecione um Sexo</option>
                      <option value="Masculino">Masculino</option>
                      <option value="Feminino">Feminino</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="data_nascimento_edicao" className="form-label">
                      Data de Nascimento
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={data_nascimento}
                      onChange={(event) =>
                        setDataNascimento(event.target.value)
                      }
                      id="data_nascimento_edicao"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      for="nome_completo_pai_edicao"
                      className="form-label"
                    >
                      Nome Completo do Pai
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome_completo_pai}
                      onChange={(event) =>
                        setNomeCompletoPai(event.target.value)
                      }
                      placeholder="João Barbosa"
                      id="nome_completo_pai_edicao"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="status_edicao" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="status_edicao"
                      value={status}
                      onChange={handleStatus}
                    >
                      <option value="">Selecione um Status</option>
                      <option value="true">Ativo</option>
                      <option value="false">Inativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Contato</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="email_edicao" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      placeholder="email@email.com"
                      id="email_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="celular_edicao" className="form-label">
                      Celular
                    </label>
                    <InputMask
                      mask="(__) _ ____-____"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={celular}
                      onChange={(event) => setCelular(event.target.value)}
                      placeholder="(XX) X XXXX-XXXX"
                      id="celular_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="telefone_edicao" className="form-label">
                      Telefone
                    </label>
                    <InputMask
                      mask="(__) ____-____"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={telefone}
                      onChange={(event) => setTelefone(event.target.value)}
                      placeholder="(XX) XXXX-XXXX"
                      id="telefone_edicao"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Endereço</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cep_edicao" className="form-label">
                      CEP
                    </label>
                    <InputMask
                      mask="_____-___"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={cep}
                      onChange={(event) => setCep(event.target.value)}
                      onBlur={handleCep}
                      placeholder="XXXXX-XXX"
                      id="cep_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="rua_edicao" className="form-label">
                      Rua
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={rua}
                      onChange={(event) => setRua(event.target.value)}
                      placeholder="Av. Paulista"
                      id="rua_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="bairro_edicao" className="form-label">
                      Bairro
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={bairro}
                      onChange={(event) => setBairro(event.target.value)}
                      placeholder="Bela Vista"
                      id="bairro_edicao"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cidade_edicao" className="form-label">
                      Cidade
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={cidade}
                      onChange={(event) => setCidade(event.target.value)}
                      placeholder="São Paulo"
                      id="cidade_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="uf_edicao" className="form-label">
                      UF
                    </label>
                    <select
                      className="form-select"
                      id="uf_edicao"
                      value={uf}
                      onChange={handleUf}
                    >
                      <option value="">Selecione um UF</option>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="numero_edicao" className="form-label">
                      Número
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={numero_endereco}
                      onChange={(event) =>
                        setNumeroEndereco(event.target.value)
                      }
                      placeholder="115"
                      id="numero_edicao"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label for="complemento_edicao" className="form-label">
                      Complemento
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={complemento}
                      onChange={(event) => setComplemento(event.target.value)}
                      placeholder="Apartamento 204"
                      id="complemento_edicao"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Senha</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="senha_edicao" className="form-label">
                      Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={senha}
                      onChange={(event) => setSenha(event.target.value)}
                      placeholder="••••••"
                      id="senha_edicao"
                      autoComplete="new-password"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="confirmar_senha_edicao" className="form-label">
                      Confirmar Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={confirmar_senha}
                      onChange={(event) =>
                        setConfirmarSenha(event.target.value)
                      }
                      placeholder="••••••"
                      id="confirmar_senha_edicao"
                      autoComplete="new-password"
                      required
                    />
                    <FontAwesomeIcon
                      icon={btnEye}
                      onClick={mostraSenha}
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "42px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                ref={closeButtonUpdateRef}
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationUpdate}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ overflow: "hidden" }}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cadastro de Administrador</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações Pessoais</TituloEdit>
              <hr />
              <div className="row">
                <ColImageContainer className="col-md-4">
                  <UploadImageContainer>
                    <UploadImageConteudo onClick={handleOpenFilePicker}>
                      {imagem_perfil_selecionada && (
                        <ImageSelected
                          src={imagem_perfil_selecionada}
                          alt="Imagem Selecionada"
                        />
                      )}
                      <BigIcon icon={faImage} />
                    </UploadImageConteudo>
                    <DescricaoImage htmlFor="file" ref={inputFileRef}>
                      <SmallIcon icon={faFile} />
                      <SubtituloImagem>{nome_arquivo}</SubtituloImagem>
                      <SmallIcon icon={faTrashAlt} onClick={handleRemoveFile} />
                    </DescricaoImage>
                    <UploadInput
                      id="file"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </UploadImageContainer>
                </ColImageContainer>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Nome Completo
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="James Carter"
                      id="nome"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="rg" className="form-label">
                      RG
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={rg}
                      onChange={(event) => setRg(event.target.value)}
                      placeholder="XX-XX.XXX.XXX"
                      id="rg"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="estadoCivil" className="form-label">
                      Estado Civil
                    </label>
                    <select
                      className="form-select"
                      id="estadoCivil"
                      value={estado_civil}
                      onChange={handleEstadoCivil}
                    >
                      <option value="">Selecione um Estado Civil</option>
                      <option value="Solteiro">Solteiro</option>
                      <option value="Casado">Casado</option>
                      <option value="Divorciado">Divorciado</option>
                      <option value="Viúvo">Viúvo</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="nome_completo_mae" className="form-label">
                      Nome Completo da Mãe
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome_completo_mae}
                      onChange={(event) =>
                        setNomeCompletoMae(event.target.value)
                      }
                      placeholder="Maria Conceição"
                      id="nome_completo_mae"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="cargo" className="form-label">
                      Cargo
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={cargo}
                      onChange={(event) => setCargo(event.target.value)}
                      placeholder="Coordenador de Marketing"
                      id="cargo"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cpf" className="form-label">
                      CPF
                    </label>
                    <InputMask
                      mask="___.___.___-__"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={cpf}
                      onChange={(event) => setCpf(event.target.value)}
                      placeholder="XXX.XXX.XXX-XX"
                      id="cpf"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="sexo" className="form-label">
                      Sexo
                    </label>
                    <select
                      className="form-select"
                      id="sexo"
                      value={sexo}
                      onChange={handleSexo}
                    >
                      <option value="">Selecione um Sexo</option>
                      <option value="Masculino">Masculino</option>
                      <option value="Feminino">Feminino</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="dataNascimento" className="form-label">
                      Data de Nascimento
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={data_nascimento}
                      onChange={(event) =>
                        setDataNascimento(event.target.value)
                      }
                      id="dataNascimento"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="nome_completo_pai" className="form-label">
                      Nome Completo do Pai
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome_completo_pai}
                      onChange={(event) =>
                        setNomeCompletoPai(event.target.value)
                      }
                      placeholder="João Barbosa"
                      id="nome_completo_pai"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="status"
                      value={status}
                      onChange={handleStatus}
                    >
                      <option value="">Selecione um Status</option>
                      <option value="true">Ativo</option>
                      <option value="false">Inativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Contato</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      placeholder="email@email.com"
                      id="email"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="celular" className="form-label">
                      Celular
                    </label>
                    <InputMask
                      mask="(__) _ ____-____"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={celular}
                      onChange={(event) => setCelular(event.target.value)}
                      placeholder="(XX) X XXXX-XXXX"
                      id="celular"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="telefone" className="form-label">
                      Telefone
                    </label>
                    <InputMask
                      mask="(__) ____-____"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={telefone}
                      onChange={(event) => setTelefone(event.target.value)}
                      placeholder="(XX) XXXX-XXXX"
                      id="telefone"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Endereço</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cep" className="form-label">
                      CEP
                    </label>
                    <InputMask
                      mask="_____-___"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={cep}
                      onChange={(event) => setCep(event.target.value)}
                      onBlur={handleCep}
                      placeholder="XXXXX-XXX"
                      id="cep"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="rua" className="form-label">
                      Rua
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={rua}
                      onChange={(event) => setRua(event.target.value)}
                      placeholder="Av. Paulista"
                      id="rua"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="bairro" className="form-label">
                      Bairro
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={bairro}
                      onChange={(event) => setBairro(event.target.value)}
                      placeholder="Bela Vista"
                      id="bairro"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cidade" className="form-label">
                      Cidade
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={cidade}
                      onChange={(event) => setCidade(event.target.value)}
                      placeholder="São Paulo"
                      id="cidade"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="uf" className="form-label">
                      UF
                    </label>
                    <select
                      className="form-select"
                      id="uf"
                      value={uf}
                      onChange={handleUf}
                    >
                      <option value="">Selecione um UF</option>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="numero" className="form-label">
                      Número
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={numero_endereco}
                      onChange={(event) =>
                        setNumeroEndereco(event.target.value)
                      }
                      placeholder="115"
                      id="numero"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label for="complemento" className="form-label">
                      Complemento
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={complemento}
                      onChange={(event) => setComplemento(event.target.value)}
                      placeholder="Apartamento 204"
                      id="complemento"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Senha</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="senha" className="form-label">
                      Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={senha}
                      onChange={(event) => setSenha(event.target.value)}
                      placeholder="••••••"
                      id="senha"
                      autoComplete="new-password"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="confirmar_senha" className="form-label">
                      Confirmar Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={confirmar_senha}
                      onChange={(event) =>
                        setConfirmarSenha(event.target.value)
                      }
                      placeholder="••••••"
                      id="confirmar_senha"
                      autoComplete="new-password"
                      required
                    />
                    <FontAwesomeIcon
                      icon={btnEye}
                      onClick={mostraSenha}
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "42px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Termos</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <CheckBox
                    type="checkbox"
                    value={aceite_termos}
                    id="aceite_termos"
                    onChange={(event) => setAceiteTermos(event.target.checked)}
                  />
                  <label for="aceite_termos">Li e concordo com os termos e condições</label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                ref={closeButtonRegisterRef}
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationRegister}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <CustomModalConfirmCancel
        show={showConfirmationUpdateModal}
        onHide={hideConfirmationUpdate}
        title="Atenção"
        body="Tem certeza que deseja atualizar os dados?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => atualizarAdministrador(id)}
        onCancel={hideConfirmationUpdate}
      />

      <CustomModalConfirmCancel
        show={showConfirmationRegisterModal}
        onHide={hideConfirmationRegister}
        title="Atenção"
        body="Tem certeza que deseja cadastrar o administrador?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => cadastrarAdministrador()}
        onCancel={hideConfirmationRegister}
      />

      <CustomModalOnlyMessage
        show={showSuccessUpdateModal}
        onHide={hideSuccessUpdate}
        title="Sucesso"
        body="Os dados foram atualizados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showSuccessRegisterModal}
        onHide={hideSuccessRegister}
        title="Sucesso"
        body="Os dados foram cadastrados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorUpdateModal}
        onHide={hideErrorUpdate}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalConfirmCancel
        show={showConfirmationDeleteModal}
        onHide={hideConfirmationDelete}
        title="Atenção"
        body="Tem certeza que deseja deletar o usuário?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => deletarAdministrador(id)}
        onCancel={hideConfirmationDelete}
      />

      <CustomModalOnlyMessage
        show={showSuccessDeleteModal}
        onHide={hideSuccessDelete}
        title="Sucesso"
        body="Usuário deletado com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorDeleteModal}
        onHide={hideErrorDelete}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalInvalidLogin
        show={showInvalidLoginModal}
        onHide={hideInvalidLogin}
      />

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />
    </>
  );
}

export default AdministradorAdmin;
