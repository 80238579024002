import React from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
//import getCookie from '../assets/scripts/Client/getCookie.mjs';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const token = localStorage.getItem("token");

  axios
    .get("https://www.api.qftech.com.br/api/access/isLoggedIn", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => {})
    .catch((err) => {
      localStorage.removeItem("token");
      localStorage.removeItem("nome");
      localStorage.removeItem("funcao");
      localStorage.removeItem("imagem_perfil_buffer");
      localStorage.removeItem("imagem_perfil_mimeType");
      localStorage.removeItem("mesa");

      return <Navigate to="/" />;
    });

  return <Element {...rest} />;
};

export default PrivateRoute;
