import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleBtnPedido = {
  Btn: styled.button`
    border-radius: 5px;
    border: solid 1px ${(prop) => prop.border || "#FFF"};
    background: ${(prop) => prop.backGround || "#FFF"};
    padding: 5px 10px;
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    &.active {
      font-weight: 600;
      background: ${(prop) => prop.backGroundFocus || "#FFF"};
      border: solid 1px ${(prop) => prop.border || "#FFF"};
    }
  `,

  Icon: styled(FontAwesomeIcon)`
    margin-right: 5px;
    color: ${(prop) => prop.iconColor || "#fff"};
  `,
};

const { Btn, Icon } = StyleBtnPedido;

function BtnStatusPedido({
  icon,
  textoBtn,
  border,
  backGround,
  backGroundFocus,
  iconColor,
  isActive,
  onClick,
}) {
  return (
    <>
      <Btn
        border={border}
        backGround={backGround}
        backGroundFocus={backGroundFocus}
        onClick={onClick}
        className={isActive ? "active" : ""}
      >
        <Icon icon={icon} iconColor={iconColor} />
        {textoBtn}
      </Btn>
    </>
  );
}

export default BtnStatusPedido;