import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./pages/App";

const root = document.getElementById("root");
const renderApp = () => {
  createRoot(root).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

renderApp();
reportWebVitals();