import React, { useState } from "react";
import style from "./style.module.css";
import Logo from "../../../assets/img/logo.png";
import {
  faBars,
  faX,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";

function ResponsiveHeader() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);

    if (!menuOpen) {
      setLoginOpen(false);
      setSignupOpen(false);
    }
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const scrollToTop = () => {
    if (location.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/");
    }

    closeMenu();
  };

  const handleNavigation = (route) => {
    if (route === "/contato" || route === "/sobrenos") {
      const targetRoute = "/";
      const targetSectionId = route === "/contato" ? "contato" : "sobre";
      localStorage.setItem("targetSection", `${targetSectionId}`);

      const targetSection = document.getElementById(targetSectionId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }

      route = targetRoute;
    }

    navigate(route);
    closeMenu();
  };

  const toggleLoginList = () => {
    setLoginOpen(!loginOpen);
    setSignupOpen(false);
  };

  const toggleSignupList = () => {
    setSignupOpen(!signupOpen);
    setLoginOpen(false);
  };

  return (
    <header>
      <img
        className={`${location.pathname === "/" ? "active" : ""}`}
        onClick={scrollToTop}
        src={Logo}
        alt="Logo da QuickFood"
      />
      <input
        type="checkbox"
        className={style.menu_bar}
        id="menu-bar"
        checked={menuOpen}
      />
      <label htmlFor="menu-bar" onClick={toggleMenu}>
        {menuOpen ? (
          <FontAwesomeIcon icon={faX} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </label>

      <nav className={style.navbar}>
        <ul>
          <li>
            <h4
              className={`${location.pathname === "/" ? "active" : ""}`}
              onClick={scrollToTop}
            >
              Home
            </h4>
          </li>
          <li>
            <h4
              className={`${location.pathname === "/sobrenos" ? "active" : ""}`}
              onClick={() => handleNavigation("/sobrenos")}
            >
              Sobre nós
            </h4>
          </li>
          <li>
            <h4
              className={`${location.pathname === "/contato" ? "active" : ""}`}
              onClick={() => handleNavigation("/contato")}
            >
              Contato
            </h4>
          </li>
          <li>
            <h4
              className={`${location.pathname === "/planos" ? "active" : ""}`}
              onClick={() => handleNavigation("/planos")}
            >
              Planos
            </h4>
          </li>
          <li>
            <h4 onClick={toggleLoginList}>
              Log in
              {loginOpen ? (
                <FontAwesomeIcon className={style.angleDown} icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon
                  className={style.angleDown}
                  icon={faAngleDown}
                />
              )}
            </h4>
            <ul style={{ display: loginOpen ? "initial" : "none" }}>
              <li>
                <p
                  className={`${
                    location.pathname === "/loginrestaurante" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/loginrestaurante")}
                >
                  Restaurante
                </p>
              </li>
              <li>
                <p
                  className={`${
                    location.pathname === "/logincozinha" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/logincozinha")}
                >
                  Cozinha
                </p>
              </li>
              <li>
                <p
                  className={`${
                    location.pathname === "/logingarcom" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/logingarcom")}
                >
                  Garçom
                </p>
              </li>
              <li>
                <p
                  className={`${
                    location.pathname === "/logincliente" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/logincliente")}
                >
                  Cliente
                </p>
              </li>
              <li>
                <p
                  className={`${
                    location.pathname === "/loginadministrator" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/loginadministrator")}
                >
                  Administrador
                </p>
              </li>
            </ul>
          </li>
          <li>
            <h4 onClick={toggleSignupList}>
              Cadastre-se
              {signupOpen ? (
                <FontAwesomeIcon className={style.angleDown} icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon
                  className={style.angleDown}
                  icon={faAngleDown}
                />
              )}
            </h4>
            <ul style={{ display: signupOpen ? "initial" : "none" }}>
              <li>
                <p
                  className={`${
                    location.pathname === "/cadastrorestaurante" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/cadastrorestaurante")}
                >
                  Restaurante
                </p>
              </li>
              <li>
                <p
                  className={`${
                    location.pathname === "/cadastrocozinha" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/cadastrocozinha")}
                >
                  Cozinha
                </p>
              </li>
              <li>
                <p
                  className={`${
                    location.pathname === "/cadastrogarcom" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/cadastrogarcom")}
                >
                  Garçom
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default ResponsiveHeader;