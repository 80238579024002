import {
  faCheck,
  faFile,
  faImage,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import CadastroCardapioStyle from "../../../assets/styles/Cardapio/Cadastro/CadastroStyle";
import HeaderAdmin from "../../Admin/Header/Header_Admin";
import { useState } from "react";
import ModalImages from "../../Props/Modals/ModalImages";
import CustomModalConfirmCancel from "../../Common/Modal_Confirm_Cancel";
import CustomModalOnlyMessage from "../../Common/Modal_Only_Message";
import CustomModalInvalidLogin from "../../Common/Modal_Invalid_Login.jsx";
import axios from "axios";
import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const {
  ConteudoPrincipal,
  Titulo,
  Subtitulo,
  ContainerInformacoesProduto,
  ContainerImagens,
  ContainerObservacoesProduto,
  UploadImageContainer,
  UploadImageConteudo,
  DescricaoImage,
  UploadInput,
  BigIcon,
  Icon,
  SmallIcon,
  DefaulLabels,
  DefaultInput,
  SelecaoCategorias,
  Categorias,
  LongInput,
  BtnConcluir,
  DefaultBtn,
  ContainerInputsProduto,
  Images,
  ImageSelected,
  SubtituloImagem,
} = CadastroCardapioStyle;

function CadastroCardapio() {
  const navigate = useNavigate();
  const access_token = localStorage.getItem("token");
  const inputFileRef = useRef(null);
  const [token_restaurante, setTokenRestaurante] = useState("");
  const [nome_produto, setNomeProduto] = useState("");
  const [preco, setPreco] = useState("");
  const [quantidade_estoque, setQuantidadeEstoque] = useState("");
  const [id_categoria, setIdCategoria] = useState("");
  const [id_imagem, setIdImagem] = useState("");
  const [descricao_produto, setDescricaoProduto] = useState("");
  const [peso, setPeso] = useState("");
  const [descricao_nutricional, setDescricaoNutricional] = useState("");
  const [imagem, setImagem] = useState(null);
  const [titulo_imagem, setTituloImagem] = useState("");
  const [descricao_imagem, setDescricaoImagem] = useState("");
  const [tipo_imagem, setTipoImagem] = useState("");
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationRegisterModal, setShowConfirmationRegisterModal] =
    useState(false);
  const [showSuccessRegisterModal, setShowSuccessRegisterModal] =
    useState(false);
  const [showErrorRegisterModal, setShowErrorRegisterModal] = useState(false);
  const [imagesBD, setImagesBD] = useState([]);
  const [nomeArquivo, setNomeArquivo] = useState(
    "Nenhum arquivo foi selecionado"
  );
  const [imagemSelecionada, setImagemSelecionada] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [showInvalidLoginModal, setShowInvalidLoginModal] = useState(false);

  const handleIdCategoria = (event) => {
    setIdCategoria(event.target.value);
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleShowModal = () => {
    setShowModal(true);

    axios
      .get(`https://www.api.qftech.com.br/api/list/listimage`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        const images = response.data;
        setImagesBD(images);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          showInvalidLogin();

          setTimeout(() => {
            navigate("/");
          }, 2000);
        }

        console.log(err);
        showErrorRegister();
        setErrorMessage(err.response.data);
      });
  };
  const handleCloseModal = () => setShowModal(false);

  const showConfirmationRegister = () => {
    setShowConfirmationRegisterModal(true);
  };

  const hideConfirmationRegister = () => {
    setShowConfirmationRegisterModal(false);
  };

  const showSuccessRegister = () => {
    setShowSuccessRegisterModal(true);
  };

  const hideSuccessRegister = () => {
    setShowSuccessRegisterModal(false);
  };

  const showErrorRegister = () => {
    setShowErrorRegisterModal(true);
  };

  const hideErrorRegister = () => {
    setShowErrorRegisterModal(false);
  };

  const showInvalidLogin = () => {
    setShowInvalidLoginModal(true);
  };

  const hideInvalidLogin = () => {
    setShowInvalidLoginModal(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNomeArquivo(file.name);
      setImagem(file);

      const reader = new FileReader();
      reader.onload = () => {
        setImagemSelecionada(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveFile = () => {
    setImagemSelecionada(null);
    setNomeArquivo("Nenhum arquivo selecionado");
  };

  const saveImage = (id, title, type, url) => {
    setImagemSelecionada(url);
    setNomeArquivo(title);
    setIdImagem(id);
    setTipoImagem(type);
    setImagem(null);
    handleCloseModal();
  };

  const handleOpenFilePicker = () => {
    inputFileRef.current.click();
  };

  const bodyModal = imagesBD.map((image, index) => {
    const uint8Array = new Uint8Array(image.buffer.data);
    const blob = new Blob([uint8Array], { type: image.mimeType });
    const imageUrl = URL.createObjectURL(blob);

    if (image.type === "PRODUTO_GERAL") {
      return (
        <Images
          key={index}
          src={imageUrl}
          alt={image.title}
          onClick={() => saveImage(image.id, image.title, image.type, imageUrl)}
        />
      );
    }

    return null;
  });

  const cadastrarProduto = () => {
    const formData = new FormData();

    formData.append("token_restaurante", token_restaurante);
    formData.append("nome_produto", nome_produto);
    formData.append("preco", preco);
    formData.append("quantidade_estoque", quantidade_estoque);
    formData.append("status", status);
    formData.append("id_categoria", id_categoria);
    formData.append("id_imagem", id_imagem);
    formData.append("descricao_produto", descricao_produto);
    formData.append("peso", peso);
    formData.append("descricao_nutricional", descricao_nutricional);
    formData.append("imagem", imagem);
    formData.append("titulo_imagem", titulo_imagem);
    formData.append("descricao_imagem", descricao_imagem);
    formData.append("tipo_imagem", tipo_imagem);

    axios
      .post(`https://www.api.qftech.com.br/api/register/registerproduct`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        hideConfirmationRegister();
        showSuccessRegister();
        navigate("/admin/exibir_cardapio");
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          showInvalidLogin();

          setTimeout(() => {
            navigate("/");
          }, 2000);
        }

        console.log(err);
        hideConfirmationRegister();
        showErrorRegister();
        setErrorMessage(err.response.data);
      });
  };

  useEffect(() => {
    axios
      .get("https://www.api.qftech.com.br/api/list/listcategory", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        const categorias = response.data;
        setCategorias(categorias);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [access_token]);

  const formatarPreco = () => {
    let inputValor = preco;

    inputValor = inputValor.replace(/[^0-9,.]/g, "");
    inputValor = inputValor.replace(/,/g, ".");

    if (!inputValor || inputValor === ".") {
      inputValor = "0.00";
    }
    if (!inputValor.startsWith("R$")) {
      inputValor = `R$${inputValor}`;
    }

    setPreco(inputValor);
  };
  return (
    <>
      <ModalImages
        show={showModal}
        onHide={handleCloseModal}
        title={"Escolha uma imagem!"}
        body={bodyModal}
      />

      <CustomModalConfirmCancel
        show={showConfirmationRegisterModal}
        onHide={hideConfirmationRegister}
        title="Atenção"
        body="Tem certeza que deseja cadastrar os dados?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => cadastrarProduto()}
        onCancel={hideConfirmationRegister}
      />

      <CustomModalOnlyMessage
        show={showSuccessRegisterModal}
        onHide={hideSuccessRegister}
        title="Sucesso"
        body="Os dados foram cadastrados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorRegisterModal}
        onHide={hideErrorRegister}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalInvalidLogin
        show={showInvalidLoginModal}
        onHide={hideInvalidLogin}
      />

      <HeaderAdmin />

      <ConteudoPrincipal>
        <Titulo>Cadastre seu produto!</Titulo>
        <Subtitulo>
          Se você quer chamar atenção de seus clientes <br />
          capriche nas imagens, títulos e descrições!
        </Subtitulo>
        <ContainerInformacoesProduto>
          <ContainerImagens>
            <Subtitulo>Escolha aqui a imagem do seu produto:</Subtitulo>
            <UploadImageContainer>
              <UploadImageConteudo onClick={handleOpenFilePicker}>
                {imagemSelecionada && (
                  <ImageSelected
                    src={imagemSelecionada}
                    alt="Imagem Selecionada"
                  />
                )}
                <BigIcon icon={faImage} />
              </UploadImageConteudo>
              <DescricaoImage htmlFor="file" ref={inputFileRef}>
                <SmallIcon icon={faFile} />
                <SubtituloImagem>{nomeArquivo}</SubtituloImagem>
                <SmallIcon icon={faTrashAlt} onClick={handleRemoveFile} />
              </DescricaoImage>
              <UploadInput id="file" type="file" onChange={handleFileChange} />
            </UploadImageContainer>
            <Subtitulo>
              Está sem imagens no momento? <br />
              Temos algumas sugestões para você!
            </Subtitulo>
            <DefaultBtn onClick={handleShowModal}>
              Clique aqui para visualizar!
            </DefaultBtn>
          </ContainerImagens>
          <ContainerInputsProduto>
            <ContainerObservacoesProduto>
              <DefaulLabels>Token do Restaurante:</DefaulLabels>
              <DefaultInput
                type="text"
                placeholder="QUICK"
                value={token_restaurante}
                onChange={(event) => setTokenRestaurante(event.target.value)}
                id="token_restaurante"
              />
              <DefaulLabels>Nome do produto:</DefaulLabels>
              <DefaultInput
                type="text"
                placeholder="Hamburguer"
                value={nome_produto}
                onChange={(event) => setNomeProduto(event.target.value)}
                id="nome_produto"
              />
              <DefaulLabels>Preço:</DefaulLabels>
              <DefaultInput
                type="text"
                placeholder="R$"
                value={preco}
                onBlur={formatarPreco} // Formatar quando clicar fora
                onChange={(e) => setPreco(e.target.value)}
              />
              <DefaulLabels>Quantidade no estoque:</DefaulLabels>
              <DefaultInput
                type="text"
                placeholder="10"
                value={quantidade_estoque}
                onChange={(event) => setQuantidadeEstoque(event.target.value)}
                id="quantidade_estoque"
              />
              <DefaulLabels>Categoria:</DefaulLabels>
              <SelecaoCategorias
                value={id_categoria}
                onChange={handleIdCategoria}
                id="id_categoria"
              >
                <Categorias value="" disabled selected>
                  Escolha uma opção
                </Categorias>
                {categorias.map((categoria) => (
                  <Categorias key={categoria.id} value={categoria.id}>
                    {categoria.nome}
                  </Categorias>
                ))}
              </SelecaoCategorias>
              <DefaulLabels>Descrição do Produto (opcional):</DefaulLabels>
              <LongInput
                maxLength={123}
                placeholder="Produto de extrema qualidade"
                value={descricao_produto}
                onChange={(event) => setDescricaoProduto(event.target.value)}
                id="descricao_produto"
              ></LongInput>
            </ContainerObservacoesProduto>
            <ContainerObservacoesProduto>
              <DefaulLabels>Status:</DefaulLabels>
              <SelecaoCategorias value={status} onChange={handleStatus}>
                <Categorias value="" disabled selected>
                  Escolha uma opção
                </Categorias>
                <Categorias>Ativo</Categorias>
                <Categorias>Inativo</Categorias>
              </SelecaoCategorias>
              <DefaulLabels>Peso:</DefaulLabels>
              <DefaultInput
                type="text"
                placeholder="90ml/110g"
                value={peso}
                onChange={(event) => setPeso(event.target.value)}
                id="peso"
              />
              <DefaulLabels>Descrição Nutricional (opcional):</DefaulLabels>
              <LongInput
                placeholder="330 kcal"
                value={descricao_nutricional}
                onChange={(event) =>
                  setDescricaoNutricional(event.target.value)
                }
                id="descricao_nutricional"
              />
              <DefaulLabels>Título da imagem:</DefaulLabels>
              <DefaultInput
                type="text"
                placeholder="Cheese Bacon"
                value={titulo_imagem}
                onChange={(event) => setTituloImagem(event.target.value)}
                id="titulo_imagem"
              />
              <DefaulLabels>Descrição da imagem (opcional):</DefaulLabels>
              <LongInput
                placeholder="Cheese Bacon com muito cheddar"
                value={descricao_imagem}
                onChange={(event) => setDescricaoImagem(event.target.value)}
                id="descricao_imagem"
              />
            </ContainerObservacoesProduto>
          </ContainerInputsProduto>
        </ContainerInformacoesProduto>
        <BtnConcluir onClick={showConfirmationRegister}>
          SALVAR <Icon icon={faCheck} />
        </BtnConcluir>
      </ConteudoPrincipal>
    </>
  );
}

export default CadastroCardapio;