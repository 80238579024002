import StyleHeaderDefault from "../../../assets/styles/Pedidos/Header/StyleHeaderDefault";

const { ConteudoPrincipal, Title } = StyleHeaderDefault;

function HeaderDefault({
  nomeRestaurante,
  backGroundColor,
  height,
  colorText,
}) {
  return (
    <>
      <ConteudoPrincipal backGroundColor={backGroundColor} height={height}>
        <Title style={{ color: `${colorText}` }}>{nomeRestaurante}</Title>
      </ConteudoPrincipal>
    </>
  );
}

export default HeaderDefault;