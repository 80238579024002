import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const IconesBtns = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

export const IconesOpcoes = styled(FontAwesomeIcon)`
  margin-right: 15px;
`;

const HomeAdminStyle = {
  ConteudoPrincipal: styled.main`
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  `,

  Navbar: styled.nav`
    height: 100vh;
    background-color: #fff;
    width: 30vw;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `,

  ContainerDono: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    margin-top: 10px;
  `,

  FotoPerfil: styled.img`
    width: 90px;
    height: 90px;
  `,

  Nome: styled.h4`
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    margin: 0;
  `,

  Funcao: styled.h6`
    color: #969696;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 15px;
  `,

  BtnNovoFunc: styled.button`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none;
    background: #4da768;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding: 5px 25px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.5s;
    margin-top: 10px;
    width: 260px;
    text-align: center;

    &:hover {
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,

  ContainerControles: styled.div`
    margin-top: 20px;
    width: 100%;
  `,

  Opcoes: styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin-left: 25%;
    color: #1b2029;
  `,

  TextoOpcoes: styled.p`
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #1b2029d5;
    list-style: none;
  `,

  ConteudoGeral: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10vh;
  `,

  Titulo: styled.h1`
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    color: #1b2029d5;
    margin-left: 5vw;
  `,

  Subtitulo: styled.h4`
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #1b2029d5;
    margin-left: 5vw;
  `,

  ContainerGraficos: styled.div`
    margin-left: 5vw;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  `,

  ContainerCards: styled.div`
    margin-left: 5vw;
    margin-top: 4vh;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  `,

  GreenText: styled.span`
    color: #7da768;
    font-weight: 500;
  `,

  ContainerAvaliacoes: styled.div``,

  TituloAvaliacao: styled.h4``,
};

export default HomeAdminStyle;