import { Chart } from "chart.js/auto";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const ContainerGrafico = styled.div`
  width: 520px;
`;

function GraficoColuna() {
  const chartRef = useRef(null);

  useEffect(() => {
    const canvas = chartRef.current;
    const ctx = canvas.getContext("2d");

    let chart = null;

    // Destruir o gráfico existente, se houver
    if (chartRef.current) {
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }
      chartRef.current.innerHTML = "";
    }

    // Criar um novo gráfico
    chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
        datasets: [
          {
            label: "Movimentação de clientes no estabelecimento",
            data: [
              200, 400, 600, 1000, 900, 700, 850, 880, 800, 920, 950, 1200,
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
          },
        },
      },
    });

    // Armazenar a referência do gráfico no objeto de referência
    chartRef.current.chart = chart;
  }, []);

  return (
    <ContainerGrafico>
      <canvas ref={chartRef} />
    </ContainerGrafico>
  );
}

export default GraficoColuna;