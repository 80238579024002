import styled from "styled-components";

const CardCode = {
  ContainerOneCard: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px 20px;
    margin-left: 40px;
    margin-right: 40px;
    height: 350px;
    width: 285px;

    @media screen and (max-width: 1120px) {
      margin: 1.5em;
    }

    @media screen and (max-width: 999px) {
      width: 260px;
      height: 320px;
    }
  `,

  ImgCard: styled.img`
    width: 90px;
    height: 90px;

    @media screen and (max-width: 999px) {
      width: 80px;
      height: 80px;
    }
  `,

  TituloCard: styled.h4`
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;

    @media screen and (max-width: 1023px) {
      font-size: 15px;
    }
  `,

  DescricaoCard: styled.ul`
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  `,
};

function CardLista({
  titulo,
  descricao1,
  descricao2,
  descricao3,
  descricao4,
  descricao5,
  descricao6,
  imagem,
}) {
  return (
    <CardCode.ContainerOneCard>
      <CardCode.ImgCard src={imagem} />
      <CardCode.TituloCard>{titulo}</CardCode.TituloCard>
      <CardCode.DescricaoCard>
        <li>{descricao1}</li>
        <li>{descricao2}</li>
        <li>{descricao3}</li>
        <li>{descricao4}</li>
        <li>{descricao5}</li>
        <li>{descricao6}</li>
      </CardCode.DescricaoCard>
    </CardCode.ContainerOneCard>
  );
}

export default CardLista;