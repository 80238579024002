import React from "react";
import { Modal, Button } from "react-bootstrap";

function CustomModalInvalidLogin({ show, onHide }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Atenção</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Login inválido! Por favor faça o login e tente novamente!
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" data-bs-dismiss="modal" onClick={onHide}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModalInvalidLogin;