import WaiterRegisterStyle from "../../../assets/styles/Waiter/Waiter_Register_Style";
import Img_Cadastro from "../../../assets/img/icon_cadastro.png";
import background_big from "../../../assets/img/background_big.png";
//import bootbox from "bootbox";
import { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import buscarCep from "../../../assets/scripts/Request/buscaCep.mjs";
import { InputMask } from "@react-input/mask";
import BackButton from "../../Props/BackButton";

const {
  Principal,
  ContainerImagens,
  ImgFundo,
  ImgCadastro,
  ContainerFormulario,
  ContainerCampos,
  Titulo,
  Subtitulo,
  Selecao,
  Opcao,
  Formulario,
  Labels,
  Inputs,
  CheckBox,
  ContainerTermos,
  Cadastrar,
  DivItemLeft,
  DivItemRight,
  DivItem,
} = WaiterRegisterStyle;

function WaiterRegisterBody() {
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [cpf, setCpf] = useState("");
  const [data_nascimento, setDataNascimento] = useState("");
  const [sexo, setSexo] = useState("");
  const [estado_civil, setEstadoCivil] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [cep, setCep] = useState("");
  const [uf, setUf] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const [numero_endereco, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmar_senha, setConfirmarSenha] = useState("");
  const [aceite_termos, setAceiteTermos] = useState("");
  const [exibirSenha, setType] = useState("password");
  const [btnEye, setEye] = useState(faEyeSlash);

  const handleData = (event) => {
    event.preventDefault();

    const dados = {
      nome,
      sobrenome,
      cpf,
      data_nascimento,
      sexo,
      estado_civil,
      email,
      celular,
      cep,
      uf,
      cidade,
      bairro,
      rua,
      numero_endereco,
      complemento,
      senha,
      confirmar_senha,
      aceite_termos,
    };

    axios
      .post("https://www.api.qftech.com.br/api/register/registerwaiter", dados)
      .then((response) => {
        alert("Garçom cadastrado com sucesso!");
        console.log(response);
        setNome("");
        setSobrenome("");
        setCpf("");
        setDataNascimento("");
        setSexo("");
        setEstadoCivil("");
        setEmail("");
        setCelular("");
        setCep("");
        setUf("");
        setCidade("");
        setBairro("");
        setRua("");
        setNumero("");
        setComplemento("");
        setSenha("");
        setConfirmarSenha("");
        setAceiteTermos("");
        window.location.reload();
      })
      .catch((error) => {
        alert(error.response.data);
        console.log(error);
      });
  };

  const mostraSenha = () => {
    setType(exibirSenha === "text" ? "password" : "text");
    setEye(btnEye === faEyeSlash ? faEye : faEyeSlash);
  };

  const handleSexo = (event) => {
    setSexo(event.target.value);
  };

  const handleEstadoCivil = (event) => {
    setEstadoCivil(event.target.value);
  };

  const handleCep = async () => {
    try {
      const data = await buscarCep(cep);
      console.log(data.uf);
      setUf(data.uf);
      setCidade(data.localidade);
      setBairro(data.bairro);
      setRua(data.logradouro);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Principal>
      <BackButton />

      <ContainerImagens>
        <ImgFundo src={background_big} />
        <ImgCadastro src={Img_Cadastro} />
      </ContainerImagens>

      <ContainerFormulario>
        <Titulo>CREATE WAITER ACCOUNT</Titulo>

        <Formulario>
          <Subtitulo> Dados pessoais: </Subtitulo>
          <ContainerCampos>
            <DivItemLeft>
              <Labels>Nome:</Labels>
              <Inputs
                type="text"
                autoFocus
                value={nome}
                onChange={(event) => setNome(event.target.value)}
                placeholder="John"
              ></Inputs>
            </DivItemLeft>

            <DivItem>
              <Labels>Sobrenome:</Labels>
              <Inputs
                type="text"
                value={sobrenome}
                onChange={(event) => setSobrenome(event.target.value)}
                placeholder="Doe"
              ></Inputs>
            </DivItem>
          </ContainerCampos>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>CPF:</Labels>
              <InputMask
                mask="___.___.___-__"
                replacement={{ _: /\d/ }}
                component={Inputs}
                type="text"
                value={cpf}
                onChange={(event) => setCpf(event.target.value)}
                placeholder="XXX.XXX.XXX-XX"
              />
            </DivItemLeft>

            <DivItemLeft>
              <Labels>Data de Nasc:</Labels>
              <InputMask
                mask="__/__/____"
                replacement={{ _: /\d/ }}
                component={Inputs}
                type="text"
                value={data_nascimento}
                onChange={(event) => setDataNascimento(event.target.value)}
                placeholder="XX/XX/XXXX"
              />
            </DivItemLeft>

            <DivItem>
              <Labels>Gênero:</Labels>
              <Selecao value={sexo} onChange={handleSexo}>
                <Opcao value="">Selecione um gênero</Opcao>
                <Opcao value="Masculino">Masculino</Opcao>
                <Opcao value="Feminino">Feminino</Opcao>
                <Opcao value="Prefiro não dizer">Prefiro não dizer</Opcao>
              </Selecao>
            </DivItem>
          </ContainerCampos>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>Email:</Labels>
              <Inputs
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="johndoe@email.com"
              ></Inputs>
            </DivItemLeft>

            <DivItemLeft>
              <Labels>Celular:</Labels>
              <InputMask
                mask="(__) _ ____-____"
                replacement={{ _: /\d/ }}
                component={Inputs}
                type="text"
                value={celular}
                onChange={(event) => setCelular(event.target.value)}
                placeholder="(XX) 9 XXXX-XXXX"
              />
            </DivItemLeft>

            <DivItem>
              <Labels>Estado Civil:</Labels>
              <Selecao value={estado_civil} onChange={handleEstadoCivil}>
                <Opcao value="">Selecione um estado civil</Opcao>
                <Opcao value="Solteiro">Solteiro</Opcao>
                <Opcao value="Casado">Casado</Opcao>
                <Opcao value="Divorciado">Divorciado</Opcao>
                <Opcao value="Viúvo">Viúvo</Opcao>
              </Selecao>
            </DivItem>
          </ContainerCampos>

          <Subtitulo> Endereço: </Subtitulo>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>CEP:</Labels>
              <InputMask
                mask="_____-___"
                replacement={{ _: /\d/ }}
                component={Inputs}
                type="text"
                value={cep}
                onChange={(event) => setCep(event.target.value)}
                onBlur={handleCep}
                placeholder="XXXXX-XXX"
              />
            </DivItemLeft>

            <DivItemLeft>
              <Labels>UF:</Labels>
              <Selecao value={uf}>
                <Opcao value="">Selecione um estado</Opcao>
                <Opcao value="AC">AC</Opcao>
                <Opcao value="AL">AL</Opcao>
                <Opcao value="AP">AP</Opcao>
                <Opcao value="AM">AM</Opcao>
                <Opcao value="BA">BA</Opcao>
                <Opcao value="CE">CE</Opcao>
                <Opcao value="DF">DF</Opcao>
                <Opcao value="ES">ES</Opcao>
                <Opcao value="GO">GO</Opcao>
                <Opcao value="MA">MA</Opcao>
                <Opcao value="MT">MT</Opcao>
                <Opcao value="MS">MS</Opcao>
                <Opcao value="MG">MG</Opcao>
                <Opcao value="PA">PA</Opcao>
                <Opcao value="PB">PB</Opcao>
                <Opcao value="PR">PR</Opcao>
                <Opcao value="PE">PE</Opcao>
                <Opcao value="PI">PI</Opcao>
                <Opcao value="RJ">PI</Opcao>
                <Opcao value="RN">RN</Opcao>
                <Opcao value="RS">RS</Opcao>
                <Opcao value="RO">RO</Opcao>
                <Opcao value="RR">RR</Opcao>
                <Opcao value="SC">SC</Opcao>
                <Opcao value="SP">SP</Opcao>
                <Opcao value="SE">SE</Opcao>
                <Opcao value="TO">TO</Opcao>
              </Selecao>
            </DivItemLeft>

            <DivItem>
              <Labels>Cidade:</Labels>
              <Inputs
                type="text"
                value={cidade}
                placeholder="São Paulo"
              ></Inputs>
            </DivItem>
          </ContainerCampos>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>Bairro:</Labels>
              <Inputs
                type="text"
                value={bairro}
                placeholder="Bela Vista"
              ></Inputs>
            </DivItemLeft>

            <DivItemLeft>
              <Labels>Rua:</Labels>
              <Inputs
                type="text"
                value={rua}
                placeholder="Av. Paulista"
              ></Inputs>
            </DivItemLeft>

            <DivItem>
              <Labels>Número:</Labels>
              <Inputs
                type="text"
                value={numero_endereco}
                onChange={(event) => setNumero(event.target.value)}
                placeholder="110"
              ></Inputs>
            </DivItem>
          </ContainerCampos>

          <div>
            <Labels>Complemento:</Labels>
            <Inputs
              type="text"
              value={complemento}
              onChange={(event) => setComplemento(event.target.value)}
              placeholder="5º Andar"
            ></Inputs>
          </div>

          <Subtitulo> Senha: </Subtitulo>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>Senha:</Labels>
              <Inputs
                type={exibirSenha}
                value={senha}
                onChange={(event) => setSenha(event.target.value)}
                placeholder="••••••"
              ></Inputs>
            </DivItemLeft>

            <DivItemRight>
              <Labels>Confirmar Senha:</Labels>
              <Inputs
                type={exibirSenha}
                value={confirmar_senha}
                onChange={(event) => setConfirmarSenha(event.target.value)}
                placeholder="••••••"
              ></Inputs>
              <FontAwesomeIcon icon={btnEye} onClick={mostraSenha} />
            </DivItemRight>
          </ContainerCampos>

          <ContainerTermos>
            <div>
              <CheckBox
                type="checkbox"
                value={aceite_termos}
                onChange={(event) => setAceiteTermos(event.target.checked)}
              />
              <Labels> Li e concordo com os termos e condições </Labels>
            </div>

            <Cadastrar onClick={handleData} type="button">
              CADASTRAR
            </Cadastrar>
          </ContainerTermos>
        </Formulario>
      </ContainerFormulario>
    </Principal>
  );
}

export default WaiterRegisterBody;