import { faPen, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomModalConfirmCancel from "../Common/Modal_Confirm_Cancel";
import CustomModalOnlyMessage from "../Common/Modal_Only_Message";
import CustomModalInvalidLogin from "../Common/Modal_Invalid_Login";

const ItemCardapioStyle = {
  ContainerPrincipal: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 200px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0.5px solid #99e89d;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 40px;
  `,

  ImgAlimento: styled.img`
    width: 160px;
    max-height: 150px;
    max-width: 150px;
    margin-right: 20px;
    border-radius: 5px;
  `,

  ContainerDescricaoAlimento: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 8px;
  `,

  NomeAlimento: styled.h4`
    font-family: Poppins, "sans-serif";
    font-weight: 600;
    font-size: 20px;
  `,

  ValorAlimento: styled.h6`
    font-family: Poppins, "sans-serif";
    color: #26874e;
  `,

  DescricaoAlimento: styled.p`
    font-family: Poppins, "sans-serif";
    margin: 0;
    margin-bottom: 10px;
  `,

  ContainerCategoria: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,

  Icon: styled(FontAwesomeIcon)`
    color: #4da768;
    margin-right: 5px;
  `,

  Icon2: styled(FontAwesomeIcon)`
    color: #4da768;
    margin-left: 10px;
    &:hover {
      cursor: pointer;
    }
  `,

  CategoriaAlimento: styled.h6`
    font-family: Poppins, "sans-serif";
    margin-left: 5px;
    color: #4da768;
    font-weight: 400;
    margin: 0;
  `,

  ContainerOpcoes: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,

  ContainerEdicaExclusao: styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 0;
    margin-top: 15px;
    height: 100%;
  `,

  ContainerAtivoDesativo: styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    margin-right: 15px;
    margin-bottom: 15px;
  `,

  TextoAtivadoOuDesativado: styled.h6`
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    color: #26874e;
    font-weight: 400;
    margin: 0;
    margin-left: 10px;
  `,

  StyleLink: styled(Link)`
    text-decoration: none;
  `,
};

function ItemCardapio({
  imgAlimento,
  nomeAlimento,
  valorAlimento,
  descricaoAlimento,
  categoriaAlimento,
  status,
  idProduto,
}) {
  const access_token = localStorage.getItem("token");
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmationUpdateModal, setShowConfirmationUpdateModal] =
    useState(false);
  const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false);
  const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [showErrorDeleteModal, setShowErrorDeleteModal] = useState(false);
  const [showInvalidLoginModal, setShowInvalidLoginModal] = useState(false);
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);

  const hideConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(false);
  };

  const hideSuccessUpdate = () => {
    setShowSuccessUpdateModal(false);
  };

  const hideErrorUpdate = () => {
    setShowErrorUpdateModal(false);
  };

  const showConfirmationDelete = () => {
    setShowConfirmationDeleteModal(true);
  };

  const hideConfirmationDelete = () => {
    setShowConfirmationDeleteModal(false);
  };

  const showSuccessDelete = () => {
    setShowSuccessDeleteModal(true);
  };

  const hideSuccessDelete = () => {
    setShowSuccessDeleteModal(false);
  };

  const showErrorDelete = () => {
    setShowErrorDeleteModal(true);
  };

  const hideErrorDelete = () => {
    setShowErrorDeleteModal(false);
  };

  const hideInvalidLogin = () => {
    setShowInvalidLoginModal(false);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  function atualizarProduto(id) {
    axios
      .delete(
        `https://quick-food-api.vercel.app/api/delete/deletecategory/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        hideConfirmationDelete();
        showSuccessDelete();
      })
      .catch((err) => {
        console.log(err);
        hideConfirmationDelete();
        showErrorDelete();
        setErrorMessage(err.response.data);
      });
  }

  function deletarProduto(id) {
    axios
      .delete(
        `https://quick-food-api.vercel.app/api/delete/deleteproduct/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        hideConfirmationDelete();
        showSuccessDelete();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        hideConfirmationDelete();
        showErrorDelete();
        setErrorMessage(err.response.data);
      });
  }

  return (
    <>
      <ItemCardapioStyle.ContainerPrincipal>
        <ItemCardapioStyle.ImgAlimento src={imgAlimento} alt="imgAlimento" />
        <ItemCardapioStyle.ContainerDescricaoAlimento>
          <ItemCardapioStyle.NomeAlimento>
            {nomeAlimento}
          </ItemCardapioStyle.NomeAlimento>
          <ItemCardapioStyle.ValorAlimento>
            {valorAlimento}
          </ItemCardapioStyle.ValorAlimento>
          <ItemCardapioStyle.DescricaoAlimento>
            {descricaoAlimento}
          </ItemCardapioStyle.DescricaoAlimento>
          <ItemCardapioStyle.ContainerCategoria>
            <ItemCardapioStyle.Icon icon={faStar} />
            <ItemCardapioStyle.CategoriaAlimento>
              {categoriaAlimento}
            </ItemCardapioStyle.CategoriaAlimento>
          </ItemCardapioStyle.ContainerCategoria>
        </ItemCardapioStyle.ContainerDescricaoAlimento>
        <ItemCardapioStyle.ContainerOpcoes>
          <ItemCardapioStyle.ContainerEdicaExclusao>
            <ItemCardapioStyle.StyleLink
              to={`/admin/editar_cardapio?id=${idProduto}`}
            >
              <ItemCardapioStyle.Icon2 icon={faPen} />
            </ItemCardapioStyle.StyleLink>
            <div>
              <ItemCardapioStyle.Icon2
                icon={faTrash}
                onClick={() => showConfirmationDelete()}
              />
            </div>
          </ItemCardapioStyle.ContainerEdicaExclusao>
        </ItemCardapioStyle.ContainerOpcoes>
      </ItemCardapioStyle.ContainerPrincipal>

      <CustomModalConfirmCancel
        show={showConfirmationUpdateModal}
        onHide={hideConfirmationUpdate}
        title="Atenção"
        body="Tem certeza que deseja atualizar os dados?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => atualizarProduto(idProduto)}
        onCancel={hideConfirmationUpdate}
      />

      <CustomModalOnlyMessage
        show={showSuccessUpdateModal}
        onHide={hideSuccessUpdate}
        title="Sucesso"
        body="Os dados foram atualizados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorUpdateModal}
        onHide={hideErrorUpdate}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalConfirmCancel
        show={showConfirmationDeleteModal}
        onHide={hideConfirmationDelete}
        title="Atenção"
        body="Tem certeza que deseja deletar o produto?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => deletarProduto(idProduto)}
        onCancel={hideConfirmationDelete}
      />

      <CustomModalOnlyMessage
        show={showSuccessDeleteModal}
        onHide={hideSuccessDelete}
        title="Sucesso"
        body="Produto deletado com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorDeleteModal}
        onHide={hideErrorDelete}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalInvalidLogin
        show={showInvalidLoginModal}
        onHide={hideInvalidLogin}
      />

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />
    </>
  );
}

export default ItemCardapio;

/*
import ToggleSwitch from "./ToggleSwitch";

const [item, setItem] = useState(true)
    function handleToggle(novoEstado) {
        if (novoEstado) {
            setItem(true)
        } else {
            setItem(false)
        }
    };

<ItemCardapioStyle.ContainerAtivoDesativo>
                        <ToggleSwitch onToggle={handleToggle} />
                        {item ? <ItemCardapioStyle.TextoAtivadoOuDesativado>Ativo</ItemCardapioStyle.TextoAtivadoOuDesativado>
                            : <ItemCardapioStyle.TextoAtivadoOuDesativado>Inativo</ItemCardapioStyle.TextoAtivadoOuDesativado>}
                    </ItemCardapioStyle.ContainerAtivoDesativo>*/