import HeaderDefault from "../Header/HeaderDefault";
import StyleSucessPix from "../../../assets/styles/Pedidos/Sucess/StyleSucessPix";
import ImgPix from "../../../assets/img/aguardando_pagamento.png";
import Timer from "../../Props/Timer";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import copy from "clipboard-copy";
import { useNavigate } from "react-router-dom";

const {
  ConteudoPrincipal,
  ImagePix,
  Title,
  Texto,
  ContainerInput,
  InputCodPix,
  BtnCopiar,
  IconCopy,
  BtnCancelar,
} = StyleSucessPix;

function SucessPix() {
  const [textoInput, setTextoInput] = useState("");

  const navigate = useNavigate();
  const inputCodPixRef = useRef(null);

  const SelecionarTexto = () => {
    if (inputCodPixRef.current) {
      setTextoInput(inputCodPixRef.current.value);
    }
    copy(textoInput)
      .then(() => {
        alert("Código copiado!");
      })
      .catch((error) => {
        console.error("Erro ao copiar texto!");
      });
  };

  const backToHome = () => {
    navigate("/cliente/cardapio");
  };

  return (
    <>
      <HeaderDefault
        backGroundColor={"#4FAE6C"}
        height={"10vh"}
        nomeRestaurante={"QUICKFOOD"}
      />
      <ConteudoPrincipal>
        <ImagePix src={ImgPix} alt="imgPix" />
        <Title>Aguardando pagamento...</Title>
        <Texto>
          Copie o código abaixo e utilize o Pix Copia e Cola no aplicativo do
          banco em que deseja efeituar o pagamento.
        </Texto>
        <div>
          <Texto>Este código expira em:</Texto>
          <Timer />
        </div>
        <ContainerInput>
          <InputCodPix
            type="text"
            readOnly
            value={"4Rt9KpZs2YxW7vNqA3F6hC8EjU1L5oXm"}
            ref={inputCodPixRef}
          />
          <BtnCopiar onClick={SelecionarTexto}>
            Copiar <IconCopy icon={faCopy} />
          </BtnCopiar>
        </ContainerInput>
        <BtnCancelar onClick={backToHome}>Cancelar</BtnCancelar>
      </ConteudoPrincipal>
    </>
  );
}

export default SucessPix;