import KitchenRegisterStyle from "../../../assets/styles/Kitchen/Kitchen_Register_Style.js";
import Img_Fundo from "../../../assets/img/ImgDeFundo.png";
import Img_Cadastro from "../../../assets/img/icon_cadastro.png";
//import bootbox from "bootbox";
import { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import BackButton from "../../Props/BackButton.js";

const {
  Principal,
  ContainerImagens,
  ImgFundo,
  ImgCadastro,
  ContainerFormulario,
  Titulo,
  Formulario,
  Labels,
  DivInputIcon,
  Inputs,
  CheckBox,
  ContainerTermos,
  Cadastrar,
} = KitchenRegisterStyle;

function KitchenRegister() {
  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");
  const [email, setEmail] = useState("");
  const [confirmar_senha, setConfirmarSenha] = useState("");
  const [token_restaurante, setTokenRestaurante] = useState("");
  const [aceite_termos, setAceiteTermos] = useState("");
  const [exibirSenha, setType] = useState("password");
  const [btnEye, setEye] = useState(faEyeSlash);

  const handleData = (event) => {
    event.preventDefault();

    const dados = {
      nome,
      email,
      senha,
      confirmar_senha,
      token_restaurante,
      aceite_termos,
    };

    axios
      .post("https://www.api.qftech.com.br/api/register/registerkitchen", dados)
      .then((response) => {
        alert("Cozinha cadastrada com sucesso!");
        console.log(response);
      })
      .catch((error) => {
        alert(error.response.data);
        console.log(error);
      });
  };

  const mostraSenha = () => {
    setType(exibirSenha === "text" ? "password" : "text");
    setEye(btnEye === faEyeSlash ? faEye : faEyeSlash);
  };

  return (
    <Principal>
      <BackButton />

      <ContainerImagens>
        <ImgFundo src={Img_Fundo} />
        <ImgCadastro src={Img_Cadastro} />
      </ContainerImagens>

      <ContainerFormulario>
        <Titulo>CREATE KITCHEN ACCOUNT</Titulo>
        <Formulario>
          <Labels>NOME:</Labels>
          <Inputs
            type="text"
            autoFocus
            value={nome}
            onChange={(event) => setNome(event.target.value)}
            placeholder="John Doe"
          ></Inputs>

          <Labels>EMAIL:</Labels>
          <Inputs
            type="text"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="email@email.com"
          ></Inputs>

          <Labels>SENHA:</Labels>
          <DivInputIcon>
            <Inputs
              type={exibirSenha}
              value={senha}
              onChange={(event) => setSenha(event.target.value)}
              placeholder="••••••"
            ></Inputs>
            <FontAwesomeIcon icon={btnEye} onClick={mostraSenha} />
          </DivInputIcon>

          <Labels>CONFIRMAR SENHA:</Labels>
          <Inputs
            type={exibirSenha}
            value={confirmar_senha}
            onChange={(event) => setConfirmarSenha(event.target.value)}
            placeholder="••••••"
          ></Inputs>

          <Labels>TOKEN RESTAURANTE:</Labels>
          <Inputs
            type="text"
            value={token_restaurante}
            onChange={(event) => setTokenRestaurante(event.target.value)}
            placeholder="QUI7A"
          ></Inputs>

          <ContainerTermos>
            <CheckBox
              type="checkbox"
              value={aceite_termos}
              onChange={(event) => setAceiteTermos(event.target.checked)}
            />
            <Labels>Li e concordo com os termos e condições</Labels>
          </ContainerTermos>

          <Cadastrar onClick={handleData} type="button">
            CADASTRAR
          </Cadastrar>
        </Formulario>
      </ContainerFormulario>
    </Principal>
  );
}

export default KitchenRegister;