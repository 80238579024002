import styled from "styled-components";
import { useNavigate } from "react-router-dom";
const CardCode = {
  ContainerOneCard: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px 20px;
    margin-left: 40px;
    margin-right: 40px;
    height: 350px;
    width: 285px;

    @media screen and (max-width: 1120px) {
      margin: 1.5em;
    }

    @media screen and (max-width: 999px) {
      width: 260px;
      height: 320px;
    }
  `,

  ImgCard: styled.img`
    width: 90px;
    height: 90px;
    object-fit: cover;

    @media screen and (max-width: 999px) {
      width: 80px;
      height: 80px;
    }
  `,

  TituloCard: styled.h4`
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;

    @media screen and (max-width: 1023px) {
      font-size: 15px;
    }
  `,

  DescricaoCard: styled.h6`
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
    margin: 0;

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  `,

  BtnDefault: styled.button`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none;
    background: #4da768;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding: 5px 25px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.5s;
    margin-top: 20px;

    &:hover {
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  `,
};

function Card({
  titulo,
  descricao1,
  descricao2,
  descricao3,
  imagem,
  textoBtn,
  linkBtn,
}) {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <CardCode.ContainerOneCard>
      <CardCode.ImgCard src={imagem} />
      <CardCode.TituloCard>{titulo}</CardCode.TituloCard>
      <CardCode.DescricaoCard>{descricao1}</CardCode.DescricaoCard>
      <CardCode.DescricaoCard>{descricao2}</CardCode.DescricaoCard>
      <CardCode.DescricaoCard>{descricao3}</CardCode.DescricaoCard>
      <CardCode.BtnDefault onClick={() => handleNavigation(`/${linkBtn}`)}>
        {textoBtn}
      </CardCode.BtnDefault>
    </CardCode.ContainerOneCard>
  );
}

export default Card;