import styled from "styled-components";
import { Link } from "react-router-dom";

const StyleHeader = {
  Cabecalho: styled.header`
    position: sticky;
    width: 100%;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #e1fae2;
    color: #1b2029d5;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 2;
  `,

  ImgLogo: styled.img`
    width: 91px;
    height: 54px;
    transition: all 0.5s;

    &:hover {
      transition: all 0.5s;
      filter: drop-shadow(0 0 2em #26874e);
      cursor: pointer;
    }
  `,

  Pages: styled.h4`
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #1b2029d9;
  `,

  GreenText: styled.span`
    color: #26874e;
  `,

  LinkStyle: styled(Link)`
    text-decoration: none;
  `,
};

export default StyleHeader;