import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { InputMask } from "@react-input/mask";

const StyleContainerAddCartao = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 15px;
    position: sticky;
    z-index: 3;
    bottom: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  `,

  IconClose: styled(FontAwesomeIcon)`
    align-self: flex-end;
  `,

  ContainerSubtitleEInput: styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,

  Title: styled.h2`
    font-family: Poppins, "sans-serif";
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px !important;
  `,

  SubTitles: styled.h4`
    font-family: Poppins, "sans-serif";
    font-size: 16px;
    font-weight: 500;
  `,

  Input: styled.input`
    margin-top: 5px;
    border-radius: 5px;
    border: 0.7px solid #ddd;
    padding-left: 5px;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    font-size: 14px;
    height: 30px;
    width: 100%;
    &:focus {
      outline: none;
    }
  `,

  BtnCadastrar: styled.button`
    align-self: center;
    border: none;
    border-radius: 5px;
    background: #1b2029;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: Poppins, "sans-serif";
    padding: 3px 15px;
    transition: all 0.5s;
    font-weight: 500;
    width: 200px;
    margin-top: 20px;
    margin-bottom: 40px;
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #4da768;
      color: #fff;
    }
  `,
};

const {
  ConteudoPrincipal,
  IconClose,
  ContainerSubtitleEInput,
  Title,
  SubTitles,
  Input,
  BtnCadastrar,
} = StyleContainerAddCartao;

function ContainerAddCartao({ funcaoClose, funcaoCadastrar }) {
  return (
    <>
      <ConteudoPrincipal>
        <IconClose icon={faX} onClick={funcaoClose} />
        <Title>Adicionar cartão</Title>
        <ContainerSubtitleEInput>
          <SubTitles>Nome no cartão*</SubTitles>
          <Input autoFocus type="text" placeholder="JOHN DOE" />
        </ContainerSubtitleEInput>
        <ContainerSubtitleEInput>
          <SubTitles>Número do Cartão*</SubTitles>
          <InputMask
            mask="____ ____ ____ ____"
            replacement={{ _: /\d/ }}
            type="text"
            component={Input}
            placeholder="0000 0000 0000 0000"
          />
        </ContainerSubtitleEInput>
        <ContainerSubtitleEInput>
          <SubTitles>Validade do Cartão*</SubTitles>
          <InputMask
            mask="__/__"
            replacement={{ _: /\d/ }}
            type="text"
            component={Input}
            placeholder="MM/AA"
          />
        </ContainerSubtitleEInput>
        <ContainerSubtitleEInput>
          <SubTitles>CVV*</SubTitles>
          <InputMask
            mask="___"
            replacement={{ _: /\d/ }}
            type="text"
            component={Input}
            placeholder="123"
          />
        </ContainerSubtitleEInput>
        <BtnCadastrar onClick={funcaoCadastrar}>Adicionar Cratão</BtnCadastrar>
      </ConteudoPrincipal>
    </>
  );
}

export default ContainerAddCartao;