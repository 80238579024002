import React, { useState, useRef } from "react";
import {
  faPlus,
  faSearch,
  faImage,
  faFile,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import StyledImagemAdmin from "../../../../assets/styles/Admin/Cardapio/Imagem/StyleImagemAdmin";
import HeaderAdmin from "../../Header/Header_Admin";
import NavBarAdmin from "../../Navbar/NavbarAdmin";
import $ from "jquery";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import CustomModalConfirmCancel from "../../../Common/Modal_Confirm_Cancel";
import CustomModalOnlyMessage from "../../../Common/Modal_Only_Message";
import CustomModalInvalidLogin from "../../../Common/Modal_Invalid_Login";
import formatBytes from "../../../../assets/scripts/Formater/BytesFormater";

const {
  ConteudoPrincipal,
  TableContainer,
  Titulo,
  ContainerOutNav,
  GreenText,
  InputPesquisa,
  Search,
  ColumnTitle,
  Subtitulo,
  TableTBody,
  TituloEdit,
  Add,
  UploadImageContainer,
  UploadImageConteudo,
  BigIcon,
  ImageSelected,
  DescricaoImage,
  SmallIcon,
  SubtituloImagem,
  UploadInput,
} = StyledImagemAdmin;

function ImagemAdmin() {
  let imagens;
  const navigate = useNavigate();
  const inputFileRef = useRef(null);
  const access_token = localStorage.getItem("token");
  const confirmButton = document.querySelector('[data-bs-dismiss="modal"]');
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [valorFiltro, setValorFiltro] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmationUpdateModal, setShowConfirmationUpdateModal] =
    useState(false);
  const [showConfirmationRegisterModal, setShowConfirmationRegisterModal] =
    useState(false);
  const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false);
  const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [showErrorDeleteModal, setShowErrorDeleteModal] = useState(false);
  const [showInvalidLoginModal, setShowInvalidLoginModal] = useState(false);
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);
  const [nomeArquivo, setNomeArquivo] = useState(
    "Nenhum arquivo foi selecionado"
  );
  const [imagemSelecionada, setImagemSelecionada] = useState(null);
  const [imagem, setImagem] = useState(null);

  const showConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(true);
  };

  const hideConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(false);
  };

  const showConfirmationRegister = () => {
    setShowConfirmationRegisterModal(true);
  };

  const hideConfirmationRegister = () => {
    setShowConfirmationRegisterModal(false);
  };

  const showSuccessUpdate = () => {
    setShowSuccessUpdateModal(true);
  };

  const hideSuccessUpdate = () => {
    setShowSuccessUpdateModal(false);
  };

  const showErrorUpdate = () => {
    setShowErrorUpdateModal(true);
  };

  const hideErrorUpdate = () => {
    setShowErrorUpdateModal(false);
  };

  const showConfirmationDelete = () => {
    setShowConfirmationDeleteModal(true);
  };

  const hideConfirmationDelete = () => {
    setShowConfirmationDeleteModal(false);
  };

  const showSuccessDelete = () => {
    setShowSuccessDeleteModal(true);
  };

  const hideSuccessDelete = () => {
    setShowSuccessDeleteModal(false);
  };

  const showErrorDelete = () => {
    setShowErrorDeleteModal(true);
  };

  const hideErrorDelete = () => {
    setShowErrorDeleteModal(false);
  };

  const showInvalidLogin = () => {
    setShowInvalidLoginModal(true);
  };

  const hideInvalidLogin = () => {
    setShowInvalidLoginModal(false);
  };

  const showErrorSearch = () => {
    setShowErrorSearchModal(true);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  const handlePenClick = function () {
    const id = $(this).data("id");
    const imagemAtual = imagens.find((imagem) => imagem.id === id);

    if (imagemAtual) {
      setId(imagemAtual.id);
      setTitle(imagemAtual.title);
      setDescription(imagemAtual.description);
      setType(imagemAtual.type);
    }
  };

  const handleTrashClick = function () {
    const id = $(this).data("id");

    setId(id);
    showConfirmationDelete();
  };

  const handleOpenFilePicker = () => {
    inputFileRef.current.click();
  };

  const handleRemoveFile = () => {
    setImagemSelecionada(null);
    setNomeArquivo("Nenhum arquivo selecionado");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNomeArquivo(file.name);
      setImagem(file);

      const reader = new FileReader();
      reader.onload = () => {
        setImagemSelecionada(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const cadastrarImagem = () => {
    const formData = new FormData();

    formData.append("imagem", imagem);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("type", type);

    axios
      .post(`https://www.api.qftech.com.br/api/register/registerimage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        hideConfirmationRegister();
        showSuccessUpdate();

        if (confirmButton) {
          confirmButton.click();
        }

        setImagem(null);
        setTitle("");
        setDescription("");
        setType("");
      })
      .catch((err) => {
        console.log(err);
        hideConfirmationRegister();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      });
  };

  const dados = {
    title,
    description,
    type,
  };

  const atualizarImagem = (id) => {
    axios
      .put(
        `https://www.api.qftech.com.br/api/update/updateimage/${id}`,
        JSON.stringify(dados),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        hideConfirmationUpdate();
        showSuccessUpdate();

        if (confirmButton) {
          confirmButton.click();
        }
      })
      .catch((err) => {
        console.log(err);
        hideConfirmationUpdate();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      });
  };

  const deletarImagem = (id) => {
    axios
      .delete(`https://www.api.qftech.com.br/api/delete/deleteimage/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        hideConfirmationDelete();
        showSuccessDelete();
      })
      .catch((err) => {
        console.log(err);
        hideConfirmationDelete();
        showErrorDelete();
        setErrorMessage(err.response.data);
      });
  };

  const filtro = valorFiltro;

  const filtrarImagem = () => {
    axios
      .post(
        `https://www.api.qftech.com.br/api/filter/filterimage`,
        JSON.stringify({ filtro }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        imagens = response.data;

        $("#tbodyImagem").empty();

        $.each(response.data, function (index, item) {
          const newRow = `
                        <tr>
                            <th>${index}</th>
                            <td>${item.title}</td>
                            <td>${item.mimeType}</td>
                            <td>${formatBytes(item.size)}</td>
                            <td>${item.type}</td>
                            <td>
                                <i
                                    class="fa fa-pen"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    data-id="${item.id}"
                                ></i>
                            </td>
                            <td>
                                <i class="fa fa-trash" data-id="${item.id}"></i>
                            </td>
                        </tr>
                    `;
          $("#tbodyImagem").append(newRow);
        });
      })
      .catch((err) => {
        console.log(err);
        showErrorDelete();
        setErrorMessage(err.response.data);
      });
  };

  $(document).on("click", ".fa-pen", handlePenClick);
  $(document).on("click", ".fa-trash", handleTrashClick);

  axios
    .get("https://www.api.qftech.com.br/api/list/listimage", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      withCredentials: true,
    })
    .then((response) => {
      if (response.data.length !== 0) {
        imagens = response.data;

        $("#tbodyImagem").empty();

        $.each(response.data, function (index, item) {
          const newRow = `
                    <tr>
                        <th>${index}</th>
                        <td>${item.title}</td>
                        <td>${item.mimeType}</td>
                        <td>${formatBytes(item.size)}</td>
                        <td>${item.type}</td>
                        <td>
                            <i
                                class="fa fa-pen"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                data-id="${item.id}"
                            ></i>
                        </td>
                        <td>
                            <i class="fa fa-trash" data-id="${item.id}"></i>
                        </td>
                    </tr>
                `;
          $("#tbodyImagem").append(newRow);
        });
      } else {
        $("#tbodyImagem").empty();

        const newRow = `
                <tr>
                    <th colspan="7">Nenhuma imagem encontrada!</th>
                </tr>
            `;
        $("#tbodyImagem").append(newRow);
      }
    })
    .catch((err) => {
      if (
        err.response.data ===
        "Login inválido! Por favor faça o login e tente novamente!"
      ) {
        showInvalidLogin();

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else if (err.response.data !== "Nenhuma imagem encontrada!") {
        console.log(err);
        showErrorSearch();
        setShowErrorSearchModal(err.response.data);
      } else {
        $("#tbodyImagem").empty();

        const newRow = `
                <tr>
                    <th colspan="7">Nenhuma iamgem encontrada!</th>
                </tr>
            `;
        $("#tbodyImagem").append(newRow);
      }
    });

  return (
    <>
      <HeaderAdmin />
      <ConteudoPrincipal>
        <NavBarAdmin />
        <ContainerOutNav>
          <Titulo>
            Seja bem-vindo a seção de <GreenText>imagens!</GreenText>
          </Titulo>
          <Subtitulo>
            Fazemos o possível para que seu dia possa ficar melhor e mais ágil!
          </Subtitulo>
          <div>
            <InputPesquisa
              type="text"
              placeholder="Faça uma pesquisa!"
              className="form-control"
              onChange={(event) => setValorFiltro(event.target.value)}
              id="filtroPesquisa"
            />
            <Search icon={faSearch} onClick={filtrarImagem} />
            <Add
              icon={faPlus}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
            />
          </div>

          <TableContainer>
            <table className="table table-hover">
              <thead className="table-light">
                <tr>
                  <ColumnTitle scope="col">#</ColumnTitle>
                  <ColumnTitle scope="col">Título</ColumnTitle>
                  <ColumnTitle scope="col">Extensão</ColumnTitle>
                  <ColumnTitle scope="col">Tamanho</ColumnTitle>
                  <ColumnTitle scope="col">Tipo</ColumnTitle>
                  <ColumnTitle scope="col">Editar</ColumnTitle>
                  <ColumnTitle scope="col">Excluir</ColumnTitle>
                </tr>
              </thead>
              <TableTBody
                className="table-group-divider"
                id="tbodyImagem"
              ></TableTBody>
            </table>
          </TableContainer>
        </ContainerOutNav>
      </ConteudoPrincipal>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edição de Dados</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Título
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                      placeholder="Hamburguer"
                      id="title"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Tipo
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={type}
                      onChange={(event) => setType(event.target.value)}
                      placeholder="PRODUTO_GERAL"
                      id="type"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Descrição
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      placeholder="Hamburguer delicioso"
                      id="description"
                      style={{ height: "125px" }}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationUpdate}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cadastro de Imagem</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <UploadImageContainer>
                      <UploadImageConteudo onClick={handleOpenFilePicker}>
                        {imagemSelecionada && (
                          <ImageSelected
                            src={imagemSelecionada}
                            alt="Imagem Selecionada"
                          />
                        )}
                        <BigIcon icon={faImage} />
                      </UploadImageConteudo>
                      <DescricaoImage htmlFor="file" ref={inputFileRef}>
                        <SmallIcon icon={faFile} />
                        <SubtituloImagem>{nomeArquivo}</SubtituloImagem>
                        <SmallIcon
                          icon={faTrashAlt}
                          onClick={handleRemoveFile}
                        />
                      </DescricaoImage>
                      <UploadInput
                        id="file"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </UploadImageContainer>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Título
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                      placeholder="Hamburguer"
                      id="title"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Descrição
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      placeholder="Hamburguer delicioso"
                      id="description"
                      style={{ height: "200px" }}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Tipo
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={type}
                      onChange={(event) => setType(event.target.value)}
                      placeholder="PRODUTO_GERAL"
                      id="type"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationRegister}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <CustomModalConfirmCancel
        show={showConfirmationUpdateModal}
        onHide={hideConfirmationUpdate}
        title="Atenção"
        body="Tem certeza que deseja atualizar os dados?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => atualizarImagem(id)}
        onCancel={hideConfirmationUpdate}
      />

      <CustomModalConfirmCancel
        show={showConfirmationRegisterModal}
        onHide={hideConfirmationRegister}
        title="Atenção"
        body="Tem certeza que deseja cadastrar a imagem?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => cadastrarImagem()}
        onCancel={hideConfirmationRegister}
      />

      <CustomModalOnlyMessage
        show={showSuccessUpdateModal}
        onHide={hideSuccessUpdate}
        title="Sucesso"
        body="Os dados foram atualizados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorUpdateModal}
        onHide={hideErrorUpdate}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalConfirmCancel
        show={showConfirmationDeleteModal}
        onHide={hideConfirmationDelete}
        title="Atenção"
        body="Tem certeza que deseja deletar a imagem?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => deletarImagem(id)}
        onCancel={hideConfirmationDelete}
      />

      <CustomModalOnlyMessage
        show={showSuccessDeleteModal}
        onHide={hideSuccessDelete}
        title="Sucesso"
        body="Imagem deletada com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorDeleteModal}
        onHide={hideErrorDelete}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalInvalidLogin
        show={showInvalidLoginModal}
        onHide={hideInvalidLogin}
      />

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />
    </>
  );
}

export default ImagemAdmin;