import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleNavBar = {
  ConteudoPrincipal: styled.div`
    width: 6%;
    height: 100vh;
    background: #e1fae2;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    padding-top: 50px;
  `,

  Icons: styled(FontAwesomeIcon)`
    color: #1b2029;
  `,
};

export default StyleNavBar;