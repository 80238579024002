import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StylePagamento = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    min-height: 85vh;
    padding-left: 15px;
    padding-right: 15px;
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.containerAddCard ? "#dadadaa4" : "#f8f5e5a2"};
  `,

  ContainerNome: styled.div`
    width: 100%;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,

  Title: styled.h2`
    font-family: Poppins, "sans-serif";
    font-size: 18px;
    font-weight: 600;
  `,

  InputNome: styled.input`
    margin-top: 10px;
    border-radius: 5px;
    border: 0.7px solid #ddd;
    background: #f8f5e5a2;
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    padding-left: 5px;
    width: 100%;
    height: 35px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    &:focus {
      outline: none;
    }
  `,

  ContainerFormasDePagamento: styled.div`
    width: 100%;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,

  ContainerIcons: styled.div`
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
  `,

  IconsPagamento: styled(FontAwesomeIcon)`
    font-size: 25px;
    color: ${(props) => props.color || "#1b2029"};
  `,

  IconMasterCard: styled.img`
    max-width: 30px;
    max-height: 28px;
  `,

  ContainerPagamento: styled.div`
    width: 100%;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,

  ContainerTipoPagamento: styled.div`
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: solid 0.5px #ddd;
    padding: 3px 10px;
    border-radius: 5px;
  `,

  ContainerDirectionRow: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `,

  TextoTipoPagamento: styled.h6`
    font-family: Poppins, "sans-serif";
    font-size: 14px;
  `,

  ContainerConfirmarPedido: styled.div`
    width: 100%;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,

  ContainerTextoEPreco: styled.div`
    margin-top: 2px;
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
  `,

  TextosConfirmacao: styled.h4`
    font-family: Poppins, "sans-serif";
    color: ${(props) => props.color || "#1b2029"};
    font-size: 15px;
    font-weight: 600;
  `,

  ValorConfirmacao: styled.h4`
    justify-self: end;
    font-family: Poppins, "sans-serif";
    color: ${(props) => props.color || "#1b2029"};
    font-size: 15px;
    font-weight: 600;
  `,

  SpaceContainer: styled.div`
    width: 100%;
    margin-top: 20px;
  `,

  ContainerCpfNaNota: styled.div`
    margin-top: 3vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.7px solid #ddd;
    border-radius: 5px;
    padding: 15px 10px 10px 10px;
  `,

  ContainerIconETexto: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `,

  TextoCpf: styled.h6`
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    margin-left: 5px !important;
  `,

  CheckIcon: styled.img`
    max-width: 15px;
    max-height: 15px;
  `,

  ContainerCpf: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: center;
  `,

  InputCpf: styled.input`
    border: none;
    background-color: transparent;
    font-family: Poppins, "sans-serif";
    margin-top: 5px;
    &:focus {
      outline: none;
    }
  `,

  BtnEditCpf: styled.button`
    justify-self: end;
    border-radius: 50px;
    background-color: #4da768;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
  `,

  IconEditCpf: styled(FontAwesomeIcon)`
    color: #fff;
    font-size: 14px;
  `,

  BtnFinalizarPedido: styled.button`
    justify-self: center;
    align-self: end;
    border: none;
    border-radius: 5px;
    background: #73c883;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: Poppins, "sans-serif";
    padding: 3px 15px;
    transition: all 0.5s;
    font-weight: 500;
    width: 200px;
    margin-top: 20px;
    margin-bottom: 40px;
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,
};

export default StylePagamento;