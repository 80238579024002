import styled from "styled-components";
import "../../fonts/Raleway.js";
import "../../fonts/Inter";
import "../../fonts/Poppins.js";

const PlanosStyle = {
  Banner: styled.div`
    background: #73c883;
    width: 100vw;
    height: 70vh;
    display: flex;
    flex-direction: row;

    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10vh 2vh 0 2vh;

    @media screen and (max-width: 672px) {
      height: 62vh;
    }

    @media screen and (max-width: 462px) {
      height: 60vh;
    }

    @media screen and (max-width: 407px) {
      height: 57vh;
    }
  `,

  Title: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Imagem: styled.img`
    width: 313px;
    height: 303px;

    @media screen and (max-width: 986px) {
      display: none;
    }
  `,

  SubTitle: styled.p`
    color: #fff;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 1048px) {
      font-size: 16px;
    }

    @media screen and (max-width: 986px) {
      text-align: center;
    }

    @media screen and (max-width: 672px) {
      font-size: 14px;
    }

    @media screen and (max-width: 462px) {
      font-size: 12px;
    }
  `,

  Titulo: styled.p`
    color: #fff;
    font-size: 57px;
    font-weight: bold;
    font-family: "Raleway";
    margin: 0;
    padding: 0;
    line-height: 95%;
    margin-bottom: 2.5vh;
    margin-top: 1.6vh;

    @media screen and (max-width: 1048px) {
      font-size: 50px;
    }

    @media screen and (max-width: 986px) {
      text-align: center;
    }

    @media screen and (max-width: 672px) {
      font-size: 40px;
    }

    @media screen and (max-width: 462px) {
      font-size: 35px;
    }

    @media screen and (max-width: 407px) {
      font-size: 30px;
    }
  `,

  Button2: styled.button`
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
    color: #26874e;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: none;
    margin-top: 2vh;
    cursor: pointer;
    width: 15vh;
    height: 3.5vh;
    transition: background-color 0.4s;

    &:hover {
      background: #006633;
      color: #fff;
    }

    @media screen and (max-width: 1048px) {
      font-size: 14px;
    }

    @media screen and (max-width: 986px) {
      margin: 4vh auto 0 auto;
    }

    @media screen and (max-width: 672px) {
      font-size: 12px;
    }

    @media screen and (max-width: 462px) {
      width: 13vh;
      font-size: 10px;
    }
  `,

  // Planos

  Plans: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: -10vh;
    flex-wrap: wrap;
    padding: 0 2vh;

    @media screen and (max-width: 946px) {
      gap: 2.5em;
    }

    @media screen and (max-width: 680px) {
      gap: 3em;
    }
  `,

  PlanoCard: styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-rows: repeat(7, auto);
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 15px;
    width: 42vh;
    height: 65vh;
    padding-top: 2vh;
    padding-bottom: 3vh;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 822px) {
      height: 58vh;
    }

    @media screen and (max-width: 632px) {
      height: 58vh;
    }

    @media screen and (max-width: 463px) {
      height: 55vh;
    }

    @media screen and (max-width: 408px) {
      width: 41vh;
      height: 50vh;
    }
  `,

  Name: styled.p`
    color: #000000;
    justify-self: center;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;

    @media screen and (max-width: 1048px) {
      font-size: 15px;
    }

    @media screen and (max-width: 672px) {
      font-size: 13px;
    }
  `,

  NameGreen: styled.p`
    color: #26874e;
    justify-self: center;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;

    @media screen and (max-width: 1048px) {
      font-size: 15px;
    }

    @media screen and (max-width: 672px) {
      font-size: 13px;
    }
  `,

  Preco: styled.p`
    display: flex;
    justify-self: center;
    justify-content: center;
    width: 33vh;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;

    border-bottom: 1px solid #cbcbcb;
    padding-bottom: 10px;
    margin-bottom: 3vh;

    @media screen and (max-width: 1048px) {
      font-size: 15px;
    }

    @media screen and (max-width: 672px) {
      font-size: 14px;
    }

    @media screen and (max-width: 462px) {
      font-size: 12px;
    }
  `,

  Span: styled.span`
    color: #000000;
    font-size: 70px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    display: inline-block;
    vertical-align: top;
    margin-top: -1.3vh;

    @media screen and (max-width: 1048px) {
      font-size: 60px;
    }

    @media screen and (max-width: 672px) {
      font-size: 50px;
    }

    @media screen and (max-width: 462px) {
      font-size: 40px;
    }
  `,

  DivLista: styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    column-gap: 5px;
  `,

  CheckImage: styled.img`
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-right: 0.5vh;

    @media screen and (max-width: 672px) {
      width: 25px;
      height: 25px;
    }
  `,

  ItemLlisa: styled.p`
    margin: auto 0;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;

    @media screen and (max-width: 672px) {
      font-size: 13px;
    }

    @media screen and (max-width: 462px) {
      font-size: 12px;
    }
  `,

  Comment: styled.p`
    color: #686262;
    text-align: center;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    margin-top: -2.5vh;
    margin-bottom: 0;

    @media screen and (max-width: 672px) {
      font-size: 11px;
    }
  `,

  Title2: styled.h2`
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    margin-top: 15vh;
    margin-bottom: 10vh;
    text-align: center;
    text-shadow: 0.1em 0.1em 0.2em #1b202927;
    font-size: 35px;

    @media screen and (max-width: 1023px) {
      font-size: 30px;
    }

    @media screen and (max-width: 822px) {
      font-size: 25px;
    }

    @media screen and (max-width: 438px) {
      font-size: 20px;
    }
  `,

  GreenText: styled.span`
    color: #26874e;
  `,

  Button3: styled.button`
    justify-self: center;
    border-radius: 5px;
    background: #73c883;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: none;
    cursor: pointer;
    width: 15vh;
    height: 3.5vh;
    transition: background-color 0.4s;

    &:hover {
      background: #26874e;
      color: #fff;
    }

    @media screen and (max-width: 672px) {
      width: 13.5vh;
      font-size: 13px;
    }

    @media screen and (max-width: 462px) {
      width: 12vh;
    }
  `,

  Button4: styled.button`
    justify-self: center;
    border-radius: 5px;
    background: #ff5f5d;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: none;
    cursor: pointer;
    width: 15vh;
    height: 3.5vh;
    transition: background-color 0.4s;

    &:hover {
      background: #e01e1d;
      color: #fff;
    }
  `,

  DivCompare: styled.div`
    display: grid;
    grid-template-rows: repeat(13, auto);
    justify-content: center;
    margin-bottom: 10vh;
  `,

  Linha1: styled.div`
    padding-left: 2vh;
    background: #fff;
    display: grid;
    grid-template-columns: 50vw 15vw 15vw;
    width: 85vw;
    height: 14vh;
    justify-content: center;
    align-content: center;
  `,

  Title3: styled.h2`
    justify-self: start;
    align-self: center;
    color: rgba(0, 0, 0, 0.95);
    font-size: 34px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    margin: 0;

    @media screen and (max-width: 1023px) {
      font-size: 28px;
    }

    @media screen and (max-width: 822px) {
      font-size: 23px;
    }

    @media screen and (max-width: 617px) {
      font-size: 20px;
    }
  `,

  BtnBasico: styled.div`
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: -2vh;
  `,

  BtnAvancado: styled.div`
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: -2vh;
  `,

  Texto: styled.h3`
    color: rgba(0, 0, 0, 0.95);
    font-size: 26px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
    padding: 0;

    @media screen and (max-width: 1023px) {
      font-size: 21px;
    }

    @media screen and (max-width: 822px) {
      font-size: 18px;
    }

    @media screen and (max-width: 617px) {
      font-size: 15px;
    }
  `,

  Button5: styled.button`
    justify-self: center;
    border-radius: 5px;
    background: #73c883;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: none;
    cursor: pointer;
    width: 15vh;
    height: 3.5vh;
    transition: background-color 0.4s;
    margin-bottom: 5px;
    margin: 0 1vw 5px 1vw;

    &:hover {
      background: #26874e;
    }

    @media screen and (max-width: 1023px) {
      width: 13vh;
      font-size: 14px;
    }

    @media screen and (max-width: 822px) {
      width: 11vh;
      font-size: 12px;
    }

    @media screen and (max-width: 617px) {
      width: 9vh;
      font-size: 10px;
    }
  `,

  Button6: styled.button`
    justify-self: center;
    border-radius: 5px;
    background: #26874e;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: none;
    cursor: pointer;
    width: 15vh;
    height: 3.5vh;
    margin: 0 1vw 5px 1vw;

    &:hover {
      background-color: #99e89d;
      color: #1b2029;
      transition: all 0.5s;
    }

    @media screen and (max-width: 672px) {
      width: 13.5vh;
      font-size: 13px;
    }

    @media screen and (max-width: 462px) {
      width: 12vh;
      font-size: 11px;
    }

    @media screen and (max-width: 1023px) {
      width: 13vh;
      font-size: 14px;
    }

    @media screen and (max-width: 822px) {
      width: 11vh;
      font-size: 12px;
    }

    @media screen and (max-width: 617px) {
      width: 9vh;
      font-size: 10px;
    }
  `,

  Linha2: styled.div`
    padding-left: 2vh;
    background: #f8f8f8;
    display: grid;
    grid-template-columns: 50vw 15vw 15vw;
    width: 85vw;
    height: 10vh;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid #f8f8f8;
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border: 2px solid #4da768;
      border-radius: 0.25rem;
      overflow: visible;
    }
  `,

  Linha3: styled.div`
    padding-left: 2vh;
    background: #fff;
    display: grid;
    grid-template-columns: 50vw 15vw 15vw;
    width: 85vw;
    height: 10vh;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid #fff;
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border: 2px solid #4da768;
      border-radius: 0.25rem;
      overflow: visible;
    }
  `,

  P: styled.p`
    justify-self: start;
    color: #000;
    font-size: 20px;
    font-family: "Poppins", sans-serif;

    @media screen and (max-width: 1023px) {
      font-size: 17px;
    }

    @media screen and (max-width: 822px) {
      font-size: 15px;
    }

    @media screen and (max-width: 438px) {
      font-size: 13px;
    }
  `,

  Verde: styled.img`
    justify-self: center;
    width: 34px;
    height: 34px;
    object-fit: cover;

    @media screen and (max-width: 822px) {
      width: 30px;
      height: 30px;
    }
  `,

  Red: styled.img`
    justify-self: center;
    width: 24px;
    height: 24px;

    @media screen and (max-width: 822px) {
      width: 20px;
      height: 20px;
    }
  `,

  FOOTER: styled.div`
    display: flex;
    margin-top: 20vh;
    background: #f5f5f5;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    width: 100vw;
    height: 35vh;
  `,

  LogoDiV: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,

  LogoImagem: styled.img`
    width: 213px;
    height: 188px;
  `,

  Subtitulo: styled.p`
    color: #063;
    text-align: center;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  `,

  DivTexto: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,

  T1: styled.p`
    color: #000;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
  `,

  P1: styled.a`
    color: #000;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  `,

  P2: styled.a`
    color: #063;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    text-align: center;
    text-decoration: none;
    margin-top: 2vh;
    cursor: pointer;

    &:hover {
      color: #063;
      text-decoration: underline;
    }
  `,

  DivRedes: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,

  Rede: styled.a`
    text-decoration: none;
  `,

  RedeImg: styled.img`
    width: 26.48px;
    height: 26.48px;
    margin-left: 0.5vh;
    margin-right: 0.5vh;
  `,
};

export default PlanosStyle;