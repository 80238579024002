import RestRegisterStyle from "../../../assets/styles/Restaurant/Restaurant_Register_Style.js";
import LogoImg from "../../../assets/img/logo.png";
import background_big from "../../../assets/img/background_big.png";
import Img_Cadastro from "../../../assets/img/icon_cadastro.png";
import { useState } from "react";
import axios from "axios";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import buscarCep from "../../../assets/scripts/Request/buscaCep.mjs";
import { InputMask } from "@react-input/mask";
import $ from "jquery";
import BackButton from "../../Props/BackButton.js";

const {
  Principal,
  ContainerImagens,
  ImgFundo,
  ImgLogo,
  ImgCadastro,
  ContainerFormulario,
  ContainerCampos,
  Titulo,
  Subtitulo,
  Selecao,
  Opcao,
  Formulario,
  Labels,
  DivItemLeft,
  DivItemRight,
  DivItem,
  Inputs,
  CheckBox,
  ContainerTermos,
  Cadastrar,
  EyeIcons,
  ContainerInfoExtra,
} = RestRegisterStyle;

function RestaurantRegisterBodyTeste() {
  const [nome, setNome] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [data_abertura, setDataAbertura] = useState("");
  const [tipo, setTipo] = useState("");
  const [email_institucional, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [uf, setUf] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const [numero_endereco, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmar_senha, setConfirmarSenha] = useState("");
  const [aceite_termos, setAceiteTermos] = useState("");
  const [cor_primaria, setCorPrimaria] = useState("");
  const [cor_secundaria, setCorSecundaria] = useState("");
  const [id_imagem_logo, setIdImagemLogo] = useState();
  const [imagem, setImagem] = useState(null);
  const [tituloImagem, setTituloImagem] = useState("");
  const [descricaoImagem, setDescricaoImagem] = useState("");
  const [tipoImagem, setTipoImagem] = useState();
  const [exibirSenha, setType] = useState("password");
  const [btnEye, setEye] = useState(faEyeSlash);

  const handleData = (event) => {
    event.preventDefault(); // Evita o recarregamento da página

    const formData = new FormData();

    formData.append("nome", nome);
    formData.append("cnpj", cnpj);
    formData.append("data_abertura", data_abertura);
    formData.append("tipo", tipo);
    formData.append("email_institucional", email_institucional);
    formData.append("telefone", telefone);
    formData.append("cep", cep);
    formData.append("uf", uf);
    formData.append("cidade", cidade);
    formData.append("bairro", bairro);
    formData.append("rua", rua);
    formData.append("numero_endereco", numero_endereco);
    formData.append("complemento", complemento);
    formData.append("senha", senha);
    formData.append("confirmar_senha", confirmar_senha);
    formData.append("aceite_termos", aceite_termos);
    formData.append("cor_primaria", cor_primaria);
    formData.append("cor_secundaria", cor_secundaria);
    formData.append("id_imagem_logo", id_imagem_logo);
    formData.append("imagem", imagem);
    formData.append("tituloImagem", tituloImagem);
    formData.append("descricaoImagem", descricaoImagem);
    formData.append("tipoImagem", tipoImagem);

    axios
      .post("https://www.api.qftech.com.br/api/register/registerrestaurant", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert("Restaurante cadastrado com sucesso!");
        setNome("");
        setCnpj("");
        setDataAbertura("");
        setTipo("");
        setEmail("");
        setTelefone("");
        setCep("");
        setUf("");
        setCidade("");
        setBairro("");
        setRua("");
        setNumero("");
        setComplemento("");
        setSenha("");
        setConfirmarSenha("");
        setAceiteTermos("");
        setCorPrimaria();
        setCorSecundaria();
        setIdImagemLogo();
        setImagem();
        setTituloImagem();
        setDescricaoImagem();
        setTipoImagem();
        window.location.reload();
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Campo NOME DA EMPRESA inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#nome").focus();
        } else if (
          err.response.data ===
          "Campo CNPJ inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#cnpj").focus();
        } else if (
          err.response.data ===
          "Campo DATA DE ABERTURA inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#data_abertura").focus();
        } else if (
          err.response.data ===
          "Campo TIPO inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#tipo").focus();
        } else if (
          err.response.data ===
          "Campo EMAIL INSTITUCIONAL inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#email_institucional").focus();
        } else if (
          err.response.data ===
          "Campo TELEFONE inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#telefone").focus();
        } else if (
          err.response.data ===
          "Campo CEP inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#cep").focus();
        } else if (
          err.response.data ===
          "Campo UF inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#uf").focus();
        } else if (
          err.response.data ===
          "Campo CIDADE inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#cidade").focus();
        } else if (
          err.response.data ===
          "Campo BAIRRO inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#bairro").focus();
        } else if (
          err.response.data ===
          "Campo RUA inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#rua").focus();
        } else if (
          err.response.data ===
          "Campo NÚMERO DO ENDEREÇO inválido! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#numero_endereco").focus();
        } else if (
          err.response.data ===
          "Campo SENHA inválido! Preencha-o corretamente e tente novamente! Obs.: Ele deve possuir no mínimo 6 caracteres!"
        ) {
          alert(err.response.data);
          $("#senha").focus();
        } else if (
          err.response.data ===
          "O campo SENHA deve ser igual ao CONFIRMAR SENHA! Preencha-o corretamente e tente novamente!"
        ) {
          alert(err.response.data);
          $("#confirmarSenha").focus();
        } else if (
          err.response.data ===
          "Caso queira utilizar nosso software, você deve concordar com os termos!"
        ) {
          alert(err.response.data);
          $("#aceite_termos").focus();
        } else {
          console.log(err);
          alert(err.response.data);
        }
      });
  };

  const mostraSenha = () => {
    setType(exibirSenha === "text" ? "password" : "text");
    setEye(btnEye === faEyeSlash ? faEye : faEyeSlash);
  };

  const handleTipo = (event) => {
    setTipo(event.target.value);
  };

  const handleCep = async () => {
    try {
      const data = await buscarCep(cep);
      setUf(data.uf);
      setCidade(data.localidade);
      setBairro(data.bairro);
      setRua(data.logradouro);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagem(file);
      setTituloImagem("LOGO");
      setDescricaoImagem("LOGO");
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };

  return (
    <Principal>
      <BackButton />

      <ContainerImagens>
        <ImgFundo src={background_big} />
        <ImgLogo src={LogoImg} />
        <ImgCadastro src={Img_Cadastro} />
      </ContainerImagens>

      <ContainerFormulario>
        <Titulo>CREATE RESTAURANT ACCOUNT</Titulo>
        <Formulario>
          <Subtitulo> Dados da Empresa: </Subtitulo>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>Nome da empresa:</Labels>
              <Inputs
                type="text"
                autoFocus
                value={nome}
                id="nome"
                onChange={(event) => setNome(event.target.value)}
                placeholder="QuickFood"
              ></Inputs>
            </DivItemLeft>

            <DivItemRight>
              <Labels>CNPJ:</Labels>
              <InputMask
                mask="__.___.___/____-__"
                replacement={{ _: /\d/ }}
                component={Inputs}
                type="text"
                id="cnpj"
                value={cnpj}
                onChange={(event) => setCnpj(event.target.value)}
                placeholder="XX.XXX.XXX/XXXX-XX"
              />
            </DivItemRight>
          </ContainerCampos>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>Data de abertura:</Labels>
              <InputMask
                mask="__/__/____"
                replacement={{ _: /\d/ }}
                component={Inputs}
                type="text"
                id="data_abertura"
                value={data_abertura}
                onChange={(event) => setDataAbertura(event.target.value)}
                placeholder="XX/XX/XXXX"
              />
            </DivItemLeft>

            <DivItemRight>
              <Labels>Tipo:</Labels>
              <Selecao value={tipo} onChange={handleTipo} id="tipo">
                <Opcao value="">Selecione um tipo</Opcao>
                <Opcao value="Restaurante">Restaurante</Opcao>
                <Opcao value="Lanchonete">Lanchonete</Opcao>
                <Opcao value="Bar">Bar</Opcao>
                <Opcao value="Outro">Outro</Opcao>
              </Selecao>
            </DivItemRight>
          </ContainerCampos>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>Email:</Labels>
              <Inputs
                type="email"
                value={email_institucional}
                id="email_institucional"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="quickfood@email.com"
              ></Inputs>
            </DivItemLeft>

            <DivItemRight>
              <Labels>Telefone:</Labels>
              <InputMask
                mask="(__) _ ____-____"
                replacement={{ _: /\d/ }}
                component={Inputs}
                type="text"
                id="telefone"
                value={telefone}
                onChange={(event) => setTelefone(event.target.value)}
                placeholder="(XX) XXXX-XXXX"
              />
            </DivItemRight>
          </ContainerCampos>

          <Subtitulo> Endereço: </Subtitulo>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>CEP:</Labels>
              <InputMask
                mask="_____-___"
                replacement={{ _: /\d/ }}
                component={Inputs}
                type="text"
                value={cep}
                id="cep"
                onChange={(event) => setCep(event.target.value)}
                onBlur={handleCep}
                placeholder="XXXXX-XXX"
              />
            </DivItemLeft>

            <DivItemLeft>
              <Labels>UF:</Labels>
              <Selecao value={uf} id="uf">
                <Opcao value="">Selecione um estado</Opcao>
                <Opcao value="AC">Acre</Opcao>
                <Opcao value="AL">Alagoas</Opcao>
                <Opcao value="AP">Amapá</Opcao>
                <Opcao value="AM">Amazonas</Opcao>
                <Opcao value="BA">Bahia</Opcao>
                <Opcao value="CE">Ceará</Opcao>
                <Opcao value="DF">Distrito Federal</Opcao>
                <Opcao value="ES">Espírito Santo</Opcao>
                <Opcao value="GO">Goiás</Opcao>
                <Opcao value="MA">Maranhão</Opcao>
                <Opcao value="MT">Mato Grosso</Opcao>
                <Opcao value="MS">Mato Grosso do Sul</Opcao>
                <Opcao value="MG">Minas Gerais</Opcao>
                <Opcao value="PA">Pará</Opcao>
                <Opcao value="PB">Paraíba</Opcao>
                <Opcao value="PR">Paraná</Opcao>
                <Opcao value="PE">Pernambuco</Opcao>
                <Opcao value="PI">Piauí</Opcao>
                <Opcao value="RJ">Rio de Janeiro</Opcao>
                <Opcao value="RN">Rio Grande do Norte</Opcao>
                <Opcao value="RS">Rio Grande do Sul</Opcao>
                <Opcao value="RO">Rondônia</Opcao>
                <Opcao value="RR">Roraima</Opcao>
                <Opcao value="SC">Santa Catarina</Opcao>
                <Opcao value="SP">São Paulo</Opcao>
                <Opcao value="SE">Sergipe</Opcao>
                <Opcao value="TO">Tocantins</Opcao>
              </Selecao>
            </DivItemLeft>

            <DivItem>
              <Labels>Cidade:</Labels>
              <Inputs
                type="text"
                value={cidade}
                id="cidade"
                placeholder="São Paulo"
              ></Inputs>
            </DivItem>
          </ContainerCampos>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>Bairro:</Labels>
              <Inputs
                type="text"
                value={bairro}
                id="bairro"
                placeholder="Bela Vista"
              ></Inputs>
            </DivItemLeft>

            <DivItemLeft>
              <Labels>Rua:</Labels>
              <Inputs
                type="text"
                value={rua}
                id="rua"
                placeholder="Av. Paulista"
              ></Inputs>
            </DivItemLeft>

            <DivItem>
              <Labels>Número:</Labels>
              <Inputs
                type="text"
                value={numero_endereco}
                id="numero_endereco"
                onChange={(event) => setNumero(event.target.value)}
                placeholder="110"
              ></Inputs>
            </DivItem>
          </ContainerCampos>

          <div>
            <Labels>Complemento:</Labels>
            <Inputs
              type="text"
              value={complemento}
              onChange={(event) => setComplemento(event.target.value)}
              placeholder="5º Andar"
            ></Inputs>
          </div>

          <Subtitulo> Senha: </Subtitulo>

          <ContainerCampos>
            <DivItemLeft>
              <Labels>Senha:</Labels>
              <Inputs
                type={exibirSenha}
                value={senha}
                id="senha"
                onChange={(event) => setSenha(event.target.value)}
                placeholder="••••••"
              ></Inputs>
            </DivItemLeft>
            <DivItemRight>
              <Labels> Confirmar Senha: </Labels>
              <Inputs
                type={exibirSenha}
                value={confirmar_senha}
                id="confirmarSenha"
                onChange={(event) => setConfirmarSenha(event.target.value)}
                placeholder="••••••"
              ></Inputs>
            </DivItemRight>
            <EyeIcons icon={btnEye} onClick={mostraSenha} />
          </ContainerCampos>

          <ContainerInfoExtra>
            <Labels>Logotipo:</Labels>
            <div className="mb-3">
              <input
                className="form-control"
                type="file"
                id="formFile"
                onChange={handleFileChange}
              />
            </div>
          </ContainerInfoExtra>

          <ContainerInfoExtra>
            <Labels>Escolha duas cores que representam seu negócio:</Labels>
            <ContainerCampos>
              <input
                type="color"
                className="form-control form-control-color"
                onChange={(event) => setCorPrimaria(event.target.value)}
              />
              <input
                type="color"
                className="form-control form-control-color"
                onChange={(event) => setCorSecundaria(event.target.value)}
              />
            </ContainerCampos>
          </ContainerInfoExtra>

          <ContainerTermos>
            <div>
              <CheckBox
                type="checkbox"
                value={aceite_termos}
                id="aceite_termos"
                onChange={(event) => setAceiteTermos(event.target.checked)}
              />
              <Labels>Li e concordo com os termos e condições</Labels>
            </div>

            <Cadastrar onClick={handleData} type="button">
              CADASTRAR
            </Cadastrar>
          </ContainerTermos>
        </Formulario>
      </ContainerFormulario>
    </Principal>
  );
}

export default RestaurantRegisterBodyTeste;