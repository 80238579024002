import styled from "styled-components";

const StyleHeaderDefault = {
  ConteudoPrincipal: styled.header`
    width: 100%;
    height: ${(props) => props.height || "10vh"};
    background-color: ${(props) => props.backGroundColor || "#fff"};
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  Title: styled.h2`
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    margin: 0;
    text-align: center;
  `,
};

export default StyleHeaderDefault;