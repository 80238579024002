import axios from "axios";
import { useNavigate } from "react-router-dom";
import HomeAdminStyle from "../../assets/styles/Admin/AdminStyle";
import {
  faChartSimple,
  faComments,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";
import GraficoLinha from "./Charts/LineChart";
import GraficoColuna from "./Charts/ColumnChart";
import CardAdmin from "./Cards/CardAdmin";
import NavBarAdmin from "./Navbar/NavbarAdmin";
import { useEffect } from "react";
//import getCookie from '../../assets/scripts/Client/getCookie.mjs';

const {
  ConteudoPrincipal,
  ConteudoGeral,
  Titulo,
  Subtitulo,
  ContainerGraficos,
  ContainerCards,
} = HomeAdminStyle;

function AdminPrincipal() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get("https://www.api.qftech.com.br/api/access/isLoggedIn", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      })
      .then((response) => {})
      .catch((err) => {
        localStorage.removeItem("token");
        localStorage.removeItem("nome");
        localStorage.removeItem("funcao");
        localStorage.removeItem("imagem_perfil_buffer");
        localStorage.removeItem("imagem_perfil_mimeType");
        localStorage.removeItem("mesa");
        
        navigate("/loginadministrator");
      });
  }, [token, navigate]);

  return (
    <>
      <>
        <ConteudoPrincipal>
          <NavBarAdmin />

          <ConteudoGeral>
            <Titulo>Olá, tudo bem?!</Titulo>
            <Subtitulo>Confira as novidades de hoje!</Subtitulo>
            <ContainerCards>
              <CardAdmin
                titulo="Faturamento"
                valor="R$60.000,00"
                icone={faDollar}
              />
              <CardAdmin titulo="Vendas" valor="800" icone={faChartSimple} />
              <CardAdmin titulo="Pedidos" valor="7000" icone={faComments} />
            </ContainerCards>
            <ContainerGraficos>
              <GraficoLinha />
              <GraficoColuna />
            </ContainerGraficos>
          </ConteudoGeral>
        </ConteudoPrincipal>
      </>
    </>
  );
}

export default AdminPrincipal;
