import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleCartaoCadastrado = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    border-radius: 5px;
    border: 0.7px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 10px 10px 10px;
  `,

  NomeCartao: styled.h4`
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    font-weight: 400;
  `,

  BandeiraCartao: styled.img`
    max-width: 30px;
    max-height: 28px;
  `,

  ContainerBandeiraENumero: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 10% 40% 50%;
    align-items: center;
  `,

  NumeroCartao: styled.h6`
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    font-weight: 400;
  `,

  BtnEdit: styled.button`
    justify-self: end;
    border-radius: 50px;
    background-color: #4da768;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
  `,

  IconEdit: styled(FontAwesomeIcon)`
    color: #fff;
    font-size: 14px;
  `,
};

const {
  ConteudoPrincipal,
  NomeCartao,
  ContainerBandeiraENumero,
  BandeiraCartao,
  NumeroCartao,
  BtnEdit,
  IconEdit,
} = StyleCartaoCadastrado;

function CartaoCadastrado({ imgBandeira, funcaoEditarDados }) {
  return (
    <>
      <ConteudoPrincipal>
        <NomeCartao>John Doe</NomeCartao>
        <ContainerBandeiraENumero>
          <BandeiraCartao src={imgBandeira} alt="imgBandeira" />
          <NumeroCartao>•••• •••• •••• 9215</NumeroCartao>
          <BtnEdit onClick={funcaoEditarDados}>
            <IconEdit icon={faPen} />
          </BtnEdit>
        </ContainerBandeiraENumero>
      </ConteudoPrincipal>
    </>
  );
}

export default CartaoCadastrado;