import styled from "styled-components";

const StyleProduto = {
  ContainerProduto: styled.div`
    width: 90%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-bottom: 0.7px #cbcbcb solid;

    &:hover {
      cursor: pointer;
    }
  `,

  ContainerInfoProduto: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 10px;
  `,

  ImgProduto: styled.img`
    max-width: 60px;
    max-height: 60px;
  `,

  Nome: styled.h6`
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    font-size: 16px;
  `,

  Ingredientes: styled.h6`
    font-family: Poppins, "sans-serif";
    font-weight: 300;
    font-size: 16px;
  `,

  Preco: styled.h4`
    font-family: Poppins, "sans-serif";
    font-weight: 600;
    font-size: 16px;
  `,

  ContainerTamanhoProduto: styled.div`
    background-color: #73c883;
    border-radius: 10px;
    width: 25px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 15px;
    bottom: 5px;
  `,

  Tamanho: styled.h5`
    font-family: Poppins, "sans-serif";
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  `,
};

const {
  ContainerProduto,
  ContainerInfoProduto,
  ImgProduto,
  Nome,
  Ingredientes,
  Preco,
  Tamanho,
  ContainerTamanhoProduto,
} = StyleProduto;

function Produto({
  imagem,
  nome,
  ingredientes,
  preco,
  tamanho,
  clickFunction,
  key,
}) {
  return (
    <>
      <ContainerProduto onClick={clickFunction} key={key}>
        <ImgProduto src={imagem} alt="imgProduto" />
        <ContainerTamanhoProduto>
          <Tamanho>{tamanho}</Tamanho>
        </ContainerTamanhoProduto>
        <ContainerInfoProduto>
          <Nome>{nome}</Nome>
          <Ingredientes>{ingredientes}</Ingredientes>
          <Preco>{preco}</Preco>
        </ContainerInfoProduto>
      </ContainerProduto>
    </>
  );
}

export default Produto;