import styled from "styled-components";

const StyleSacola = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 100%;
    align-items: flex-start;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  `,

  Titulo: styled.h2`
    font-family: Poppins, "sans-serif";
    font-weight: 600;
    font-size: 16px;
  `,

  Subtitulo: styled.h4`
    color: #b4b4b4;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  `,

  LinkAdicionarMaisItens: styled.h4`
    align-self: flex-end;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    color: #1b2029;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  `,

  ContainerCupom: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  `,

  ContainerCodigoCupom: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
  `,

  InputCupom: styled.input`
    border-radius: 5px;
    border: 0.5px solid #cbcbcb;
    background: #f8f5e5a2;
    color: #929292;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    padding-left: 5px;
    width: 210px;
    padding: 5px;
    &:focus {
      outline: none;
      color: #1b2029;
    }
  `,

  BtnAddCupom: styled.button`
    border-radius: 5px;
    background: #1b2029;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-family: Poppins;
    border: none;
    font-size: 14px;
    padding: 5px 15px;
    transition: all 0.5s;
    &:hover {
      cursor: pointer;
      background-color: #7da768;
      transition: all 0.5s;
    }
  `,

  ContainerValorAPagar: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  `,

  DefaultContainerValores: styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    width: 100%;
  `,

  TextoSubtotalECupom: styled.h4`
    color: #b4b4b4;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
  `,

  Valor: styled.h4`
    justify-self: flex-end;
    color: #b4b4b4;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
  `,

  TextoTotal: styled.h4`
    color: #1b2029;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
  `,

  ValorTotal: styled.h4`
    justify-self: flex-end;
    color: #1b2029;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
  `,

  BtnGoPagamento: styled.button`
    border: none;
    border-radius: 5px;
    background: #73c883;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: Poppins, "sans-serif";
    padding: 3px 15px;
    transition: all 0.5s;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 200px;
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,
};

export default StyleSacola;