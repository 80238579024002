import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";

const StyleItensAMaisPedidos = {
  ContainerItemAMais: styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  `,

  NomeItemAMais: styled.h6`
    font-size: 12px;
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    margin: 0;
  `,

  PrecoItemAMais: styled.h6`
    font-size: 12px;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    color: #b4b4b4;
    margin: 0;
    margin-top: 3px;
  `,

  ContainerQtdeItensAMais: styled.div`
    border-radius: 5px;
    border: 0.5px solid #cbcbcb;
    background: #fff;
    box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.15);
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 5px 5px 5px 5px;
  `,

  IconMinusPlus: styled(FontAwesomeIcon)`
    color: #73c883;
    font-size: 10px;
    &:hover {
      cursor: pointer;
    }
  `,

  TextoQtdeAMais: styled.h6`
    font-size: 12px;
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    margin: 0;
  `,

  ContainerNomeEPreco: styled.div`
    margin-top: 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `,
};

const {
  NomeItemAMais,
  PrecoItemAMais,
  ContainerQtdeItensAMais,
  ContainerItemAMais,
  ContainerNomeEPreco,
  IconMinusPlus,
  TextoQtdeAMais,
} = StyleItensAMaisPedidos;

function ItensAMaisPedidos({ nome, preco }) {
  const [qtdeItensAMais, setqtdeItensAMais] = useState(0);

  return (
    <>
      <ContainerItemAMais>
        <ContainerNomeEPreco>
          <NomeItemAMais>{nome}</NomeItemAMais>
          <PrecoItemAMais>+ {preco}</PrecoItemAMais>
        </ContainerNomeEPreco>
        <ContainerQtdeItensAMais>
          <IconMinusPlus icon={faMinus} />
          <TextoQtdeAMais>{qtdeItensAMais}</TextoQtdeAMais>
          <IconMinusPlus icon={faPlus} />
        </ContainerQtdeItensAMais>
      </ContainerItemAMais>
    </>
  );
}

export default ItensAMaisPedidos;