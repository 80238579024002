import { useState } from "react";
import styled from "styled-components";
import RadioButton from "./RadioButton";

const StyleOpcionaisPedidos = {
  ConteudoPrincipal: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  `,

  ContainerItemOpcional: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,

  ContainerBtnsSimOuNao: styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
  `,

  NomeItemOpcional: styled.h6`
    width: 100%;
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    margin: 0;
  `,
};

const {
  ConteudoPrincipal,
  ContainerItemOpcional,
  ContainerBtnsSimOuNao,
  NomeItemOpcional,
} = StyleOpcionaisPedidos;

function ItensOpcionaisPedidos({ nome }) {
  const [opcaoSimOuNao, setOpcaoSimOuNao] = useState(false);

  return (
    <>
      <ConteudoPrincipal>
        <ContainerItemOpcional>
          <NomeItemOpcional>{nome}</NomeItemOpcional>
          <ContainerBtnsSimOuNao>
            <RadioButton />
          </ContainerBtnsSimOuNao>
        </ContainerItemOpcional>
      </ConteudoPrincipal>
    </>
  );
}

export default ItensOpcionaisPedidos;