import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const RodapeStyle = {
  Rodape: styled.footer`
    background-color: #f5f5f5;
    width: 100vw;
    height: 40vh;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 910px) {
      height: 55vh;
    }

    @media screen and (max-width: 646px) {
      height: 78vh;
    }
  `,

  ConteudoRodape: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 910px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 2em;
    }

    @media screen and (max-width: 646px) {
      grid-template-columns: 1fr;
    }
  `,

  CardsRodape: styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;

    img {
      @media screen and (max-width: 910px) {
        width: 80px;
      }
    }
  `,

  FraseDeEfeito: styled.h6`
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;

    @media screen and (max-width: 910px) {
      font-size: 12px;
    }

    @media screen and (max-width: 646px) {
      height: 10px;
    }
  `,

  TitulosRodape: styled.h6`
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 15px;

    @media screen and (max-width: 910px) {
      font-size: 13px;
    }

    @media screen and (max-width: 646px) {
      height: 11px;
    }
  `,

  SubtitulosRodape: styled.h6`
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      font-weight: bold;
      color: #26874e;
    }

    @media screen and (max-width: 910px) {
      font-size: 12px;
    }

    @media screen and (max-width: 646px) {
      height: 10px;
    }
  `,

  FooterLink: styled.a`
    text-decoration: none;
    color: inherit;
  `,

  RedesSociaisIcons: styled(FontAwesomeIcon)`
    color: #26874e;
    margin-right: 10px;
    font-size: 24px;
    transition: color 0.3s ease;

    &:hover {
      color: #73c883;
    }

    @media screen and (max-width: 910px) {
      font-size: 22px;
    }
  `,

  DireitosAutorais: styled.h6`
    font-family: "Poppins", sans-serif;
    margin-top: 50px;
    font-weight: 400;
    text-align: center;

    @media screen and (max-width: 910px) {
      font-size: 14px;
    }

    @media screen and (max-width: 475px) {
      font-size: 12px;
    }
  `,

  GreenText: styled.span`
    color: #26874e;
  `,
};

export default RodapeStyle;