import styled from "styled-components";

const ItemCode = {
  ContainerLista: styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  `,

  Lista: styled.div`
    background-color: #99e89d71;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    @media screen and (max-width: 1023px) {
      width: 30px;
      height: 30px;
    }

    @media screen and (max-width: 735px) {
      width: 25px;
      height: 25px;
    }
  `,

  ImgCheck: styled.img`
    height: 20px;
    width: 20px;
  `,

  TextoDiv: styled.div`
    display: flex;
    flex-direction: column;
  `,

  TextoDefault: styled.h4`
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500;

    @media screen and (max-width: 1023px) {
      font-size: 16px;
    }

    @media screen and (max-width: 1000px) {
      font-size: 15px;
    }

    @media screen and (max-width: 822px) {
      font-size: 14px;
    }
  `,
};

function Item({ imagem, texto1, texto2 }) {
  return (
    <ItemCode.ContainerLista>
      <ItemCode.Lista>
        <ItemCode.ImgCheck src={imagem} alt="check" />
      </ItemCode.Lista>
      <ItemCode.TextoDiv>
        <ItemCode.TextoDefault>{texto1}</ItemCode.TextoDefault>
        <ItemCode.TextoDefault>{texto2}</ItemCode.TextoDefault>
      </ItemCode.TextoDiv>
    </ItemCode.ContainerLista>
  );
}

export default Item;