import HeaderDefault from "../Header/HeaderDefault";
import StyleSucessCard from "../../../assets/styles/Pedidos/Sucess/StyleSucessCard";
import ImgSucessCard from "../../../assets/img/pedido_entregue.png";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const {
  ConteudoPrincipal,
  ImageSucessCard,
  Title,
  TextoTempoMedioDeEspera,
  BtnNovoPedido,
  IconArrow,
} = StyleSucessCard;

function SucessCard() {
  const [tempoMedioEspera, setTempoMedioEspera] = useState();

  const navigate = useNavigate();

  const backToHome = () => {
    navigate("/cliente/cardapio");
  };

  return (
    <>
      <HeaderDefault
        backGroundColor={"#4FAE6C"}
        height={"10vh"}
        nomeRestaurante={"QUICKFOOD"}
      />
      <ConteudoPrincipal>
        <ImageSucessCard src={ImgSucessCard} alt="imgSucessCard" />
        <Title>Seu pedido foi entregue com sucesso na cozinha!</Title>
        <TextoTempoMedioDeEspera>
          Tempo médio de espera: 20 minutos
        </TextoTempoMedioDeEspera>
        <BtnNovoPedido onClick={backToHome}>
          Novo pedido <IconArrow icon={faArrowRight} />
        </BtnNovoPedido>
      </ConteudoPrincipal>
    </>
  );
}

export default SucessCard;