import HeaderDefault from "../Header/HeaderDefault";
import StyleClienteCardapio from "../../../assets/styles/Pedidos/ClienteCardapio/StyleClienteCardapio";
import {
  faLocationDot,
  faBellConcierge,
  faUtensils,
  faBagShopping,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Produto from "../../Props/Produto";
import { useEffect } from "react";
import axios from "axios";
import Sacola from "../../Props/Sacola";
import { useNavigate } from "react-router-dom";
import CustomModalOnlyMessage from "../../Common/Modal_Only_Message.jsx";
import ReactLoading from "react-loading";
import StyledGeneral from "../../../assets/styles/General/GeneralStyle";

const { DivLoading } = StyledGeneral;

const {
  ConteudoPrincipal,
  CabecalhoRest,
  LogoRest,
  ContainerInfoRest,
  Icons,
  DefaultContainer,
  DefaultTitle,
  BtnDefault,
  ContainerProdutos,
  TituloCategoria,
  BtnChamarGarcom,
  IconSino,
  Container,
  TextoEndereco,
  ContainerEndereco,
  ContainerChamarGarcom,
  SubContainerInfoRest,
  ContainerMesa,
  InputMesa,
} = StyleClienteCardapio;

function ClienteCardapio() {
  const [loading, setLoading] = useState(false);
  const [endereco, setEndereco] = useState("");
  const [categoriasDoEstabelecimento, setcategoriasDoEstabelecimento] =
    useState([]);
  const [tipoRestaurante, setTipoRestaurante] = useState("");
  const [valorMinimo, setValorMinimo] = useState("");
  const [tamanho, setTamanho] = useState(["P", "M", "G"]);
  const [nome_restaurante, setNomeRestaurante] = useState("");
  const [produtos_cardapio, setProdutosCardapio] = useState("");
  const [imagens_produto, setImagensProdutos] = useState([]);
  const [cor_primaria_restaurante, setCorPrimariaRestaurante] = useState("");
  const [cor_secundaria_restaurante, setCorSecundariaRestaurante] =
    useState("");
  const [imagemLogo, setImagemLogo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);
  const [showBootBox, setShowBootBox] = useState(false);
  const tokenRest = localStorage.getItem("token");
  const id_rest = localStorage.getItem("id_restaurante");
  const nome_rest = localStorage.getItem("nome_restaurante");
  const cor_primaria_rest = localStorage.getItem("cor_primaria_restaurante");
  const cor_secundaria_rest = localStorage.getItem(
    "cor_secundaria_restaurante"
  );
  const imagem_logo_rest = localStorage.getItem("imagem_logo_restaurante");
  const mesa_cliente = localStorage.getItem("mesa");
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    axios
      .get(`https://www.api.qftech.com.br/api/list/listmenu/${tokenRest}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (id_rest === null) {
          localStorage.setItem(
            "id_restaurante",
            `${response.data.informacoesRestaurante.id_restaurante}`
          );
        }
        if (nome_rest === null) {
          localStorage.setItem(
            "nome_restaurante",
            `${response.data.informacoesRestaurante.nome_restaurante.toUpperCase()}`
          );
        }
        if (cor_primaria_rest === null) {
          localStorage.setItem(
            "cor_primaria_restaurante",
            `${response.data.informacoesRestaurante.cor_primaria_restaurante}`
          );
        }
        if (cor_secundaria_rest === null) {
          localStorage.setItem(
            "cor_secundaria_restaurante",
            `${response.data.informacoesRestaurante.cor_secundaria_restaurante}`
          );
        }

        setNomeRestaurante(
          response.data.informacoesRestaurante.nome_restaurante.toUpperCase()
        );
        setEndereco(response.data.informacoesRestaurante.endereco_restaurante);
        setTipoRestaurante(
          response.data.informacoesRestaurante.tipo_restaurante
        );
        setValorMinimo(response.data.informacoesRestaurante.pedido_minimo);
        setCorPrimariaRestaurante(
          response.data.informacoesRestaurante.cor_primaria_restaurante
        );
        setCorSecundariaRestaurante(
          response.data.informacoesRestaurante.cor_secundaria_restaurante
        );
        setProdutosCardapio(response.data.produtosCardapio);
        setcategoriasDoEstabelecimento(response.data.categoriasCardapio);

        if (
          response.data.informacoesRestaurante.buffer !== "" &&
          response.data.informacoesRestaurante.mimeType !== ""
        ) {
          const uint8Array = new Uint8Array(
            response.data.informacoesRestaurante.buffer.data
          );
          const blob = new Blob([uint8Array], {
            type: response.data.informacoesRestaurante.mimeType,
          });
          const imageUrl = URL.createObjectURL(blob);

          if (imagem_logo_rest === null) {
            localStorage.setItem("imagem_logo_restaurante", `${imageUrl}`);
          }

          setImagemLogo(imageUrl);
        }

        response.data.produtosCardapio.map((image) => {
          const uint8Array = new Uint8Array(image.buffer.data);
          const blob = new Blob([uint8Array], { type: image.mimeType });
          const imageUrl = URL.createObjectURL(blob);

          setImagensProdutos((imagens) => [...imagens, imageUrl]);
        });
      })
      .catch((err) => {
        showErrorSearch();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [precoTotal, setPrecoTotal] = useState(0);
  const AdicionarNaSacola = (precoProduto) => {
    setPrecoTotal((precoTotal) => precoTotal + precoProduto);
  };

  const changePage = () => {
    navigate("/cliente/sacola");
  };

  const showErrorSearch = () => {
    setShowErrorSearchModal(true);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  const filtrarByCategoria = (categoria) => {
    setLoading(true);

    let findCategoria = {
      filtro: categoria,
      token: tokenRest,
    };

    axios
      .post(
        "https://www.api.qftech.com.br/api/filter/filterproductmenubycategory",
        JSON.stringify(findCategoria),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setImagensProdutos("");
        setProdutosCardapio(response.data);

        response.data.map((image) => {
          const uint8Array = new Uint8Array(image.buffer.data);
          const blob = new Blob([uint8Array], { type: image.mimeType });
          const imageUrl = URL.createObjectURL(blob);

          setImagensProdutos((imagens) => [...imagens, imageUrl]);
        });
      })
      .catch((err) => {
        showErrorSearch();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <DivLoading>
          <ReactLoading
            type="bars"
            color="#fff"
            width="100px"
            height="100px"
            className="loading"
          />
        </DivLoading>
      )}

      <HeaderDefault
        nomeRestaurante={nome_restaurante}
        backGroundColor={cor_primaria_restaurante}
        height={"6vh"}
        colorText={cor_secundaria_restaurante}
      />
      <ConteudoPrincipal>
        <CabecalhoRest
          style={{ backgroundColor: `${cor_secundaria_restaurante}` }}
        >
          <LogoRest src={imagemLogo} alt="logo" />
        </CabecalhoRest>
        <Container>
          <ContainerInfoRest>
            <SubContainerInfoRest>
              <Icons icon={faUtensils} />
              <DefaultTitle>{tipoRestaurante}</DefaultTitle>
            </SubContainerInfoRest>
            <SubContainerInfoRest>
              <Icons icon={faBagShopping} />
              <DefaultTitle>Pedido mÍnimo: {`R$${valorMinimo}`}</DefaultTitle>
            </SubContainerInfoRest>
            <ContainerEndereco>
              <Icons icon={faLocationDot} />
              <TextoEndereco>{endereco}</TextoEndereco>
            </ContainerEndereco>
          </ContainerInfoRest>
          <ContainerChamarGarcom>
            <BtnChamarGarcom>
              <IconSino icon={faBellConcierge} /> Chamar garçom
            </BtnChamarGarcom>
          </ContainerChamarGarcom>
        </Container>
        <DefaultContainer>
          <BtnDefault onClick={() => filtrarByCategoria("")}>Todos</BtnDefault>
          {categoriasDoEstabelecimento.map((item, index) => (
            <BtnDefault
              key={index}
              onClick={() => filtrarByCategoria(item.categoria)}
            >
              {item.categoria}
            </BtnDefault>
          ))}
        </DefaultContainer>
        <ContainerMesa>
          <InputMesa type="text" value={`Mesa ${mesa_cliente}`} disabled />
        </ContainerMesa>
        <ContainerProdutos>
          {produtos_cardapio &&
            produtos_cardapio.map((item, index) => (
              <Produto
                imagem={imagens_produto[index]}
                nome={item.nome_produto}
                ingredientes={item.ingredientes}
                preco={`R$${item.preco.$numberDecimal}`}
                tamanho={tamanho[index]}
                clickFunction={() =>
                  navigate(
                    `/cliente/detalhes_produto?id_produto=${item.id}&id_restaurante=${id_rest}`
                  )
                }
                key={index}
              />
            ))}
        </ContainerProdutos>

        <Sacola
          //preco={`R$${precoTotal}`}
          preco={"R$0,00"}
          changePage={changePage}
        />
      </ConteudoPrincipal>

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />
    </>
  );
}

export default ClienteCardapio;