import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleBtnAddCartao = {
  ConteudoPrincipal: styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: auto;
  `,

  ContainerBtnTexto: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  `,

  BtnAdd: styled.button`
    border-radius: 50px;
    background-color: #1b2029;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
  `,

  IconAdd: styled(FontAwesomeIcon)`
    color: #fff;
    font-size: 10px;
  `,

  Texto: styled.h6`
    font-family: Poppins, "sans-serif";
    font-size: 14px;
    margin-left: 17px !important;
  `,
};

const { ConteudoPrincipal, BtnAdd, IconAdd, Texto, ContainerBtnTexto } =
  StyleBtnAddCartao;

function BtnAddCartao({ evento }) {
  return (
    <>
      <ConteudoPrincipal>
        <ContainerBtnTexto>
          <BtnAdd onClick={evento}>
            <IconAdd icon={faPlus} />
          </BtnAdd>
          <Texto>Adicionar cartão</Texto>
        </ContainerBtnTexto>
      </ConteudoPrincipal>
    </>
  );
}

export default BtnAddCartao;