import StyleCookies from "../../../assets/styles/Home/Cookies/StyleCookies";
import ImgCookie from "../../../assets/img/cookie.png";
import { faX } from "@fortawesome/free-solid-svg-icons";

const {
  ConteudoPrincipal,
  ImageIconCookie,
  Texto,
  ContainerTextoIcons,
  Descricao,
  BtnAceitarCookies,
  IconClose,
} = StyleCookies;

function Cookies({ funcao }) {
  return (
    <>
      <ConteudoPrincipal>
        <ContainerTextoIcons>
          <Texto>Primeiro, vamos falar de cookies</Texto>
          <ImageIconCookie src={ImgCookie} alt="img-cookie" />
          <IconClose icon={faX} onClick={funcao} />
        </ContainerTextoIcons>
        <Descricao>
          Para melhorar a sua experiência de navegação. Ao continuar a utilizar
          este site, você concorda com o uso de cookies <br />
          de acordo com a nossa Política de Privacidade.
        </Descricao>
        <BtnAceitarCookies onClick={funcao}>Aceitar Cookies</BtnAceitarCookies>
      </ConteudoPrincipal>
    </>
  );
}

export default Cookies;