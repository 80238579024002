import styled from "styled-components";
import "../../fonts/Raleway";
import "../../fonts/Inter";
import "../../fonts/Poppins";

const WaiterRegisterStyle = {
  Principal: styled.div`
    margin: 0;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: hidden;
  `,

  ContainerImagens: styled.div``,

  ImgFundo: styled.img`
    position: absolute;
    z-index: -2;
    max-height: 100vh;
    width: 100vw;
  `,

  ImgLogo: styled.img`
    position: absolute;
    z-index: -1;
    max-width: 120px;
    max-height: 120px;
    margin-left: 2vw;
    margin-top: 6vh;
  `,

  ImgCadastro: styled.img`
    position: absolute;
    z-index: -1;
    max-width: 500px;
    margin-left: 5vw;
    margin-top: 10vh;
  `,

  ContainerFormulario: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    width: 68vw;
    height: auto;
    margin-top: 5vh;
    margin-left: 29vw;
    margin-bottom: 3%;
    padding: 10%, 10%, 10%, 10%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  `,

  ContainerCampos: styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  `,

  ContainerTermos: styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    align-content: center;
    margin-top: 50px;
  `,

  Titulo: styled.h1`
    color: #26874e;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    margin-top: 30px;
    align-self: center;
  `,

  Subtitulo: styled.h2`
    width: 100%;
    color: #26874e;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 27px;
    align-self: center;
    text-align: start;
    margin-left: -7%;
    margin-top: 35px;
    margin-bottom: 10px;
  `,

  Formulario: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 1vw;
    margin-bottom: 35px;
  `,

  Labels: styled.label`
    font-family: "Poppins", sans-serif;
    margin-right: 10px;
  `,

  TxtTermos: styled.a`
    font-family: "Poppins", sans-serif;
    margin-right: 10px;
    &:hover {
      color: #1b2029;
      transition: all 0.5s;
    }
  `,

  DivInputIcon: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  `,

  Inputs: styled.input`
    width: inherit;
    height: 30px;
    border-radius: 5px;
    border: solid 1px #26874e;
    margin-bottom: 20px;
    margin-top: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 14px;
    margin-right: 10px;
    padding-left: 10px;
    font-family: "Poppins", sans-serif;

    &:focus {
      border: solid 1px #26874e;
      font-weight: 600;
      outline: none;
    }
  `,

  Selecao: styled.select`
    height: 30px;
    width: inherit;
    margin-right: 20px;
    margin-top: 10px;
    font-family: "Poppins", sans-serif;
    padding: 1px 10px;
    border-radius: 5px;
    border: solid 1px #26874e;
    margin-bottom: 10px;
    font-size: 14px;

    &:focus {
      border: solid 1px #26874e;
      outline: none;
    }
  `,

  Opcao: styled.option`
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  `,

  CheckBox: styled.input`
    margin-right: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 15px;
    height: 15px;
  `,

  Cadastrar: styled.button`
    border: solid 1px #26874e;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #4da768;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: "Poppins", sans-serif;
    transition: all 0.5s;
    align-self: center;

    &:hover {
      background-color: #99e89d;
      color: #1b2029;
      transition: all 0.5s;
    }
  `,

  DivItemLeft: styled.div`
    width: 100%;
    margin-right: 30px;
  `,

  DivItemRight: styled.div`
    width: 100%;
    margin-left: 4vw;
  `,

  DivItem: styled.div`
    width: 100%;
  `,
};

export default WaiterRegisterStyle;