import HomeStyle from "../../assets/styles/Home/HomeStyle.js";
import ImgComputador from "../../assets/img/designHeader.png";
import HeaderStyle from "../../assets/styles/Header/HeaderStyle.js";
import Card from "../Props/Cards.js";
import Item from "../Props/Items.js";
import Categoria from "../Props/Categorias.js";
import Trofeu from "../../assets/img/trofeu.png";
import Dinheiro from "../../assets/img/money.png";
import Tempo from "../../assets/img/tempo.png";
import EmpresaPng from "../../assets/img/empresa.png";
import Check from "../../assets/img/select.png";
import Loja from "../../assets/img/loja.png";
import Vantagem from "../../assets/img/vantagens.png";
import Happy from "../../assets/img/happy.png";
import Star from "../../assets/img/star.png";
import ImgPizza from "../../assets/img/pizza.png";
import Together from "../../assets/img/juntos.png";
import MercadoPago from "../../assets/img/mercado-pago-logo.png";
import SendGrid from "../../assets/img/SendGrid-Logo.png";
import RegistroBr from "../../assets/img/registrobr.png";
import Aws from "../../assets/img/aws.png";
import Img_Contato from "../../assets/img/contato.png";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { AirPlaneIcon } from "../../assets/styles/Home/HomeStyle.js";
import ToastComponent from "../Toast/Toast.js";
import CardEmpresa from "../Props/CardEmpresa.js";
import ImgAlvo from "../../assets/img/alvo.png";
import ImgStar from "../../assets/img/visao.png";
import ImgDiamante from "../../assets/img/valor.png";
import CardLista from "../Props/CardLista.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputMask } from "@react-input/mask";
import axios from "axios";
import Cookies from "./Cookies/Cookies.jsx";
import StyledGeneral from "../../assets/styles/General/GeneralStyle";
import ReactLoading from "react-loading";
import CustomModalOnlyMessage from "../Common/Modal_Only_Message.jsx";

const { DivLoading } = StyledGeneral;

const {
  Principal,
  Slogan,
  ContainerAds,
  FraseEfeito,
  TituloSlogan1,
  TituloSlogan2,
  TituloSlogan3,
  BtnDefault,
  TituloDefault,
  ContainerAllCards,
  Apresentacao,
  ImgEmpresa,
  TextoDefault,
  Servicos,
  ContainerAllItems,
  ContainerOneItem,
  Vantagens,
  ImgVantagens,
  AllEmpreendedores,
  DefaultSubtitulo,
  CategoriasEmpresa,
  ListaCategorias,
  Propostas,
  Pizza,
  TextoProposta,
  FormProposta,
  Email,
  BtnEnviarEmail,
  Parceiros,
  SaibaMais,
  ContainerParceria,
  ContainerImgParceiros,
  ContainerTextoParceria,
  ImgTogether,
  ImgPayPal,
  ImgSendGrid,
  ImgRegistroBr,
  ImgAws,
  Contato,
  ContainerImagemFormularioContato,
  ImgContato,
  FormContato,
  TituloInputs,
  InputsContato,
  BtnEnviarMensagem,
  Mensagem,
  Camada,
  Computer,
  ImageLoja,
  ContainerImagensParceiros,
} = HomeStyle;

const { GreenText } = HeaderStyle;

function HomeBody() {
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(true);
  const [nome, setNome] = useState("");
  const [email_proposta, setEmailProposta] = useState("");
  const [email_contato, setEmailContato] = useState("");
  const [celular, setCelular] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  //const [emailPromo, setEmailPromo] = useState()

  useEffect(() => {
    const cookieLocalStorage = localStorage.getItem("cookie");
    if (cookieLocalStorage === "true") {
      setDisplay(false);
    }

    const targetSection = localStorage.getItem("targetSection");
    if (targetSection) {
      const targetSectionId = document.getElementById(targetSection);
      targetSectionId.scrollIntoView({ behavior: "smooth" });

      localStorage.removeItem("targetSection");
    }
    // else {
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    // }
  }, []);

  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  const showErrorSearch = () => {
    setShowErrorSearchModal(true);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  const dataProposta = {
    email_proposta,
  };

  const handleClickEnviarProposta = () => {
    setLoading(true);

    axios
      .post("https://www.api.qftech.com.br/api/send/proposal", dataProposta)
      .then((response) => {
        showErrorSearch();
        setErrorMessage(response.data);

        limpaCampos();
      })
      .catch((err) => {
        showErrorSearch();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const dataContato = {
    email_contato,
    mensagem,
    nome,
    celular,
  };

  const handleClickEnviarContato = () => {
    setLoading(true);

    axios
      .post("https://www.api.qftech.com.br/api/send/contactus", dataContato)
      .then((response) => {
        showErrorSearch();
        setErrorMessage(response.data);

        limpaCampos();
      })
      .catch((err) => {
        showErrorSearch();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickCloseCookie = () => {
    localStorage.setItem("cookie", true);
    setDisplay(!display);
  };

  const limpaCampos = () => {
    setNome("");
    setEmailProposta("");
    setEmailContato("");
    setCelular("");
    setMensagem("");
  };

  return (
    <>
      {loading && (
        <DivLoading>
          <ReactLoading
            type="bars"
            color="#fff"
            width="100px"
            height="100px"
            className="loading"
          />
        </DivLoading>
      )}

      {display && <Cookies funcao={handleClickCloseCookie} />}
      <Principal id="home">
        <Slogan>
          <ContainerAds>
            <FraseEfeito>Seu comércio em alta em poucos dias!</FraseEfeito>
            <FraseEfeito>Seu pedido. Nossa demanda</FraseEfeito>
            <TituloSlogan1>OTIMIZE SEU TEMPO E </TituloSlogan1>
            <TituloSlogan2>AUMENTE SEUS LUCROS </TituloSlogan2>
            <TituloSlogan3>A PARTIR DE HOJE!</TituloSlogan3>
            <div>
              <BtnDefault onClick={() => handleNavigation("/planos")}>
                Conheça os planos!
              </BtnDefault>
            </div>
          </ContainerAds>
          <Computer src={ImgComputador} alt="Apresentação do nosso sistema" />
        </Slogan>

        <TituloDefault>
          Sua <GreenText>solução</GreenText> em pedidos digitais está aqui!
        </TituloDefault>

        <ContainerAllCards>
          <Card
            imagem={Trofeu}
            titulo="Precisão"
            descricao1="Está recebendo reclamações de"
            descricao2="pedidos incorretos ou faltando"
            descricao3="algo? Aqui está sua solução!"
            textoBtn="Confira agora!"
            linkBtn="planos"
          />

          <Card
            imagem={Dinheiro}
            titulo="Rentabilidade"
            descricao1="Suas despesas têm sido muito"
            descricao2="altas? Resolvemos seu"
            descricao3="problema!"
            textoBtn="Confira agora!"
            linkBtn="planos"
          />

          <Card
            imagem={Tempo}
            titulo="Otimização de tempo"
            descricao1="Sua cozinha não tem dado"
            descricao2="conta de muitos pedidos? Você"
            descricao3="veio ao lugar certo!"
            textoBtn="Confira agora!"
            linkBtn="planos"
          />
        </ContainerAllCards>

        <Apresentacao id="sobre">
          <ImgEmpresa src={EmpresaPng} alt="empresa" />
          <TextoDefault>
            Nós da <GreenText>QuickFood</GreenText> temos o <br />
            compromisso em fazer com que <br />
            seu restaurante decole! Nossa <br />
            missão é oferecer ao seu <br />
            negócio soluções que <br /> aprimorem seus serviços e <br />{" "}
            otimizem seu tempo!
          </TextoDefault>
        </Apresentacao>

        <ContainerAllCards id="missaovisaovalores">
          <CardEmpresa
            imagem={ImgAlvo}
            titulo="Missão"
            descricao="Existimos, pois percebemos a dor de mercado em restaurantes quando os pedidos são anotados no papel e sua grande ineficiêcia."
          />
          <CardEmpresa
            imagem={ImgStar}
            titulo="Visão"
            descricao="Sermos reconhecidos pela gratidão dos donos de restaurante no uso do nosso sistema e a promoção de um ambiente mais organizado e lucrativo para eles."
          />
          <CardLista
            imagem={ImgDiamante}
            titulo="Valores"
            descricao1="Precisão"
            descricao2="Cooperativismo"
            descricao3="Excelência de serviço"
            descricao4="Empreendedorismo"
            descricao5="Parceria"
            descricao6="Proatividade"
          />
        </ContainerAllCards>

        <Servicos>
          <TituloDefault>
            Alguns de nossos <GreenText>serviços:</GreenText>
          </TituloDefault>
          <ContainerAllItems>
            <ContainerOneItem>
              <Item
                imagem={Check}
                texto1="Pedidos do cliente feitos por meio de nosso"
                texto2="software personalizável."
              />
              <Item
                imagem={Check}
                texto1="Tela de administração, no qual é possível analisar o"
                texto2="rendimento do estabelecimento e de seus funcionários"
              />
              <Item
                imagem={Check}
                texto1="Pedidos do cliente feitos por meio de nosso"
                texto2="software personalizável."
              />
            </ContainerOneItem>
            <ImageLoja src={Loja} alt="imgLoja" />
          </ContainerAllItems>
        </Servicos>

        <Vantagens>
          <TituloDefault>
            <GreenText>Vantagens</GreenText> de nos ter em seu negócio:
          </TituloDefault>
          <ContainerAllItems>
            <ImgVantagens src={Vantagem} alt="imgLoja" />
            <ContainerOneItem>
              <Item
                imagem={Happy}
                texto1="Os pedidos dos clientes serão feitos com"
                texto2="mais precisão e rapidez."
              />
              <Item
                imagem={Happy}
                texto1="Melhor gerenciamento e administração do "
                texto2="seu restaurante"
              />
              <Item
                imagem={Happy}
                texto1="Economia de tempo nos"
                texto2="serviços."
              />
            </ContainerOneItem>
          </ContainerAllItems>
        </Vantagens>

        <AllEmpreendedores>
          <TituloDefault>
            Para<GreenText> toda </GreenText>jornada{" "}
            <GreenText>empreendedora</GreenText>
          </TituloDefault>
          <DefaultSubtitulo>
            A QuickFood é ideial para qualquer que seja seu ramo!
          </DefaultSubtitulo>
          <CategoriasEmpresa>
            <ListaCategorias>
              <Categoria imagem={Star} titulo="Doces" />
              <Categoria imagem={Star} titulo="Saudável" />
              <Categoria imagem={Star} titulo="Frutas" />
            </ListaCategorias>
            <ListaCategorias>
              <Categoria imagem={Star} titulo="Sorvetes" />
              <Categoria imagem={Star} titulo="Lanches" />
              <Categoria imagem={Star} titulo="Salgados" />
            </ListaCategorias>
            <ListaCategorias>
              <Categoria imagem={Star} titulo="Açaí" />
              <Categoria imagem={Star} titulo="Marmitas" />
              <Categoria imagem={Star} titulo="Cafeteria" />
            </ListaCategorias>
            <ListaCategorias>
              <Categoria imagem={Star} titulo="Oriental" />
              <Categoria imagem={Star} titulo="Massas" />
              <Categoria imagem={Star} titulo="Bebidas" />
            </ListaCategorias>
          </CategoriasEmpresa>
        </AllEmpreendedores>

        <Propostas>
          <Pizza src={ImgPizza} alt="pizza" />
          <TextoProposta>
            Digite aqui seu email e <br /> receba nossas propostas!
          </TextoProposta>
          <FormProposta>
            <Email
              type="email"
              placeholder="email@example.com"
              value={email_proposta}
              onChange={(texto) => setEmailProposta(texto.target.value)}
            />
            <BtnEnviarEmail
              type="button"
              onClick={() => handleClickEnviarProposta()}
            >
              ENVIAR
            </BtnEnviarEmail>
          </FormProposta>
        </Propostas>

        <Parceiros>
          <SaibaMais>
            <TituloDefault>
              Conheça nossos <GreenText>parceiros!</GreenText>
            </TituloDefault>
            <DefaultSubtitulo>
              Nós nos preocupamos muito com seu negócio!
            </DefaultSubtitulo>
          </SaibaMais>
          <ContainerParceria>
            <ImgTogether src={Together} alt="juntos" />
            <ContainerTextoParceria>
              <DefaultSubtitulo>
                Para que seu estabelecimento sempre funcione <br /> da
                <GreenText> melhor forma possível</GreenText> e que sempre
                atenda <br />
                <GreenText>as expectativas de seus clientes.</GreenText> <br />
              </DefaultSubtitulo>
              <DefaultSubtitulo id="space">
                Conheça nossos parceiros e sistemas <br /> utilizados,
                <GreenText> para tornar tudo isso possível!</GreenText> <br />
              </DefaultSubtitulo>
            </ContainerTextoParceria>
          </ContainerParceria>
          <ContainerImgParceiros>
            <ContainerImagensParceiros>
              <ImgPayPal src={MercadoPago} alt="paypal" />
            </ContainerImagensParceiros>
            <ContainerImagensParceiros>
              <ImgSendGrid src={SendGrid} alt="send-grid" />
            </ContainerImagensParceiros>
            <ContainerImagensParceiros>
              <ImgRegistroBr src={RegistroBr} alt="registro-br" />
            </ContainerImagensParceiros>
            <ContainerImagensParceiros>
              <ImgAws src={Aws} alt="aws" />
            </ContainerImagensParceiros>
          </ContainerImgParceiros>
        </Parceiros>

        <Contato id="contato">
          <TituloDefault>
            Entre em contato<GreenText> conosco!</GreenText>
          </TituloDefault>
          <ContainerImagemFormularioContato>
            <ImgContato src={Img_Contato} alt="contato" />
            <FormContato>
              <TituloInputs>Nome:</TituloInputs>
              <InputsContato
                type="text"
                id="nome"
                placeholder="John Doe"
                value={nome}
                onChange={(texto) => setNome(texto.target.value)}
              />
              <TituloInputs>Email:</TituloInputs>
              <InputsContato
                type="email"
                id="email"
                placeholder="johndoe@email.com"
                value={email_contato}
                onChange={(texto) => setEmailContato(texto.target.value)}
              />
              <TituloInputs>Celular:</TituloInputs>
              <InputMask
                mask="(__) _ ____-____"
                replacement={{ _: /\d/ }}
                component={InputsContato}
                type="text"
                value={celular}
                onChange={(event) => setCelular(event.target.value)}
                placeholder="(XX) 9 XXXX-XXXX"
              />
              <TituloInputs>Mensagem: </TituloInputs>
              <Mensagem
                id="mensagem"
                cols={30}
                rows={10}
                value={mensagem}
                onChange={(texto) => setMensagem(texto.target.value)}
              />
              <BtnEnviarMensagem
                onClick={() => handleClickEnviarContato()}
                type="button"
              >
                ENVIAR
                <AirPlaneIcon icon={faPaperPlane} />
              </BtnEnviarMensagem>
            </FormContato>
          </ContainerImagemFormularioContato>
        </Contato>
      </Principal>

      <Camada style={{ zIndex: "999" }}>
        <ToastComponent />
      </Camada>

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />
    </>
  );
}

export default HomeBody;
