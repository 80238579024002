import React, { useState, useEffect, useRef } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import StyledGeneral from "../../../../assets/styles/General/GeneralStyle";
import StyledRestauranteAdmin from "../../../../assets/styles/Admin/Contas/Restaurante/StyledRestauranteAdmin";
import HeaderAdmin from "../../Header/Header_Admin.jsx";
import NavBarAdmin from "../../Navbar/NavbarAdmin.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { InputMask } from "@react-input/mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faFloppyDisk,
  faEye,
  faEyeSlash,
  faImage,
  faFile,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import formatarDataISO from "../../../../assets/scripts/Formater/DateFormaterISO.js";
import buscarCep from "../../../../assets/scripts/Request/buscaCep.mjs";
import CustomModalConfirmCancel from "../../../Common/Modal_Confirm_Cancel.jsx";
import CustomModalOnlyMessage from "../../../Common/Modal_Only_Message.jsx";
import CustomModalInvalidLogin from "../../../Common/Modal_Invalid_Login.jsx";
import ReactLoading from "react-loading";

const { DivLoading, StyledPagination } = StyledGeneral;

const {
  ConteudoPrincipal,
  TableContainer,
  Titulo,
  ContainerOutNav,
  GreenText,
  InputPesquisa,
  Search,
  ColumnTitle,
  Subtitulo,
  TableTBody,
  TituloEdit,
  UploadImageContainer,
  UploadImageConteudo,
  BigIcon,
  ImageSelected,
  DescricaoImage,
  SmallIcon,
  SubtituloImagem,
  UploadInput,
  DivCores,
  Add,
  ColImageContainer,
  CheckBox,
} = StyledRestauranteAdmin;

function RestauranteAdmin() {
  const [usuarios, setUsuarios] = useState([]);
  const navigate = useNavigate();
  const access_token = localStorage.getItem("token");
  const inputFileRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const closeButtonRegisterRef = useRef(null);
  const closeButtonUpdateRef = useRef(null);
  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [token, setToken] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [inscricao_estadual, setInscricaoEstadual] = useState("");
  const [nome_proprietario, setNomeProprietario] = useState("");
  const [tipo, setTipo] = useState("");
  const [status, setStatus] = useState("");
  const [data_abertura, setDataAbertura] = useState("");
  const [email_institucional, setEmailInstitucional] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [numero_endereco, setNumeroEndereco] = useState("");
  const [complemento, setComplemento] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmar_senha, setConfirmarSenha] = useState("");
  const [aceite_termos, setAceiteTermos] = useState("");
  const [valorFiltro, setValorFiltro] = useState({});
  const [exibirSenha, setType] = useState("password");
  const [btnEye, setEye] = useState(faEyeSlash);
  const [errorMessage, setErrorMessage] = useState("");
  const [imagem_perfil, setImagemPerfil] = useState("");
  const [imagem_perfil_selecionada, setImagemPerfilSelecionada] = useState("");
  const [nome_arquivo, setNomeArquivo] = useState(
    "Nenhum arquivo foi selecionado"
  );
  const [id_imagem_logo, setIdImagemLogo] = useState("");
  const [cor_primaria, setCorPrimaria] = useState("");
  const [cor_secundaria, setCorSecundaria] = useState("");
  const [user_images, setUserImages] = useState({});
  const [showConfirmationRegisterModal, setShowConfirmationRegisterModal] =
    useState(false);
  const [showSuccessRegisterModal, setShowSuccessRegisterModal] =
    useState(false);
  const [showConfirmationUpdateModal, setShowConfirmationUpdateModal] =
    useState(false);
  const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false);
  const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [showErrorDeleteModal, setShowErrorDeleteModal] = useState(false);
  const [showInvalidLoginModal, setShowInvalidLoginModal] = useState(false);
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 5;
  const offset = currentPage * dataPerPage;

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const usersToDisplay =
    usuarios.length > 0
      ? usuarios.slice(offset, offset + dataPerPage).map((item, index) => (
          <tr key={item.id}>
            <td>{index}</td>
            <td>{item.nome}</td>
            <td>{item.email_institucional}</td>
            <td>{item.cnpj}</td>
            <td>{item.token}</td>
            <td>
              <i
                className="fa fa-pen"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => handlePenClick(item.id)}
              ></i>
            </td>
            <td>
              <i
                className="fa fa-trash"
                onClick={() => handleTrashClick(item.id)}
              ></i>
            </td>
          </tr>
        ))
      : "";

  const handleUf = (event) => {
    setUf(event.target.value);
  };

  const handleTipo = (event) => {
    setTipo(event.target.value);
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const showConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(true);
  };

  const hideConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(false);
  };

  const showSuccessUpdate = () => {
    setShowSuccessUpdateModal(true);
  };

  const hideSuccessUpdate = () => {
    setShowSuccessUpdateModal(false);
  };

  const showErrorUpdate = () => {
    setShowErrorUpdateModal(true);
  };

  const hideErrorUpdate = () => {
    setShowErrorUpdateModal(false);
  };

  const showConfirmationDelete = () => {
    setShowConfirmationDeleteModal(true);
  };

  const hideConfirmationDelete = () => {
    setShowConfirmationDeleteModal(false);
  };

  const showSuccessDelete = () => {
    setShowSuccessDeleteModal(true);
  };

  const hideSuccessDelete = () => {
    setShowSuccessDeleteModal(false);
  };

  const showErrorDelete = () => {
    setShowErrorDeleteModal(true);
  };

  const hideErrorDelete = () => {
    setShowErrorDeleteModal(false);
  };

  const showInvalidLogin = () => {
    setShowInvalidLoginModal(true);
  };

  const hideInvalidLogin = () => {
    setShowInvalidLoginModal(false);
  };

  const showErrorSearch = () => {
    setShowErrorSearchModal(true);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  const showConfirmationRegister = () => {
    setShowConfirmationRegisterModal(true);
  };

  const hideConfirmationRegister = () => {
    setShowConfirmationRegisterModal(false);
  };

  const showSuccessRegister = () => {
    setShowSuccessRegisterModal(true);
  };

  const hideSuccessRegister = () => {
    setShowSuccessRegisterModal(false);
  };

  const handleOpenFilePicker = () => {
    inputFileRef.current.click();
  };

  const handleRemoveFile = () => {
    setImagemPerfilSelecionada(null);
    setIdImagemLogo("");
    setNomeArquivo("Nenhum arquivo selecionado");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNomeArquivo(file.name);
      setImagemPerfil(file);
      setIdImagemLogo("");

      const reader = new FileReader();
      reader.onload = () => {
        setImagemPerfilSelecionada(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePenClick = function (id) {
    setLoading(true);
    limpaCampos();

    const usuarioAtual = usuarios.find((usuario) => usuario.id === id);

    if (usuarioAtual) {
      setId(usuarioAtual.id);
      setNome(usuarioAtual.nome);
      setToken(usuarioAtual.token);
      setTipo(usuarioAtual.tipo);
      setStatus(usuarioAtual.status);
      setCnpj(usuarioAtual.cnpj);
      setInscricaoEstadual(usuarioAtual.inscricao_estadual);
      setDataAbertura(formatarDataISO(usuarioAtual.data_abertura));
      setEmailInstitucional(usuarioAtual.email_institucional);
      setTelefone(usuarioAtual.telefone);
      setCep(usuarioAtual.cep);
      setRua(usuarioAtual.rua);
      setBairro(usuarioAtual.bairro);
      setCidade(usuarioAtual.cidade);
      setUf(usuarioAtual.uf);
      setNumeroEndereco(usuarioAtual.numero_endereco);
      setComplemento(usuarioAtual.complemento);
      setAceiteTermos(usuarioAtual.aceite_termos);
      setCorPrimaria(usuarioAtual.cor_primaria);
      setCorSecundaria(usuarioAtual.cor_secundaria);
      setNomeProprietario(usuarioAtual.nome_proprietario);
      setStatus(usuarioAtual.status);

      if (user_images[id] === "") {
        handleRemoveFile();
      } else {
        setIdImagemLogo(usuarioAtual.id_imagem_logo);
        setImagemPerfilSelecionada(user_images[id]);
        setNomeArquivo(usuarioAtual.title);
      }
    }

    setLoading(false);
  };

  const handleTrashClick = function (id) {
    setId(id);
    showConfirmationDelete();
  };

  const handleCep = async () => {
    setLoading(true);

    try {
      const data = await buscarCep(cep);

      setRua(data.logradouro);
      setBairro(data.bairro);
      setCidade(data.localidade);
      setUf(data.uf);
      setNumeroEndereco("");
      setComplemento(data.complemento);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const mostraSenha = () => {
    setType(exibirSenha === "text" ? "password" : "text");
    setEye(btnEye === faEyeSlash ? faEye : faEyeSlash);
  };

  const cadastrarRestaurante = () => {
    setLoading(true);

    const formData = new FormData();

    formData.append("nome", nome);
    formData.append("token", token);
    formData.append("tipo", tipo);
    formData.append("status", status);
    formData.append("cnpj", cnpj);
    formData.append("nome_proprietario", nome_proprietario);
    formData.append("inscricao_estadual", inscricao_estadual);
    formData.append("data_abertura", data_abertura);
    formData.append("email_institucional", email_institucional);
    formData.append("telefone", telefone);
    formData.append("cep", cep);
    formData.append("rua", rua);
    formData.append("bairro", bairro);
    formData.append("cidade", cidade);
    formData.append("uf", uf);
    formData.append("numero_endereco", numero_endereco);
    formData.append("complemento", complemento);
    formData.append("senha", senha);
    formData.append("confirmar_senha", confirmar_senha);
    formData.append("aceite_termos", true);
    formData.append("status", status);
    formData.append("cor_primaria", cor_primaria);
    formData.append("cor_secundaria", cor_secundaria);
    formData.append("imagem_perfil", imagem_perfil);
    formData.append("id_imagem_logo", id_imagem_logo);
    formData.append("title", "LOGO_RESTAURANTE");
    formData.append("description", "LOGO_RESTAURANTE");
    formData.append("type", "LOGO_RESTAURANTE");

    axios
      .post(`https://www.api.qftech.com.br/api/register/registerrestaurant`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        hideConfirmationRegister();
        showSuccessRegister();

        if (closeButtonRegisterRef.current) {
          closeButtonRegisterRef.current.click();
        }

        buscarRestaurante();
        limpaCampos();
      })
      .catch((err) => {
        hideConfirmationRegister();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const atualizarRestaurante = (id) => {
    setLoading(true);

    const formData = new FormData();

    formData.append("nome", nome);
    formData.append("token", token);
    formData.append("tipo", tipo);
    formData.append("status", status);
    formData.append("cnpj", cnpj);
    formData.append("nome_proprietario", nome_proprietario);
    formData.append("inscricao_estadual", inscricao_estadual);
    formData.append("data_abertura", data_abertura);
    formData.append("email_institucional", email_institucional);
    formData.append("telefone", telefone);
    formData.append("cep", cep);
    formData.append("rua", rua);
    formData.append("bairro", bairro);
    formData.append("cidade", cidade);
    formData.append("uf", uf);
    formData.append("numero_endereco", numero_endereco);
    formData.append("complemento", complemento);
    formData.append("senha", senha);
    formData.append("confirmar_senha", confirmar_senha);
    formData.append("aceite_termos", aceite_termos);
    formData.append("status", status);
    formData.append("cor_primaria", cor_primaria);
    formData.append("cor_secundaria", cor_secundaria);
    formData.append("aceite_termos", true);
    formData.append("imagem_perfil", imagem_perfil);
    formData.append("id_imagem_logo", id_imagem_logo);
    formData.append("title", "LOGO_RESTAURANTE");
    formData.append("description", "LOGO_RESTAURANTE");
    formData.append("type", "LOGO_RESTAURANTE");

    axios
      .put(
        `https://www.api.qftech.com.br/api/update/updaterestaurant/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        hideConfirmationUpdate();
        showSuccessUpdate();

        if (closeButtonUpdateRef.current) {
          closeButtonUpdateRef.current.click();
        }

        buscarRestaurante();
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }

        hideConfirmationUpdate();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletarRestaurante = (id) => {
    setLoading(true);

    axios
      .delete(`https://www.api.qftech.com.br/api/delete/deleterestaurant/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        hideConfirmationDelete();
        showSuccessDelete();
        buscarRestaurante();
      })
      .catch((err) => {
        hideConfirmationDelete();
        showErrorDelete();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filtro = valorFiltro;
  const filtrarRestaurante = () => {
    setLoading(true);

    axios
      .post(
        `https://www.api.qftech.com.br/api/filter/filterrestaurant`,
        JSON.stringify({ filtro }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setUsuarios(response.data);

        const imageUrls = {};

        response.data.forEach((user) => {
          if (user.buffer === "") {
            imageUrls[user.id] = "";
          } else {
            const uint8Array = new Uint8Array(user.buffer.data);
            const blob = new Blob([uint8Array], { type: user.mimeType });
            const imageUrl = URL.createObjectURL(blob);

            imageUrls[user.id] = imageUrl;
          }
        });

        setUserImages(imageUrls);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }
        if (err.response.data !== "Nenhum restaurante encontrado!") {
          showErrorSearch();
          setShowErrorSearchModal(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const buscarRestaurante = () => {
    setLoading(true);

    axios
      .get("https://www.api.qftech.com.br/api/list/listrestaurant", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        limpaCampos();
        setUsuarios(response.data);

        const imageUrls = {};

        response.data.forEach((user) => {
          if (user.buffer === "") {
            imageUrls[user.id] = "";
          } else {
            const uint8Array = new Uint8Array(user.buffer.data);
            const blob = new Blob([uint8Array], { type: user.mimeType });
            const imageUrl = URL.createObjectURL(blob);

            imageUrls[user.id] = imageUrl;
          }
        });

        setUserImages(imageUrls);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
        } else if (err.response.data !== "Nenhum restaurante encontrado!") {
          showErrorSearch();
          setShowErrorSearchModal(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    limpaCampos();

    axios
      .get("https://www.api.qftech.com.br/api/list/listrestaurant", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        limpaCampos();
        setUsuarios(response.data);

        const imageUrls = {};

        response.data.forEach((user) => {
          if (user.buffer === "") {
            imageUrls[user.id] = "";
          } else {
            const uint8Array = new Uint8Array(user.buffer.data);
            const blob = new Blob([uint8Array], { type: user.mimeType });
            const imageUrl = URL.createObjectURL(blob);

            imageUrls[user.id] = imageUrl;
          }
        });

        setUserImages(imageUrls);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
        } else if (err.response.data !== "Nenhum restaurante encontrado!") {
          showErrorSearch();
          setShowErrorSearchModal(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [access_token, navigate]);

  const limpaCampos = () => {
    setId("");
    setNome("");
    setToken("");
    setCnpj("");
    setInscricaoEstadual("");
    setNomeProprietario("");
    setTipo("");
    setStatus("");
    setDataAbertura("");
    setEmailInstitucional("");
    setTelefone("");
    setCep("");
    setRua("");
    setBairro("");
    setCidade("");
    setUf("");
    setNumeroEndereco("");
    setComplemento("");
    setAceiteTermos("");
    setConfirmarSenha("");
    setCorPrimaria("");
    setCorSecundaria("");
    setIdImagemLogo("");
    setImagemPerfilSelecionada("");
    setNomeArquivo("Nenhum arquivo foi selecionado");
    setValorFiltro("");
    setSenha("");
    setConfirmarSenha("");
  };

  return (
    <>
      {loading && (
        <DivLoading>
          <ReactLoading
            type="bars"
            color="#fff"
            width="100px"
            height="100px"
            className="loading"
          />
        </DivLoading>
      )}

      <HeaderAdmin />
      <ConteudoPrincipal>
        <NavBarAdmin />
        <ContainerOutNav>
          <Titulo>
            Seja bem-vindo a seção de <GreenText>restaurantes!</GreenText>
          </Titulo>
          <Subtitulo>
            Fazemos o possível para que seu dia possa ficar melhor e mais ágil!
          </Subtitulo>
          <div>
            <InputPesquisa
              type="text"
              placeholder="Faça uma pesquisa!"
              className="form-control"
              onChange={(event) => setValorFiltro(event.target.value)}
              autoComplete="new-password"
              value={valorFiltro}
              id="filtroPesquisa"
            />
            <Search icon={faSearch} onClick={() => filtrarRestaurante()} />
            <Add
              icon={faPlus}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              onClick={() => limpaCampos()}
            />
          </div>
          <TableContainer>
            <table className="table table-hover">
              <thead className="table-light">
                <tr>
                  <ColumnTitle scope="col">#</ColumnTitle>
                  <ColumnTitle scope="col">Nome</ColumnTitle>
                  <ColumnTitle scope="col">Email Institucional</ColumnTitle>
                  <ColumnTitle scope="col">CNPJ</ColumnTitle>
                  <ColumnTitle scope="col">Token</ColumnTitle>
                  <ColumnTitle scope="col">Editar</ColumnTitle>
                  <ColumnTitle scope="col">Excluir</ColumnTitle>
                </tr>
              </thead>
              <TableTBody className="table-group-divider" id="tbodyRestaurante">
                {usuarios.length > 0 ? (
                  usersToDisplay
                ) : (
                  <tr>
                    <td colSpan="7">Nenhum restaurante encontrado!</td>
                  </tr>
                )}
              </TableTBody>
            </table>
            <StyledPagination
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              breakLabel={"..."}
              pageCount={Math.ceil(usuarios.length / dataPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </TableContainer>
        </ContainerOutNav>
      </ConteudoPrincipal>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edição de Dados</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações Empresariais</TituloEdit>
              <hr />
              <div className="row">
                <ColImageContainer className="col-md-4">
                  <UploadImageContainer>
                    <UploadImageConteudo onClick={handleOpenFilePicker}>
                      {imagem_perfil_selecionada && (
                        <ImageSelected
                          src={imagem_perfil_selecionada}
                          alt="Imagem Selecionada"
                        />
                      )}
                      <BigIcon icon={faImage} />
                    </UploadImageConteudo>
                    <DescricaoImage htmlFor="file_edicao" ref={inputFileRef}>
                      <SmallIcon icon={faFile} />
                      <SubtituloImagem>{nome_arquivo}</SubtituloImagem>
                      <SmallIcon icon={faTrashAlt} onClick={handleRemoveFile} />
                    </DescricaoImage>
                    <UploadInput
                      id="file_edicao"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </UploadImageContainer>
                </ColImageContainer>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="Outback"
                      id="nome"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="cnpj" className="form-label">
                      CNPJ
                    </label>
                    <InputMask
                      mask="__.___.___/____-__"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={cnpj}
                      onChange={(event) => setCnpj(event.target.value)}
                      placeholder="XX.XXX.XXX/XXXX-XX"
                      id="cnpj"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="tipo" className="form-label">
                      Tipo
                    </label>
                    <select
                      className="form-select"
                      id="tipo"
                      value={tipo}
                      onChange={handleTipo}
                    >
                      <option value="">Selecione um Tipo</option>
                      <option value="Restaurante">Restaurante</option>
                      <option value="Lanchonete">Lanchonete</option>
                      <option value="Bar">Bar</option>
                      <option value="Outro">Outro</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="status"
                      value={status}
                      onChange={handleStatus}
                    >
                      <option value="">Selecione um Status</option>
                      <option value="true">Ativo</option>
                      <option value="false">Inativo</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="inscricaoEstadual" className="form-label">
                      Inscrição Estadual
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={inscricao_estadual}
                      onChange={(event) =>
                        setInscricaoEstadual(event.target.value)
                      }
                      placeholder="XXX.XXX.XXX/XXXX"
                      id="inscricaoEstadual"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="dataAbertura" className="form-label">
                      Data de Abertura
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={data_abertura}
                      onChange={(event) => setDataAbertura(event.target.value)}
                      id="dataAbertura"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="nome_proprietario" className="form-label">
                      Nome Completo do Proprietário
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome_proprietario}
                      onChange={(event) =>
                        setNomeProprietario(event.target.value)
                      }
                      placeholder="Sebastian D'Aubigny"
                      id="nome_proprietario"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="dataAbertura" className="form-label">
                      Cores que Representam o Restaurante
                    </label>
                    <DivCores className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <input
                            type="color"
                            className="form-control form-control-color"
                            value={cor_primaria}
                            onChange={(event) =>
                              setCorPrimaria(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <input
                            type="color"
                            className="form-control form-control-color"
                            value={cor_secundaria}
                            onChange={(event) =>
                              setCorSecundaria(event.target.value)
                            }
                          />
                        </div>
                      </div>
                    </DivCores>
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Contato</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="email_institucional" className="form-label">
                      Email Institucional
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email_institucional}
                      onChange={(event) =>
                        setEmailInstitucional(event.target.value)
                      }
                      placeholder="email@email.com"
                      id="email_institucional"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="telefone" className="form-label">
                      Telefone
                    </label>
                    <InputMask
                      mask="(__) ____-____"
                      replacement={{ _: /\d/ }}
                      type="tel"
                      className="form-control"
                      value={telefone}
                      onChange={(event) => setTelefone(event.target.value)}
                      placeholder="(XX) XXXX-XXXX"
                      id="telefone"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cep" className="form-label">
                      CEP
                    </label>
                    <InputMask
                      mask="_____-___"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={cep}
                      onChange={(event) => setCep(event.target.value)}
                      onBlur={handleCep}
                      placeholder="XXXXX-XXX"
                      id="cep"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Endereço</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="rua" className="form-label">
                      Rua
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={rua}
                      onChange={(event) => setRua(event.target.value)}
                      placeholder="Av. Paulista"
                      id="rua"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="bairro" className="form-label">
                      Bairro
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={bairro}
                      onChange={(event) => setBairro(event.target.value)}
                      placeholder="Bela Vista"
                      id="bairro"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cidade" className="form-label">
                      Cidade
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={cidade}
                      onChange={(event) => setCidade(event.target.value)}
                      placeholder="São Paulo"
                      id="cidade"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="uf" className="form-label">
                      UF
                    </label>
                    <select
                      className="form-select"
                      id="uf"
                      value={uf}
                      onChange={handleUf}
                    >
                      <option value="">Selecione um UF</option>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="numero" className="form-label">
                      Número
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={numero_endereco}
                      onChange={(event) =>
                        setNumeroEndereco(event.target.value)
                      }
                      placeholder="115"
                      id="numero"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="complemento" className="form-label">
                      Complemento
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={complemento}
                      onChange={(event) => setComplemento(event.target.value)}
                      placeholder="Apartamento 204"
                      id="complemento"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Senha</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="senha" className="form-label">
                      Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={senha}
                      onChange={(event) => setSenha(event.target.value)}
                      placeholder="••••••"
                      id="senha"
                      autoComplete="new-password"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="confirmar_senha" className="form-label">
                      Confirmar Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={confirmar_senha}
                      onChange={(event) =>
                        setConfirmarSenha(event.target.value)
                      }
                      placeholder="••••••"
                      id="confirmar_senha"
                      autoComplete="new-password"
                      required
                    />
                    <FontAwesomeIcon
                      icon={btnEye}
                      onClick={mostraSenha}
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "42px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                ref={closeButtonUpdateRef}
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationUpdate}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cadastro de Administrador</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações Empresariais</TituloEdit>
              <hr />
              <div className="row">
                <ColImageContainer className="col-md-4">
                  <UploadImageContainer>
                    <UploadImageConteudo onClick={handleOpenFilePicker}>
                      {imagem_perfil_selecionada && (
                        <ImageSelected
                          src={imagem_perfil_selecionada}
                          alt="Imagem Selecionada"
                        />
                      )}
                      <BigIcon icon={faImage} />
                    </UploadImageConteudo>
                    <DescricaoImage htmlFor="file_edicao" ref={inputFileRef}>
                      <SmallIcon icon={faFile} />
                      <SubtituloImagem>{nome_arquivo}</SubtituloImagem>
                      <SmallIcon icon={faTrashAlt} onClick={handleRemoveFile} />
                    </DescricaoImage>
                    <UploadInput
                      id="file_edicao"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </UploadImageContainer>
                </ColImageContainer>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="Outback"
                      id="nome"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="cnpj" className="form-label">
                      CNPJ
                    </label>
                    <InputMask
                      mask="__.___.___/____-__"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={cnpj}
                      onChange={(event) => setCnpj(event.target.value)}
                      placeholder="XX.XXX.XXX/XXXX-XX"
                      id="cnpj"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="tipo" className="form-label">
                      Tipo
                    </label>
                    <select
                      className="form-select"
                      id="tipo"
                      value={tipo}
                      onChange={handleTipo}
                    >
                      <option value="">Selecione um Tipo</option>
                      <option value="Restaurante">Restaurante</option>
                      <option value="Lanchonete">Lanchonete</option>
                      <option value="Bar">Bar</option>
                      <option value="Outro">Outro</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="status"
                      value={status}
                      onChange={handleStatus}
                    >
                      <option value="">Selecione um Status</option>
                      <option value="true">Ativo</option>
                      <option value="false">Inativo</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="inscricaoEstadual" className="form-label">
                      Inscrição Estadual
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={inscricao_estadual}
                      onChange={(event) =>
                        setInscricaoEstadual(event.target.value)
                      }
                      placeholder="XXX.XXX.XXX/XXXX"
                      id="inscricaoEstadual"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="dataAbertura" className="form-label">
                      Data de Abertura
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={data_abertura}
                      onChange={(event) => setDataAbertura(event.target.value)}
                      id="dataAbertura"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="nomeProprietario" className="form-label">
                      Nome Completo do Proprietário
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome_proprietario}
                      onChange={(event) =>
                        setNomeProprietario(event.target.value)
                      }
                      placeholder="Sebastian D'Aubigny"
                      id="nomeProprietario"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label for="dataAbertura" className="form-label">
                      Cores que Representam o Restaurante
                    </label>
                    <DivCores className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <input
                            type="color"
                            className="form-control form-control-color"
                            value={cor_primaria}
                            onChange={(event) =>
                              setCorPrimaria(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <input
                            type="color"
                            className="form-control form-control-color"
                            value={cor_secundaria}
                            onChange={(event) =>
                              setCorSecundaria(event.target.value)
                            }
                          />
                        </div>
                      </div>
                    </DivCores>
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Contato</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="email_institucional" className="form-label">
                      Email Institucional
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email_institucional}
                      onChange={(event) =>
                        setEmailInstitucional(event.target.value)
                      }
                      placeholder="email@email.com"
                      id="email_institucional"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="telefone" className="form-label">
                      Telefone
                    </label>
                    <InputMask
                      mask="(__) ____-____"
                      replacement={{ _: /\d/ }}
                      type="tel"
                      className="form-control"
                      value={telefone}
                      onChange={(event) => setTelefone(event.target.value)}
                      placeholder="(XX) XXXX-XXXX"
                      id="telefone"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cep" className="form-label">
                      CEP
                    </label>
                    <InputMask
                      mask="_____-___"
                      replacement={{ _: /\d/ }}
                      type="text"
                      className="form-control"
                      value={cep}
                      onChange={(event) => setCep(event.target.value)}
                      onBlur={handleCep}
                      placeholder="XXXXX-XXX"
                      id="cep"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Endereço</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="rua" className="form-label">
                      Rua
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={rua}
                      onChange={(event) => setRua(event.target.value)}
                      placeholder="Av. Paulista"
                      id="rua"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="bairro" className="form-label">
                      Bairro
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={bairro}
                      onChange={(event) => setBairro(event.target.value)}
                      placeholder="Bela Vista"
                      id="bairro"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="cidade" className="form-label">
                      Cidade
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={cidade}
                      onChange={(event) => setCidade(event.target.value)}
                      placeholder="São Paulo"
                      id="cidade"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="uf" className="form-label">
                      UF
                    </label>
                    <select
                      className="form-select"
                      id="uf"
                      value={uf}
                      onChange={handleUf}
                    >
                      <option value="">Selecione um UF</option>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="numero" className="form-label">
                      Número
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={numero_endereco}
                      onChange={(event) =>
                        setNumeroEndereco(event.target.value)
                      }
                      placeholder="115"
                      id="numero"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="complemento" className="form-label">
                      Complemento
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={complemento}
                      onChange={(event) => setComplemento(event.target.value)}
                      placeholder="Apartamento 204"
                      id="complemento"
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Senha</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="senha" className="form-label">
                      Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={senha}
                      onChange={(event) => setSenha(event.target.value)}
                      placeholder="••••••"
                      id="senha"
                      autoComplete="new-password"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="confirmar_senha" className="form-label">
                      Confirmar Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={confirmar_senha}
                      onChange={(event) =>
                        setConfirmarSenha(event.target.value)
                      }
                      placeholder="••••••"
                      id="confirmar_senha"
                      autoComplete="new-password"
                      required
                    />
                    <FontAwesomeIcon
                      icon={btnEye}
                      onClick={mostraSenha}
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "42px",
                      }}
                    />
                  </div>
                </div>
                <hr />
                <TituloEdit>Termos</TituloEdit>
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <CheckBox
                      type="checkbox"
                      value={aceite_termos}
                      id="aceite_termos"
                      onChange={(event) =>
                        setAceiteTermos(event.target.checked)
                      }
                    />
                    <label for="aceite_termos">
                      Li e concordo com os termos e condições
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                ref={closeButtonRegisterRef}
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationRegister}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <CustomModalConfirmCancel
        show={showConfirmationUpdateModal}
        onHide={hideConfirmationUpdate}
        title="Atenção"
        body="Tem certeza que deseja atualizar os dados?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => atualizarRestaurante(id)}
        onCancel={hideConfirmationUpdate}
      />

      <CustomModalOnlyMessage
        show={showSuccessUpdateModal}
        onHide={hideSuccessUpdate}
        title="Sucesso"
        body="Os dados foram atualizados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorUpdateModal}
        onHide={hideErrorUpdate}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalConfirmCancel
        show={showConfirmationDeleteModal}
        onHide={hideConfirmationDelete}
        title="Atenção"
        body="Tem certeza que deseja deletar o usuário?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => deletarRestaurante(id)}
        onCancel={hideConfirmationDelete}
      />

      <CustomModalOnlyMessage
        show={showSuccessDeleteModal}
        onHide={hideSuccessDelete}
        title="Sucesso"
        body="Usuário deletado com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorDeleteModal}
        onHide={hideErrorDelete}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalInvalidLogin
        show={showInvalidLoginModal}
        onHide={hideInvalidLogin}
      />

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalConfirmCancel
        show={showConfirmationRegisterModal}
        onHide={hideConfirmationRegister}
        title="Atenção"
        body="Tem certeza que deseja cadastrar o restaurante?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => cadastrarRestaurante()}
        onCancel={hideConfirmationRegister}
      />

      <CustomModalOnlyMessage
        show={showSuccessRegisterModal}
        onHide={hideSuccessRegister}
        title="Sucesso"
        body="Os dados foram cadastrados com sucesso!"
      />
    </>
  );
}

export default RestauranteAdmin;
