function formatarDataDatabase(data) {
  data = data.replace(/\D/g, "");

  const dia = data.slice(0, 2);
  const mes = data.slice(2, 4);
  const ano = data.slice(4, 8);

  return `${ano}-${mes}-${dia}`;
}

module.exports = formatarDataDatabase;