import { useState, useEffect } from "react";
import ClientLoginStyle from "../../assets/styles/Client/Client_Login_Style";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import StyledGeneral from "../../assets/styles/General/GeneralStyle";

const { DivLoading } = StyledGeneral;

const {
  Corpo,
  Imagens,
  ImgCook,
  Formulario,
  DivDoFrom,
  Titulo1,
  Lbl,
  Form1,
  TxtName,
  TxtNum,
  TxtChave,
  BtnAcessar,
} = ClientLoginStyle;

function Clientelogin() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [numero_mesa, setNumeroMesa] = useState("");
  const [token_restaurante, setToken] = useState("");

  useEffect(() => {
    setLoading(true);

    let url = window.location.href;
    let urlObj = new URL(url);
    let numeromesa = urlObj.searchParams.get("mesa");
    let token = urlObj.searchParams.get("token");

    if (numeromesa !== null && token !== null) {
      setNumeroMesa(numeromesa);
      setToken(token);
    }

    setLoading(false);
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    setLoading(true);

    const dados = {
      nome,
      numero_mesa,
      token_restaurante,
    };

    axios
      .post("https://www.api.qftech.com.br/api/register/registerclient", dados)
      .then((response) => {
        localStorage.removeItem("token");
        localStorage.removeItem("nome");
        localStorage.removeItem("funcao");
        localStorage.removeItem("imagem_perfil_buffer");
        localStorage.removeItem("imagem_perfil_mimeType");
        localStorage.removeItem("mesa");
        localStorage.setItem("token", token_restaurante);
        localStorage.setItem("mesa", numero_mesa);
        
        navigate("/cliente/home_pedidos");
      })
      .catch((error) => {
        alert(error.response.data);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Corpo>
      {loading && (
        <DivLoading>
          <ReactLoading
            type="bars"
            color="#fff"
            width="100px"
            height="100px"
            className="loading"
          />
        </DivLoading>
      )}

      <Imagens>
        <ImgCook />
      </Imagens>
      <Formulario>
        <DivDoFrom>
          <Titulo1>WELCOME!</Titulo1>
          <Form1>
            <Lbl>Nome Completo:</Lbl>
            <TxtName
              autoFocus
              type="text"
              value={nome}
              placeholder="John Doe"
              onChange={(event) => setNome(event.target.value)}
            />
            <Lbl>Número da Mesa:</Lbl>
            <TxtNum
              type="number"
              value={numero_mesa}
              placeholder="12"
              onChange={(event) => setNumeroMesa(event.target.value)}
            />
            <Lbl>Chave de Acesso:</Lbl>
            <TxtChave
              type="text"
              value={token_restaurante}
              placeholder="TOKEN"
              maxLength="5"
              onChange={(event) => setToken(event.target.value)}
            />
            <BtnAcessar onClick={handleClick}> ACESSAR </BtnAcessar>
          </Form1>
        </DivDoFrom>
      </Formulario>
    </Corpo>
  );
}

export default Clientelogin;