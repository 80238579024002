import styled from "styled-components";

import "../../fonts/Raleway";
import "../../fonts/Inter";
import "../../fonts/Poppins";

const KitchenRegisterStyle = {
  Principal: styled.div`
    margin: 0;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 90vh;
  `,

  ContainerImagens: styled.div``,

  ImgFundo: styled.img`
    position: absolute;
    z-index: -1;
    max-height: 100vh;
    width: 100vw;
  `,

  ImgLogo: styled.img`
    max-width: 120px;
    max-height: 120px;
    margin-left: 2vw;
    margin-top: 2vh;
  `,

  ImgCadastro: styled.img`
    max-width: 500px;
    margin-left: 11vw;
  `,

  ContainerFormulario: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    width: 50vw;
    height: 82vh;
    margin-top: 2vh;
    margin-right: 4vw;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  `,

  Titulo: styled.h1`
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    margin-top: 30px;
  `,

  Formulario: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5vh;
  `,

  Labels: styled.label`
    font-family: "Poppins", sans-serif;
  `,

  DivInputIcon: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  `,

  Inputs: styled.input`
    width: 400px;
    height: 30px;
    border-radius: 5px;
    border: solid 1px #1b2029;
    margin-bottom: 20px;
    margin-top: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 14px;
    margin-right: 10px;
    padding-left: 10px;
    font-family: "Poppins", sans-serif;

    &:focus {
      border: solid 1px #26874e;
      font-weight: 600;
      outline: none;
    }
  `,

  ContainerTermos: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 30px;
  `,

  CheckBox: styled.input`
    margin-right: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 15px;
    height: 15px;
  `,

  Cadastrar: styled.button`
    border: solid 1px #26874e;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #4da768;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: "Poppins", sans-serif;
    transition: all 0.5s;
    align-self: center;
    margin-bottom: 30px;

    &:hover {
      background-color: #99e89d;
      color: #1b2029;
      transition: all 0.5s;
    }
  `,
};

export default KitchenRegisterStyle;