import styled from "styled-components";

const StyleIncrementos = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    min-height: 100vh;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    display: grid;
    grid-template-columns: 100%;
    align-items: flex-start;
  `,

  Titulo: styled.h2`
    width: 100%;
    text-align: left;
    font-family: Poppins, "sans-serif";
    font-size: 16px;
    font-weight: 700;
  `,

  Btn: styled.button`
    background-color: #1b2029;
    color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    width: 200px;
    height: 35px;
    justify-self: center;
    margin-top: 30px;
    margin-bottom: 40px;
    transition: all 0.5s;
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #7da768;
      color: #fff;
    }
  `,
};

export default StyleIncrementos;