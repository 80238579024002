import React from "react";
import { Modal, Button } from "react-bootstrap";

function ModalImages({ show, onHide, title, body }) {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-xl">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalImages;