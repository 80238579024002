import StyleHeader from "../../../assets/styles/GestaoPedidos_Cozinha/Header/StyleHeader";
import ImageLogo from "../../../assets/img/logo.png";
import UserLogin from "../../Props/DefaultDropdown/UserLogin";

const { ConteudoPrincipal, ImgLogo, TituloHeader } = StyleHeader;

function Header() {
  return (
    <>
      <ConteudoPrincipal>
        <ImgLogo src={ImageLogo} alt="img-logo" />
        <TituloHeader>Gestor de Pedidos(KDS)</TituloHeader>
        <UserLogin />
      </ConteudoPrincipal>
    </>
  );
}

export default Header;