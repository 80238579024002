import $ from "jquery";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StyleHome from "../../assets/styles/GestaoPedidos_Cozinha/StyleHome";
import Header from "./Header/Header";
import Navbar from "./Navbar/Navbar";
import BtnStatusPedido from "../Props/BtnStatusPedido/BtnStatusPedido";
import {
  faCheck,
  faClock,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import InfoPedido from "./InfoPedido/InfoPedido";
import { useState } from "react";
import NenhumPedido from "./NenhumPedido";
import PedidoConcluido from "./PedidoConcluido/PedidoConcluido";
import PagamentoPendente from "./PagamentoPendente/PagamentoPendente";
import { useEffect } from "react";

const {
  AllContent,
  ConteudoPrincipal,
  Title,
  ContainerBtnStatusPedido,
  ContainerPedidos,
  ContainerPagamentosPendentes,
} = StyleHome;

function HomeGestaoPedidos() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  $(document).ready(function () {
    axios
      .get("https://www.api.qftech.com.br/api/access/isLoggedIn", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      })
      .then((response) => {})
      .catch((err) => {
        localStorage.removeItem("token");
        localStorage.removeItem("nome");
        localStorage.removeItem("funcao");
        localStorage.removeItem("imagem_perfil_buffer");
        localStorage.removeItem("imagem_perfil_mimeType");
        localStorage.removeItem("mesa");
        
        navigate("/logincozinha");
      });
  });

  const [tempoCorridoGlobal, setTempoCorridoGlobal] = useState(
    Number(localStorage.getItem("tempoCorrido")) || 0
  );

  const [activeButton, setActiveButton] = useState(1);
  const [objPedido, setObjPedido] = useState(
    JSON.parse(localStorage.getItem("objPedido")) || []
  );
  const [objPedidoConcluido, setObjPedidoConcluido] = useState(
    JSON.parse(localStorage.getItem("objPedidoConcluido")) || []
  );

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  useEffect(() => {
    setObjPedido([
      {
        numMesa: 12,
        nomeUsuario: "Pedro",
        metodoPagamento: "dinheiro",
        valorTotal: 50.0,
        pedidos: [
          {
            nomeProduto: "Hamburguer XTudo",
            quantidade: 2,
            observacao: "Sem cebola",
          },
          {
            nomeProduto: "Coca-Cola Lata",
            quantidade: 3,
            observacao: null,
          },
        ],
      },
      {
        numMesa: 24,
        nomeUsuario: "Lucas",
        metodoPagamento: "dinheiro",
        valorTotal: 60.75,
        pedidos: [
          {
            nomeProduto: "Hamburguer XBacon",
            quantidade: 2,
            observacao: null,
          },
          {
            nomeProduto: "Suco de laranja",
            quantidade: 1,
            observacao: "Sem açúcar",
          },
        ],
      },
    ]);
  }, []);

  const handlePedidoProntoButtonClick = (numMesa) => {
    let filtro = objPedido.filter((item) => item.numMesa === numMesa);

    setObjPedidoConcluido((pedidos) => [...pedidos, ...filtro]);
    setObjPedido((pedidos) =>
      pedidos.filter((item) => item.numMesa !== numMesa)
    );

    localStorage.setItem("objPedido", JSON.stringify(objPedido));
    localStorage.setItem(
      "objPedidoConcluido",
      JSON.stringify(objPedidoConcluido)
    );
  };

  return (
    <>
      <Header />
      <AllContent>
        <Navbar />
        <ConteudoPrincipal>
          <Title>Pedidos</Title>
          <ContainerBtnStatusPedido>
            <BtnStatusPedido
              border={"#9373C8"}
              backGround={"#f8f5e5a2"}
              backGroundFocus={"#9373c859"}
              icon={faClock}
              iconColor={"#9473c8"}
              textoBtn={"Em preparo"}
              isActive={activeButton === 1}
              onClick={() => handleButtonClick(1)}
            />
            <BtnStatusPedido
              border={"#ca6267"}
              backGround={"#f8f5e5a2"}
              backGroundFocus={"#eb626953"}
              icon={faDollarSign}
              iconColor={"#ca6267"}
              textoBtn={"Pagamentos Pendentes"}
              isActive={activeButton === 2}
              onClick={() => handleButtonClick(2)}
            />
            <BtnStatusPedido
              border={"#357548"}
              backGround={"#f8f5e5a2"}
              backGroundFocus={"#4da7683d"}
              iconColor={"#4da768"}
              icon={faCheck}
              textoBtn={"Concluídos de hoje"}
              isActive={activeButton === 3}
              onClick={() => handleButtonClick(3)}
            />
          </ContainerBtnStatusPedido>

          <ContainerPedidos>
            {activeButton === 1 ? (
              objPedido.length === 0 ? (
                <NenhumPedido texto={"Nenhum pedido no momento!"} />
              ) : (
                objPedido.map((item, index) => (
                  <InfoPedido
                    key={index}
                    numMesa={item.numMesa}
                    valorTotal={item.valorTotal}
                    tempoCorrido={tempoCorridoGlobal}
                    setTempoCorrido={setTempoCorridoGlobal}
                    nomeUsuario={item.nomeUsuario}
                    itensPedido={item.pedidos}
                    functionPedidoConcluido={() =>
                      handlePedidoProntoButtonClick(item.numMesa)
                    }
                  />
                ))
              )
            ) : (
              ""
            )}
          </ContainerPedidos>

          {activeButton === 2 && (
            <ContainerPagamentosPendentes>
              {objPedido.some((item) => item.metodoPagamento !== "dinheiro") ? (
                <NenhumPedido texto={"Nenhum pedido com pagamento pendente!"} />
              ) : (
                objPedido
                  .filter((item) => item.metodoPagamento === "dinheiro")
                  .map((item, index) => (
                    <PagamentoPendente
                      key={index}
                      nomeUsuario={item.nomeUsuario}
                      numMesa={item.numMesa}
                      valorTotal={item.valorTotal}
                    />
                  ))
              )}
            </ContainerPagamentosPendentes>
          )}

          <ContainerPedidos>
            {activeButton === 3 ? (
              objPedidoConcluido.length === 0 ? (
                <NenhumPedido texto={"Nenhum pedido concluído no momento!"} />
              ) : (
                objPedidoConcluido.map((item, index) => (
                  <PedidoConcluido
                    key={index}
                    tempoCorrido={localStorage.getItem("tempoIndividual")}
                    valorTotal={item.valorTotal}
                    numMesa={item.numMesa}
                    nomeUsuario={item.nomeUsuario}
                    itensPedido={item.pedidos}
                  />
                ))
              )
            ) : (
              ""
            )}
          </ContainerPedidos>
        </ConteudoPrincipal>
      </AllContent>
    </>
  );
}

export default HomeGestaoPedidos;