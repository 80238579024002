import React from "react";
import { Modal, Button } from "react-bootstrap";

function CustomModal({
  show,
  onHide,
  title,
  body,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onCancel}>
          {cancelButtonText || "Cancelar"}
        </Button>
        <Button variant="success" onClick={onConfirm}>
          {confirmButtonText || "Confirmar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;