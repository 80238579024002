import React, { useState } from "react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import StyledAcrescimoAdmin from "../../../../assets/styles/Admin/Cardapio/Acrescimo/StyleAcrescimoAdmin";
import HeaderAdmin from "../../Header/Header_Admin";
import NavBarAdmin from "../../Navbar/NavbarAdmin";
import $ from "jquery";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import CustomModalConfirmCancel from "../../../Common/Modal_Confirm_Cancel.jsx";
import CustomModalOnlyMessage from "../../../Common/Modal_Only_Message.jsx";
import CustomModalInvalidLogin from "../../../Common/Modal_Invalid_Login.jsx";

const {
  ConteudoPrincipal,
  TableContainer,
  Titulo,
  ContainerOutNav,
  GreenText,
  InputPesquisa,
  Search,
  ColumnTitle,
  Subtitulo,
  TableTBody,
  TituloEdit,
  Add,
} = StyledAcrescimoAdmin;

function AcrescimoAdmin() {
  let acrescimos;
  const navigate = useNavigate();
  const access_token = localStorage.getItem("token");
  const confirmButton = document.querySelector('[data-bs-dismiss="modal"]');
  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [valor, setValor] = useState("");
  const [valorFiltro, setValorFiltro] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmationUpdateModal, setShowConfirmationUpdateModal] =
    useState(false);
  const [showConfirmationRegisterModal, setShowConfirmationRegisterModal] =
    useState(false);
  const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false);
  const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [showErrorDeleteModal, setShowErrorDeleteModal] = useState(false);
  const [showInvalidLoginModal, setShowInvalidLoginModal] = useState(false);
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);

  const showConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(true);
  };

  const hideConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(false);
  };

  const showConfirmationRegister = () => {
    setShowConfirmationRegisterModal(true);
  };

  const hideConfirmationRegister = () => {
    setShowConfirmationRegisterModal(false);
  };

  const showSuccessUpdate = () => {
    setShowSuccessUpdateModal(true);
  };

  const hideSuccessUpdate = () => {
    setShowSuccessUpdateModal(false);
  };

  const showErrorUpdate = () => {
    setShowErrorUpdateModal(true);
  };

  const hideErrorUpdate = () => {
    setShowErrorUpdateModal(false);
  };

  const showConfirmationDelete = () => {
    setShowConfirmationDeleteModal(true);
  };

  const hideConfirmationDelete = () => {
    setShowConfirmationDeleteModal(false);
  };

  const showSuccessDelete = () => {
    setShowSuccessDeleteModal(true);
  };

  const hideSuccessDelete = () => {
    setShowSuccessDeleteModal(false);
  };

  const showErrorDelete = () => {
    setShowErrorDeleteModal(true);
  };

  const hideErrorDelete = () => {
    setShowErrorDeleteModal(false);
  };

  const showInvalidLogin = () => {
    setShowInvalidLoginModal(true);
  };

  const hideInvalidLogin = () => {
    setShowInvalidLoginModal(false);
  };

  const showErrorSearch = () => {
    setShowErrorSearchModal(true);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  const handlePenClick = function () {
    const id = $(this).data("id");
    const acrescimoAtual = acrescimos.find((acrescimo) => acrescimo.id === id);

    if (acrescimoAtual) {
      setId(acrescimoAtual.id);
      setNome(acrescimoAtual.nome);
      setValor(acrescimoAtual.valor);
    }
  };

  const handleTrashClick = function () {
    const id = $(this).data("id");

    setId(id);
    showConfirmationDelete();
  };

  const dados = {
    nome,
  };

  const cadastrarAcrescimo = () => {
    axios
      .post(
        `https://www.api.qftech.com.br/api/register/registeraddition`,
        JSON.stringify(dados),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        hideConfirmationRegister();
        showSuccessUpdate();

        if (confirmButton) {
          confirmButton.click();
        }
        setNome("");
      })
      .catch((err) => {
        console.log(err);
        hideConfirmationRegister();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      });
  };

  const atualizarAcrescimo = (id) => {
    axios
      .put(
        `https://www.api.qftech.com.br/api/update/updateaddition/${id}`,
        JSON.stringify(dados),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        hideConfirmationUpdate();
        showSuccessUpdate();

        if (confirmButton) {
          confirmButton.click();
        }
        setNome("");
      })
      .catch((err) => {
        console.log(err);
        hideConfirmationUpdate();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      });
  };

  const deletarAcrescimo = (id) => {
    axios
      .delete(`https://www.api.qftech.com.br/api/delete/deleteaddition/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        hideConfirmationDelete();
        showSuccessDelete();
      })
      .catch((err) => {
        console.log(err);
        hideConfirmationDelete();
        showErrorDelete();
        setErrorMessage(err.response.data);
      });
  };

  const filtro = valorFiltro;

  const filtrarAcrescimo = () => {
    axios
      .post(
        `https://www.api.qftech.com.br/api/filter/filteraddition`,
        JSON.stringify({ filtro }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        acrescimos = response.data;

        $("#tbodyAcrescimo").empty();

        $.each(response.data, function (index, item) {
          const newRow = `
                        <tr>
                            <th>${index}</th>
                            <td>${item.nome}</td>
                            <td>
                                <i
                                    class="fa fa-pen"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    data-id="${item.id}"
                                ></i>
                            </td>
                            <td>
                                <i class="fa fa-trash" data-id="${item.id}"></i>
                            </td>
                        </tr>
                    `;
          $("#tbodyAcrescimo").append(newRow);
        });
      })
      .catch((err) => {
        console.log(err);
        showErrorDelete();
        setErrorMessage(err.response.data);
      });
  };

  $(document).on("click", ".fa-pen", handlePenClick);
  $(document).on("click", ".fa-trash", handleTrashClick);

  axios
    .get("https://www.api.qftech.com.br/api/list/listaddition", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      withCredentials: true,
    })
    .then((response) => {
      if (response.data.length !== 0) {
        acrescimos = response.data;

        $("#tbodyAcrescimo").empty();

        $.each(response.data, function (index, item) {
          const newRow = `
                    <tr>
                        <th>${index}</th>
                        <td>${item.nome}</td>
                        <td>
                            <i
                                class="fa fa-pen"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                data-id="${item.id}"
                            ></i>
                        </td>
                        <td>
                            <i class="fa fa-trash" data-id="${item.id}"></i>
                        </td>
                    </tr>
                `;
          $("#tbodyAcrescimo").append(newRow);
        });
      } else {
        $("#tbodyAcrescimo").empty();

        const newRow = `
                <tr>
                    <th colspan="4">Nenhum acréscimo encontrado!</th>
                </tr>
            `;
        $("#tbodyAcrescimo").append(newRow);
      }
    })
    .catch((err) => {
      if (
        err.response.data ===
        "Login inválido! Por favor faça o login e tente novamente!"
      ) {
        showInvalidLogin();

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else if (err.response.data !== "Nenhum acrescimo encontrado!") {
        console.log(err);
        showErrorSearch();
        setShowErrorSearchModal(err.response.data);
      } else {
        $("#tbodyAcrescimo").empty();

        const newRow = `
                <tr>
                    <th colspan="4">Nenhum acréscimo encontrado!</th>
                </tr>
            `;
        $("#tbodyAcrescimo").append(newRow);
      }
    });

  return (
    <>
      <HeaderAdmin />
      <ConteudoPrincipal>
        <NavBarAdmin />
        <ContainerOutNav>
          <Titulo>
            Seja bem-vindo a seção de <GreenText>acréscimos!</GreenText>
          </Titulo>
          <Subtitulo>
            Fazemos o possível para que seu dia possa ficar melhor e mais ágil!
          </Subtitulo>
          <div>
            <InputPesquisa
              type="text"
              placeholder="Faça uma pesquisa!"
              className="form-control"
              onChange={(event) => setValorFiltro(event.target.value)}
              id="filtroPesquisa"
            />
            <Search icon={faSearch} onClick={filtrarAcrescimo} />
            <Add
              icon={faPlus}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
            />
          </div>

          <TableContainer>
            <table className="table table-hover">
              <thead className="table-light">
                <tr>
                  <ColumnTitle scope="col">#</ColumnTitle>
                  <ColumnTitle scope="col">Nome</ColumnTitle>
                  <ColumnTitle scope="col">Editar</ColumnTitle>
                  <ColumnTitle scope="col">Excluir</ColumnTitle>
                </tr>
              </thead>
              <TableTBody
                className="table-group-divider"
                id="tbodyAcrescimo"
              ></TableTBody>
            </table>
          </TableContainer>
        </ContainerOutNav>
      </ConteudoPrincipal>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edição de Dados</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Ingrediente
                    </label>
                    <select
                      type="text"
                      className="form-select"
                      placeholder="John Doe"
                      id="ingrediente"
                      required
                    >
                      <option value={"-1"} selected>
                        -- Selecione --
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="valor" className="form-label">
                      Valor
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={valor}
                      onChange={(event) => setValor(event.target.value)}
                      placeholder="100"
                      id="valor"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationUpdate}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cadastro de Acréscimo</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Ingrediente
                    </label>
                    <select
                      type="text"
                      className="form-select"
                      placeholder="John Doe"
                      id="ingrediente"
                      required
                    >
                      <option value={"-1"} selected>
                        -- Selecione --
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="valor" className="form-label">
                      Valor
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={valor}
                      onChange={(event) => setValor(event.target.value)}
                      placeholder="100"
                      id="valor"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationRegister}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <CustomModalConfirmCancel
        show={showConfirmationUpdateModal}
        onHide={hideConfirmationUpdate}
        title="Atenção"
        body="Tem certeza que deseja atualizar os dados?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => atualizarAcrescimo(id)}
        onCancel={hideConfirmationUpdate}
      />

      <CustomModalConfirmCancel
        show={showConfirmationRegisterModal}
        onHide={hideConfirmationRegister}
        title="Atenção"
        body="Tem certeza que deseja cadastrar o acréscimo?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => cadastrarAcrescimo()}
        onCancel={hideConfirmationRegister}
      />

      <CustomModalOnlyMessage
        show={showSuccessUpdateModal}
        onHide={hideSuccessUpdate}
        title="Sucesso"
        body="Os dados foram atualizados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorUpdateModal}
        onHide={hideErrorUpdate}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalConfirmCancel
        show={showConfirmationDeleteModal}
        onHide={hideConfirmationDelete}
        title="Atenção"
        body="Tem certeza que deseja deletar o acréscimo?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => deletarAcrescimo(id)}
        onCancel={hideConfirmationDelete}
      />

      <CustomModalOnlyMessage
        show={showSuccessDeleteModal}
        onHide={hideSuccessDelete}
        title="Sucesso"
        body="Acréscimo deletado com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorDeleteModal}
        onHide={hideErrorDelete}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalInvalidLogin
        show={showInvalidLoginModal}
        onHide={hideInvalidLogin}
      />

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />
    </>
  );
}

export default AcrescimoAdmin;