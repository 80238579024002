import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleRevisaoItensPedido = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: solid 0.5px #1b2029b3;
  `,

  ContainerImage: styled.div`
    width: 60px;
    height: 60px;
  `,

  ContainerInformacoes: styled.div`
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  `,

  ContainerTituloIngredientes: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  `,

  ContainerQuantidadeEPreco: styled.div`
    margin-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
  `,

  NomeProduto: styled.h4`
    color: #1b2029;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
  `,

  IngredientesProduto: styled.h4`
    color: #b4b4b4;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
  `,

  ImageProduto: styled.img`
    max-width: 60px;
    max-height: 60px;
  `,

  PrecoProduto: styled.h4`
    color: #b4b4b4;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
  `,

  PrecoTotalProduto: styled.h4`
    justify-self: flex-end;
    color: #73c883;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  `,

  ContainerQtdeItensAMais: styled.div`
    justify-self: flex-start;
    border-radius: 5px;
    border: 0.5px solid #cbcbcb;
    background: #fff;
    box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.15);
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 5px 5px 5px 5px;
  `,

  IconMinusPlus: styled(FontAwesomeIcon)`
    color: #73c883;
    font-size: 10px;
    &:hover {
      cursor: pointer;
    }
  `,

  TextoQtdeAMais: styled.h6`
    font-size: 12px;
    font-family: Poppins, "sans-serif";
    font-weight: 500;
    margin: 0;
  `,

  ContainerTamanhoProduto: styled.div`
    background-color: #73c883;
    border-radius: 10px;
    width: 25px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 60px;
    left: 30px;
  `,

  Tamanho: styled.h5`
    font-family: Poppins, "sans-serif";
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  `,
};

const {
  ConteudoPrincipal,
  ContainerInformacoes,
  ContainerQuantidadeEPreco,
  NomeProduto,
  IngredientesProduto,
  ImageProduto,
  PrecoProduto,
  PrecoTotalProduto,
  ContainerQtdeItensAMais,
  IconMinusPlus,
  TextoQtdeAMais,
  ContainerTituloIngredientes,
  ContainerTamanhoProduto,
  Tamanho,
  ContainerImage,
} = StyleRevisaoItensPedido;

function RevisaoItensPedido({
  imagemProduto,
  tamanhoProduto,
  nomeProduto,
  ingredientes,
  preco,
  precoTotal,
  qtdeItensAMais,
}) {
  return (
    <>
      <ConteudoPrincipal>
        <ContainerInformacoes>
          <ContainerImage>
            <ImageProduto src={imagemProduto} alt="imgProduto" />
            <ContainerTamanhoProduto>
              <Tamanho>{tamanhoProduto}</Tamanho>
            </ContainerTamanhoProduto>
          </ContainerImage>
          <ContainerTituloIngredientes>
            <NomeProduto>{nomeProduto}</NomeProduto>
            <IngredientesProduto>{ingredientes}</IngredientesProduto>
          </ContainerTituloIngredientes>
          <PrecoProduto>{preco}</PrecoProduto>
        </ContainerInformacoes>
        <ContainerQuantidadeEPreco>
          <ContainerQtdeItensAMais>
            <IconMinusPlus icon={faMinus} />
            <TextoQtdeAMais>{qtdeItensAMais}</TextoQtdeAMais>
            <IconMinusPlus icon={faPlus} />
          </ContainerQtdeItensAMais>
          <PrecoTotalProduto>{precoTotal}</PrecoTotalProduto>
        </ContainerQuantidadeEPreco>
      </ConteudoPrincipal>
    </>
  );
}

export default RevisaoItensPedido;