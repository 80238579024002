import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import style from "./style.module.css";
import {
  faArrowRight,
  faRightFromBracket,
  faUserSecret,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function CustomDropdownAdmin() {
  const navigate = useNavigate();
  const [nomeRestaurante, setNomeRestaurante] = useState("");

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("nome");
    localStorage.removeItem("funcao");
    localStorage.removeItem("imagem_perfil_buffer");
    localStorage.removeItem("imagem_perfil_mimeType");
    localStorage.removeItem("mesa");

    navigate("/loginadministrator");
  };

  useEffect(() => {
    const nome = localStorage.getItem("nome");

    setNomeRestaurante(nome);
  }, []);

  return (
    <Dropdown id={style.containerDropdown}>
      <FontAwesomeIcon icon={faUserAstronaut} id={style.icone} />
      <DropdownButton id={style.btnPrincipal} title={nomeRestaurante}>
        <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon icon={faUserSecret} className={style.leftIcons} />
          </div>
          <Dropdown.Item href="#" className={style.tiposLogin}>
            Olá {nomeRestaurante}!
          </Dropdown.Item>
          <span className={style.space}></span>
        </div>

        <hr />

        {/* <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon icon={faBell} className={style.leftIcons} />
          </div>
          <Dropdown.Item href="#" className={style.tiposLogin}>
            Notificações
          </Dropdown.Item>
          <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
        </div>
        <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon icon={faGear} className={style.leftIcons} />
          </div>
          <Dropdown.Item href="#" className={style.tiposLogin}>
            Configurações
          </Dropdown.Item>
          <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
        </div>
        <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon icon={faPencil} className={style.leftIcons} />
          </div>
          <Dropdown.Item className={style.tiposLogin}>Perfil</Dropdown.Item>
          <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
        </div> */}
        <div className={style.containerIcons}>
          <div className={style.greyContainer}>
            <FontAwesomeIcon
              icon={faRightFromBracket}
              className={style.leftIcons}
            />
          </div>
          <Dropdown.Item
            className={style.tiposLogin}
            onClick={() => handleLogout()}
          >
            Sair
          </Dropdown.Item>
          <FontAwesomeIcon icon={faArrowRight} className={style.rightIcons} />
        </div>
      </DropdownButton>
    </Dropdown>
  );
}

export default CustomDropdownAdmin;
