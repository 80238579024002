import React, { useState, useRef, useEffect } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import StyledGeneral from "../../../../assets/styles/General/GeneralStyle";
import StyledCozinhaAdmin from "../../../../assets/styles/Admin/Contas/Cozinha/StyledCozinhaAdmin";
import HeaderAdmin from "../../Header/Header_Admin.jsx";
import NavBarAdmin from "../../Navbar/NavbarAdmin.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { InputMask } from "@react-input/mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faFloppyDisk,
  faEye,
  faEyeSlash,
  faImage,
  faFile,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import formatarDataISO from "../../../../assets/scripts/Formater/DateFormaterISO.js";
import buscarCep from "../../../../assets/scripts/Request/buscaCep.mjs";
import CustomModalConfirmCancel from "../../../Common/Modal_Confirm_Cancel.jsx";
import CustomModalOnlyMessage from "../../../Common/Modal_Only_Message.jsx";
import CustomModalInvalidLogin from "../../../Common/Modal_Invalid_Login.jsx";
import ReactLoading from "react-loading";

const { DivLoading, StyledPagination } = StyledGeneral;

const {
  ConteudoPrincipal,
  TableContainer,
  Titulo,
  ContainerOutNav,
  GreenText,
  InputPesquisa,
  Search,
  ColumnTitle,
  Subtitulo,
  TableTBody,
  TituloEdit,
  Add,
  CheckBox,
} = StyledCozinhaAdmin;

function CozinhaAdmin() {
  const [usuarios, setUsuarios] = useState([]);
  const navigate = useNavigate();
  const inputFileRef = useRef(null);
  const access_token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const closeButtonRegisterRef = useRef(null);
  const closeButtonUpdateRef = useRef(null);
  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [token_restaurante, setTokenRestaurante] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmar_senha, setConfirmarSenha] = useState("");
  const [aceite_termos, setAceiteTermos] = useState("");
  const [valorFiltro, setValorFiltro] = useState({});
  const [exibirSenha, setType] = useState("password");
  const [btnEye, setEye] = useState(faEyeSlash);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmationRegisterModal, setShowConfirmationRegisterModal] =
    useState(false);
  const [showConfirmationUpdateModal, setShowConfirmationUpdateModal] =
    useState(false);
  const [showSuccessUpdateModal, setShowSuccessUpdateModal] = useState(false);
  const [showSuccessRegisterModal, setShowSuccessRegisterModal] =
    useState(false);
  const [showErrorUpdateModal, setShowErrorUpdateModal] = useState(false);
  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [showErrorDeleteModal, setShowErrorDeleteModal] = useState(false);
  const [showInvalidLoginModal, setShowInvalidLoginModal] = useState(false);
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 5;
  const offset = currentPage * dataPerPage;

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const usersToDisplay =
    usuarios.length > 0
      ? usuarios.slice(offset, offset + dataPerPage).map((item, index) => (
          <tr key={item.id}>
            <td>{index}</td>
            <td>{item.nome}</td>
            <td>{item.email}</td>
            <td>{item.token_restaurante}</td>
            <td>
              <i
                className="fa fa-pen"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => handlePenClick(item.id)}
              ></i>
            </td>
            <td>
              <i
                className="fa fa-trash"
                onClick={() => handleTrashClick(item.id)}
              ></i>
            </td>
          </tr>
        ))
      : "";

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const showConfirmationRegister = () => {
    setShowConfirmationRegisterModal(true);
  };

  const hideConfirmationRegister = () => {
    setShowConfirmationRegisterModal(false);
  };

  const showConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(true);
  };

  const hideConfirmationUpdate = () => {
    setShowConfirmationUpdateModal(false);
  };

  const showSuccessUpdate = () => {
    setShowSuccessUpdateModal(true);
  };

  const hideSuccessUpdate = () => {
    setShowSuccessUpdateModal(false);
  };

  const showSuccessRegister = () => {
    setShowSuccessRegisterModal(true);
  };

  const hideSuccessRegister = () => {
    setShowSuccessRegisterModal(false);
  };

  const showErrorUpdate = () => {
    setShowErrorUpdateModal(true);
  };

  const hideErrorUpdate = () => {
    setShowErrorUpdateModal(false);
  };

  const showConfirmationDelete = () => {
    setShowConfirmationDeleteModal(true);
  };

  const hideConfirmationDelete = () => {
    setShowConfirmationDeleteModal(false);
  };

  const showSuccessDelete = () => {
    setShowSuccessDeleteModal(true);
  };

  const hideSuccessDelete = () => {
    setShowSuccessDeleteModal(false);
  };

  const showErrorDelete = () => {
    setShowErrorDeleteModal(true);
  };

  const hideErrorDelete = () => {
    setShowErrorDeleteModal(false);
  };

  const showInvalidLogin = () => {
    setShowInvalidLoginModal(true);
  };

  const hideInvalidLogin = () => {
    setShowInvalidLoginModal(false);
  };

  const showErrorSearch = () => {
    setShowErrorSearchModal(true);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  const handlePenClick = function (id) {
    setLoading(true);
    limpaCampos();

    const usuarioAtual = usuarios.find((usuario) => usuario.id === id);

    if (usuarioAtual) {
      setId(usuarioAtual.id);
      setNome(usuarioAtual.nome);
      setTokenRestaurante(usuarioAtual.token_restaurante);
      setEmail(usuarioAtual.email);
      setStatus(usuarioAtual.status);
      setAceiteTermos(usuarioAtual.aceite_termos);
    }

    setLoading(false);
  };

  const handleTrashClick = function (id) {
    setId(id);
    showConfirmationDelete();
  };

  const mostraSenha = () => {
    setType(exibirSenha === "text" ? "password" : "text");
    setEye(btnEye === faEyeSlash ? faEye : faEyeSlash);
  };

  const buscarCozinha = () => {
    setLoading(true);

    axios
      .get("https://www.api.qftech.com.br/api/list/listkitchen", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }
          if (closeButtonRegisterRef.current) {
            closeButtonRegisterRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
        } else if (err.response.data !== "Nenhuma cozinha encontrada!") {
          showErrorSearch();
          setErrorMessage(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const dados = {
    nome,
    senha,
    aceite_termos,
    token_restaurante,
    email,
    status,
    confirmar_senha,
  };

  const cadastrarCozinha = () => {
    setLoading(true);

    axios
      .post(
        `https://www.api.qftech.com.br/api/register/registerkitchen`,
        JSON.stringify(dados),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        hideConfirmationRegister();
        showSuccessRegister();

        if (closeButtonRegisterRef.current) {
          closeButtonRegisterRef.current.click();
        }

        buscarCozinha();
      })
      .catch((err) => {
        hideConfirmationRegister();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const atualizarCozinha = (id) => {
    axios
      .put(
        `https://www.api.qftech.com.br/api/update/updatekitchen/${id}`,
        JSON.stringify(dados),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        hideConfirmationUpdate();
        showSuccessUpdate();

        if (closeButtonUpdateRef.current) {
          closeButtonUpdateRef.current.click();
        }

        buscarCozinha();
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }

        hideConfirmationUpdate();
        showErrorUpdate();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletarGarcom = (id) => {
    axios
      .delete(`https://www.api.qftech.com.br/api/delete/deletekitchen/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        hideConfirmationDelete();
        showSuccessDelete();
        buscarCozinha();
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          if (closeButtonUpdateRef.current) {
            closeButtonUpdateRef.current.click();
          }

          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }

        hideConfirmationDelete();
        showErrorDelete();
        setErrorMessage(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filtro = valorFiltro;
  const filtrarCozinha = () => {
    setLoading(true);

    axios
      .post(
        `https://www.api.qftech.com.br/api/filter/filterkitchen`,
        JSON.stringify({ filtro }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
          return;
        }
        if (err.response.data !== "Nenhuma cozinha encontrada!") {
          showErrorSearch();
          setErrorMessage(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    limpaCampos();

    axios
      .get("https://www.api.qftech.com.br/api/list/listkitchen", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor, faça o login e tente novamente!"
        ) {
          showInvalidLogin();

          setTimeout(() => {
            navigate("/loginadministrator");
          }, 5000);
        } else if (err.response.data !== "Nenhuma cozinha encontrada!") {
          showErrorSearch();
          setErrorMessage(err.response.data);
        }

        setUsuarios("");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [access_token, navigate]);

  const limpaCampos = () => {
    setId("");
    setNome("");
    setTokenRestaurante("");
    setEmail("");
    setStatus("");
    setAceiteTermos("");
    setValorFiltro("");
    setSenha("");
    setConfirmarSenha("");
  };

  return (
    <>
      {loading && (
        <DivLoading>
          <ReactLoading
            type="bars"
            color="#fff"
            width="100px"
            height="100px"
            className="loading"
          />
        </DivLoading>
      )}

      <HeaderAdmin />
      <ConteudoPrincipal>
        <NavBarAdmin />
        <ContainerOutNav>
          <Titulo>
            Seja bem-vindo a seção de <GreenText>cozinhas!</GreenText>
          </Titulo>
          <Subtitulo>
            Fazemos o possível para que seu dia possa ficar melhor e mais ágil!
          </Subtitulo>
          <div>
            <InputPesquisa
              type="text"
              placeholder="Faça uma pesquisa!"
              className="form-control"
              onChange={(event) => setValorFiltro(event.target.value)}
              value={valorFiltro}
              autoComplete="new-password"
              id="filtroPesquisa"
            />
            <Search icon={faSearch} onClick={() => filtrarCozinha()} />
            <Add
              icon={faPlus}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              onClick={() => limpaCampos()}
            />
          </div>
          <TableContainer>
            <table className="table table-hover">
              <thead className="table-light">
                <tr>
                  <ColumnTitle scope="col">#</ColumnTitle>
                  <ColumnTitle scope="col">Nome</ColumnTitle>
                  <ColumnTitle scope="col">Email</ColumnTitle>
                  <ColumnTitle scope="col">Token do Restaurante</ColumnTitle>
                  <ColumnTitle scope="col">Editar</ColumnTitle>
                  <ColumnTitle scope="col">Excluir</ColumnTitle>
                </tr>
              </thead>
              <TableTBody className="table-group-divider" id="tbodyCozinha">
                {usuarios.length > 0 ? (
                  usersToDisplay
                ) : (
                  <tr>
                    <td colSpan="6">Nenhuma cozinha encontrada!</td>
                  </tr>
                )}
              </TableTBody>
            </table>
            <StyledPagination
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              breakLabel={"..."}
              pageCount={Math.ceil(usuarios.length / dataPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </TableContainer>
        </ContainerOutNav>
      </ConteudoPrincipal>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edição de Dados</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome_edicao" className="form-label">
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="Cozinha BK"
                      id="nome_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="email_edicao" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      placeholder="johndoe@email.com"
                      id="email_edicao"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      for="token_restaurante_edicao"
                      className="form-label"
                    >
                      Token do Restaurante
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={token_restaurante}
                      onChange={(event) =>
                        setTokenRestaurante(event.target.value)
                      }
                      placeholder="TOKEN"
                      id="token_restaurante_edicao"
                      maxLength={5}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="status_edicao" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="status_edicao"
                      value={status}
                      onChange={handleStatus}
                    >
                      <option value="">Selecione um Status</option>
                      <option value="true">Ativo</option>
                      <option value="false">Inativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Senha</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="senha_edicao" className="form-label">
                      Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={senha}
                      onChange={(event) => setSenha(event.target.value)}
                      placeholder="••••••"
                      id="senha_edicao"
                      autoComplete="new-password"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="confirmar_senha_edicao" className="form-label">
                      Confirmar Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={confirmar_senha}
                      onChange={(event) =>
                        setConfirmarSenha(event.target.value)
                      }
                      placeholder="••••••"
                      id="confirmar_senha_edicao"
                      autoComplete="new-password"
                      required
                    />
                    <FontAwesomeIcon
                      icon={btnEye}
                      onClick={mostraSenha}
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "42px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                ref={closeButtonUpdateRef}
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationUpdate}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cadastro de Cozinha</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <hr />
              <TituloEdit>Informações</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="nome" className="form-label">
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="Cozinha BK"
                      id="nome"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      placeholder="johndoe@email.com"
                      id="email"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="token_restaurante" className="form-label">
                      Token do Restaurante
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={token_restaurante}
                      onChange={(event) =>
                        setTokenRestaurante(event.target.value)
                      }
                      placeholder="TOKEN"
                      id="token_restaurante"
                      maxLength={5}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label for="status_edicao" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="status_edicao"
                      value={status}
                      onChange={handleStatus}
                    >
                      <option value="">Selecione um Status</option>
                      <option value="true">Ativo</option>
                      <option value="false">Inativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Senha</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="senha_edicao" className="form-label">
                      Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={senha}
                      onChange={(event) => setSenha(event.target.value)}
                      placeholder="••••••"
                      id="senha_edicao"
                      autoComplete="new-password"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label for="confirmar_senha_edicao" className="form-label">
                      Confirmar Senha
                    </label>
                    <input
                      type={exibirSenha}
                      className="form-control"
                      value={confirmar_senha}
                      onChange={(event) =>
                        setConfirmarSenha(event.target.value)
                      }
                      placeholder="••••••"
                      id="confirmar_senha_edicao"
                      autoComplete="new-password"
                      required
                    />
                    <FontAwesomeIcon
                      icon={btnEye}
                      onClick={mostraSenha}
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "42px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <TituloEdit>Termos</TituloEdit>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <CheckBox
                    type="checkbox"
                    value={aceite_termos}
                    id="aceite_termos"
                    onChange={(event) => setAceiteTermos(event.target.checked)}
                  />
                  <label for="aceite_termos">
                    Li e concordo com os termos e condições
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                ref={closeButtonRegisterRef}
              >
                <FontAwesomeIcon icon={faX}></FontAwesomeIcon> Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={showConfirmationRegister}
              >
                <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>{" "}
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <CustomModalConfirmCancel
        show={showConfirmationUpdateModal}
        onHide={hideConfirmationUpdate}
        title="Atenção"
        body="Tem certeza que deseja atualizar os dados?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => atualizarCozinha(id)}
        onCancel={hideConfirmationUpdate}
      />

      <CustomModalConfirmCancel
        show={showConfirmationRegisterModal}
        onHide={hideConfirmationRegister}
        title="Atenção"
        body="Tem certeza que deseja cadastrar a cozinha?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => cadastrarCozinha()}
        onCancel={hideConfirmationRegister}
      />

      <CustomModalOnlyMessage
        show={showSuccessUpdateModal}
        onHide={hideSuccessUpdate}
        title="Sucesso"
        body="Os dados foram atualizados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showSuccessRegisterModal}
        onHide={hideSuccessRegister}
        title="Sucesso"
        body="Os dados foram cadastrados com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorUpdateModal}
        onHide={hideErrorUpdate}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalConfirmCancel
        show={showConfirmationDeleteModal}
        onHide={hideConfirmationDelete}
        title="Atenção"
        body="Tem certeza que deseja deletar o usuário?"
        confirmButtonText="Confirmar"
        cancelButtonText="Cancelar"
        onConfirm={() => deletarGarcom(id)}
        onCancel={hideConfirmationDelete}
      />

      <CustomModalOnlyMessage
        show={showSuccessDeleteModal}
        onHide={hideSuccessDelete}
        title="Sucesso"
        body="Usuário deletado com sucesso!"
      />

      <CustomModalOnlyMessage
        show={showErrorDeleteModal}
        onHide={hideErrorDelete}
        title="Atenção"
        body={errorMessage}
      />

      <CustomModalInvalidLogin
        show={showInvalidLoginModal}
        onHide={hideInvalidLogin}
      />

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />
    </>
  );
}

export default CozinhaAdmin;
