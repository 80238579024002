import styled from "styled-components";

const StyleHeader = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    height: 12vh;
    display: grid;
    grid-template-columns: 10% 60% 20%;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  `,

  ImgLogo: styled.img`
    width: 60px;
    height: 30px;
    margin-left: 20px;
  `,

  TituloHeader: styled.h4`
    font-family: Poppins, "sans-serif";
    justify-self: start;
    font-weight: 500;
    font-size: 16px;
  `,
};

export default StyleHeader;