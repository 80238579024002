import StyleSacola from "../../../assets/styles/Pedidos/Sacola/StyleSacola";
import HeaderDetalhes from "../Header/HeaderDetalhes";
import Logo from "../../../assets/img/logoHamburguer.png";
import RevisaoItensPedido from "../../Props/RevisãoItensPedido";
import ImagemTeste from "../../../assets/img/hamburguer2.png";
import { useNavigate } from "react-router-dom";

const {
  ConteudoPrincipal,
  Titulo,
  Subtitulo,
  LinkAdicionarMaisItens,
  ContainerCupom,
  ContainerCodigoCupom,
  InputCupom,
  BtnAddCupom,
  ContainerValorAPagar,
  DefaultContainerValores,
  TextoSubtotalECupom,
  TextoTotal,
  BtnGoPagamento,
  Valor,
  ValorTotal,
} = StyleSacola;

function Sacola() {
  const navigate = useNavigate();

  const addMoreItems = () => {
    navigate("/cliente/cardapio");
  };

  const goToPagamento = () => {
    navigate("/cliente/pagamento");
  };

  return (
    <>
      <HeaderDetalhes imgLogo={Logo} backGroundColor={"#FFA800"} />
      <ConteudoPrincipal>
        <div>
          <Titulo>Sacola</Titulo>
          <Subtitulo>Revise seus itens</Subtitulo>
        </div>
        <RevisaoItensPedido
          imagemProduto={ImagemTeste}
          tamanhoProduto={"P"}
          nomeProduto={"Hamburguer X"}
          ingredientes={"Pão, alface, tomate, ovo, queijo, carne"}
          preco={"R$29,90"}
          precoTotal={"R$32,90"}
          qtdeItensAMais={2}
        />
        <RevisaoItensPedido
          imagemProduto={ImagemTeste}
          tamanhoProduto={"P"}
          nomeProduto={"Hamburguer X"}
          ingredientes={"Pão, alface, tomate, ovo, queijo, carne"}
          preco={"R$29,90"}
          precoTotal={"R$32,90"}
          qtdeItensAMais={2}
        />
        <RevisaoItensPedido
          imagemProduto={ImagemTeste}
          tamanhoProduto={"P"}
          nomeProduto={"Hamburguer X"}
          ingredientes={"Pão, alface, tomate, ovo, queijo, carne"}
          preco={"R$29,90"}
          precoTotal={"R$32,90"}
          qtdeItensAMais={2}
        />
        <RevisaoItensPedido
          imagemProduto={ImagemTeste}
          tamanhoProduto={"P"}
          nomeProduto={"Hamburguer X"}
          ingredientes={"Pão, alface, tomate, ovo, queijo, carne"}
          preco={"R$29,90"}
          precoTotal={"R$32,90"}
          qtdeItensAMais={2}
        />
        <RevisaoItensPedido
          imagemProduto={ImagemTeste}
          tamanhoProduto={"P"}
          nomeProduto={"Hamburguer X"}
          ingredientes={"Pão, alface, tomate, ovo, queijo, carne"}
          preco={"R$29,90"}
          precoTotal={"R$32,90"}
          qtdeItensAMais={2}
        />
        <LinkAdicionarMaisItens onClick={addMoreItems}>
          Adicionar mais itens
        </LinkAdicionarMaisItens>
        <ContainerCupom>
          <ContainerCodigoCupom>
            <InputCupom
              type="text"
              placeholder="Insira seu código promocional"
            />
            <BtnAddCupom>Adicionar</BtnAddCupom>
          </ContainerCodigoCupom>
        </ContainerCupom>
        <ContainerValorAPagar>
          <DefaultContainerValores>
            <TextoSubtotalECupom>Subtotal</TextoSubtotalECupom>
            <Valor>R$119,90</Valor>
          </DefaultContainerValores>
          <DefaultContainerValores>
            <TextoSubtotalECupom>Cupom de desconto</TextoSubtotalECupom>
            <Valor>R$30,00</Valor>
          </DefaultContainerValores>
          <DefaultContainerValores>
            <TextoTotal>Total</TextoTotal>
            <ValorTotal>89,90</ValorTotal>
          </DefaultContainerValores>
          <BtnGoPagamento onClick={goToPagamento}>
            Ir para pagamento
          </BtnGoPagamento>
        </ContainerValorAPagar>
      </ConteudoPrincipal>
    </>
  );
}

export default Sacola;