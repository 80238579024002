import styled from "styled-components";
import ImageBusca from "../../assets/img/busca.png";

const StyledNenhumPedido = {
  ConteudoPrincipal: styled.div`
    margin-top: 100px;
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `,

  Image: styled.img`
    margin-bottom: 20px;
  `,

  Titulo: styled.h4`
    font-family: Poppins, "sans-serif";
    font-weight: 600;
    font-size: 24px;
    color: #1b2029;
  `,

  Texto: styled.h6`
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    font-size: 16px;
    color: #b4b4b4;
  `,
};

const { ConteudoPrincipal, Image, Titulo, Texto } = StyledNenhumPedido;

function NenhumPedido({ texto }) {
  return (
    <>
      <ConteudoPrincipal>
        <Image src={ImageBusca} alt="img-busca" />
        <Titulo>Tudo vazio por aqui!</Titulo>
        <Texto>{texto}</Texto>
      </ConteudoPrincipal>
    </>
  );
}

export default NenhumPedido;