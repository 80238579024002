import { useEffect, useState } from "react";
import styled from "styled-components";

const TextTimer = styled.h2`
  font-family: Poppins, "sans-serif";
  font-size: 20px;
  color: #fff;
  margin-top: 20px !important;
`;

function Timer() {
  const [remainingTime, setRemainingTime] = useState(420); // Defina 5 minutos em segundos

  const calculateTime = () => {
    const remainingMinutes = Math.floor(remainingTime / 60);
    const remainingSeconds = remainingTime % 60;
    return { minutes: remainingMinutes, seconds: remainingSeconds };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        }
        return prevTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const { minutes, seconds } = calculateTime();

  return (
    <>
      <TextTimer>
        {minutes} minutos {seconds} segundos
      </TextTimer>
    </>
  );
}

export default Timer;