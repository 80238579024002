import styled from "styled-components";
import Img_Administrador from "../../../img/img_administrador.png";
import Img_Fundo from "../../../img/ImgDeFundo.png";
import "../../../fonts/Raleway";
import "../../../fonts/Inter";
import "../../../fonts/Poppins";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledAdministradorLogin = {
  ConteudoPrincipal: styled.div`
    background-image: url(${Img_Fundo});
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin: 0;
    padding: 0;
  `,

  DivInputs: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    align-content: center;
  `,

  DivSenha: styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
  `,

  DivNome: styled.div`
    width: 100%;
    margin: 15px 0px 4vh;
    display: flex;
    align-items: center;
    justify-content: start;
  `,

  Icones: styled.div`
    width: 30px;
    color: #04a768;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    img {
      width: 24px;
      object-fit: cover;
    }
  `,

  Imagens: styled.div`
    display: grid;
    justify-content: start;
    grid-template-rows: 1fr 2fr;
    height: 100vh;
  `,

  ImgAdministrador: styled.div`
    text-align: center;
    background-image: url(${Img_Administrador});
    background-repeat: no-repeat;
    background-size: 100%;
    width: 55vh;
    height: 55vh;
    margin-left: 25vh;
    margin-top: 23vh;
  `,

  Formulario: styled.div`
    display: grid;
    justify-content: center;
    justify-items: center;
    align-content: center;
    height: 100vh;
  `,

  DivDoFrom: styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-radius: 10px;
    height: 80vh;
    width: 33vw;
  `,

  Titulo: styled.p`
    text-align: center;
    color: #26874e;
    font-style: normal;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    font-size: 35px;
    margin-bottom: 6px;
  `,

  Lbl: styled.label`
    font-weight: 200;
    font-size: 18px;
    color: #4da768;
    font-family: "Poppins", sans-serif;
  `,

  Form: styled.form`
    display: grid;
    grid-template-rows: 7;
    width: 45vh;
    margin-top: 3vh;
    margin-right: 1vw;
  `,

  TxtName: styled.input`
    width: 100%;
    height: 34px;
    border-radius: 5px;
    padding: 10px 15px;
    border: 1px solid #26874e;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;

    &:focus {
      border: 2px solid #26874e;
      font-weight: 600;
      outline: none;
    }
  `,

  TxtSenha: styled.input`
    width: 100%;
    height: 34px;
    border-radius: 5px;
    padding: 10px 45px 10px 15px;
    margin: 15px 0 15px 0;
    border: 1px solid #26874e;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;

    &:focus {
      border: 2px solid #26874e;
      font-weight: 600;
      outline: none;
    }
  `,

  BtnLogar: styled.button`
    background-color: #26874e;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    color: white;
    margin: 8px 0;
    margin-left: -2vh;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 109.44px;
    height: 37px;
    justify-self: center;

    &:hover {
      background-color: #99e89d;
      color: #1b2029;
      transition: all 0.5s;
    }
  `,

  IconeAdministrador: styled.div`
    width: 50px;
    margin-bottom: 10px;
  `,

  Legenda: styled.p`
    color: #969696;
    font-family: "Poppins", sans-serif;
  `,

  StyledFontAwesomeIcon: styled(FontAwesomeIcon)`
    color: #26874e;
    position: absolute;
    right: 15px;
    cursor: pointer;
  `,
};

export default StyledAdministradorLogin;