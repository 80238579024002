import StyleIncrementos from "../../../assets/styles/Pedidos/Detalhes/StyleIncrementos";
import HeaderDetalhes from "../Header/HeaderDetalhes";
import Logo from "../../../assets/img/logoHamburguer.png";
import IncrementosProps from "../../Props/IncrementosProps";
import ImgCoca from "../../../assets/img/coca.png";
import { useNavigate } from "react-router-dom";
//import { useState } from "react"

const { ConteudoPrincipal, Titulo, Btn } = StyleIncrementos;

function Incrementos() {
  // const [logo, setLogo] = useState()
  // const [backColor, setBackColor] = useState()
  // const [imgAcrescimo, setImgAcrescimo] = useState()
  // const [nomeAcrescimo, setNomeAcrescimo] = useState()
  // const [pesoAcrescimo, setPesoAcrescimo]  = useState()
  // const [precoAcrescimo, setPrecoAcrescimo] = useState()

  const navigate = useNavigate();

  const changePage = () => {
    navigate("/cliente/cardapio");
  };

  return (
    <>
      <HeaderDetalhes imgLogo={Logo} backGroundColor={"#FFB629"} />
      <ConteudoPrincipal>
        <Titulo>
          Gostaria de incrementar algo no <br /> seu pedido?
        </Titulo>
        <IncrementosProps
          imagem={ImgCoca}
          nome={"Coca-Cola Zero Lata"}
          peso={"330ml"}
          preco={"R$5,00"}
        />
        <IncrementosProps
          imagem={ImgCoca}
          nome={"Coca-Cola Zero Lata"}
          peso={"330ml"}
          preco={"R$5,00"}
        />
        <IncrementosProps
          imagem={ImgCoca}
          nome={"Coca-Cola Zero Lata"}
          peso={"330ml"}
          preco={"R$5,00"}
        />
        <Btn onClick={changePage}>Agora não</Btn>
      </ConteudoPrincipal>
    </>
  );
}

export default Incrementos;