import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleHeaderDetalhes = {
  ConteudoPrincipal: styled.header`
    background-color: ${(props) => props.backGroundColor || "#4FAE6C"};
    width: 100%;
    height: 15vh;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    justify-content: start;
    align-items: center;
  `,

  Logo: styled.img`
    justify-self: center;
    max-width: 108px;
    max-height: 108px;
  `,

  Icon: styled(FontAwesomeIcon)`
    color: #fff;
    margin-left: 25px;
    &:hover {
      cursor: pointer;
    }
  `,
};

export default StyleHeaderDetalhes;