import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyleClienteCardapio = {
  ConteudoPrincipal: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
  `,

  CabecalhoRest: styled.div`
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  LogoRest: styled.img`
    border-radius: 10px;
    max-width: 150px;
    max-height: 150px;
  `,

  ContainerInfoRest: styled.div`
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-left: 15px;

    @media (min-width: 768px) {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      margin-right: 20px;
    }
  `,

  SubContainerInfoRest: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    flex-wrap: wrap;
  `,

  Icons: styled(FontAwesomeIcon)`
    color: #b4b4b48f;
    font-size: 20px;
  `,

  DefaultContainer: styled.div`
    width: 100%;
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    overflow-x: auto;
    white-space: nowrap;

    @media (min-width: 768px) {
      justify-content: center;
    }

    ::-webkit-scrollbar {
      height: 3px;
      background-color: #f8f4f4;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #73c883;
      border-radius: 7px;
    }

    ::-webkit-scrollbar-track {
      background-color: #cbcbcb;
      margin: 110px;
      border-radius: 7px;
    }
  `,

  ContainerEndereco: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
  `,

  TextoEndereco: styled.h6`
    color: #b4b4b4;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    margin: 0;
    margin-left: 10px;
    font-size: 12px;
    word-wrap: break-word;
    width: 200px;
  `,

  DefaultTitle: styled.h6`
    color: #b4b4b4;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    margin: 0;
    margin-left: 10px;
    font-size: 12px;
    word-wrap: break-word;
  `,

  Container: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100vw;
    }
  `,

  ContainerChamarGarcom: styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    margin-right: 10px;
  `,

  BtnChamarGarcom: styled.button`
    height: 80px;
    width: auto;
    margin-top: 5px;
    margin-right: 15px;
    border: none;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    border-radius: 5px;
    background: #73c883;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #fff;
    font-size: 14px;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,

  IconSino: styled(FontAwesomeIcon)`
    font-size: 24px;
    margin-bottom: 5px;
  `,

  BtnDefault: styled.button`
    margin-top: 20px;
    margin-left: 15px;
    margin-bottom: 15px;
    border: none;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    border-radius: 5px;
    background: #73c883;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    transition: all 0.5s;
    height: 35px;
    width: 100%;

    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }

    @media (min-width: 768px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  `,

  ContainerProdutos: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  `,

  TituloCategoria: styled.h4`
    font-family: Poppins, "sans-serif";
    font-weight: 600;
    font-size: 18px;
    margin-top: 15px;
  `,

  ContainerMesa: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  `,

  InputMesa: styled.input`
    width: 90%;
    height: 100%;
    background: white;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border: 0.5px #cbcbcb solid;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #b4b4b4;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 500;
    letter-spacing: 0.01px;
    word-wrap: break-word;
    text-align: center;

    @media (min-width: 768px) {
      width: 80%;
    }
  `,
};

export default StyleClienteCardapio;