import HeaderDefault from "../Header/HeaderDefault";
import StyleSucessMoney from "../../../assets/styles/Pedidos/Sucess/StyleSucessMoney";
import ImgMoney from "../../../assets/img/aguardando_pagamento.png";
import { useNavigate } from "react-router-dom";

const { ConteudoPrincipal, ImagePix, Title, Texto, BtnCancelar } =
  StyleSucessMoney;

function SucessMoney() {
  const navigate = useNavigate();

  const backToHome = () => {
    navigate("/cliente/cardapio");
  };

  return (
    <>
      <HeaderDefault
        backGroundColor={"#4FAE6C"}
        height={"10vh"}
        nomeRestaurante={"QUICKFOOD"}
      />
      <ConteudoPrincipal>
        <ConteudoPrincipal>
          <ImagePix src={ImgMoney} alt="imgPix" />
          <Title>Vá até o caixa e pague seu pedido!</Title>
          <Texto>
            Não se esqueça de informar ao atendente seu nome e número de mesa!
          </Texto>
          <Texto>Aguardando confirmação do pagamento...</Texto>
          <BtnCancelar onClick={backToHome}>Cancelar</BtnCancelar>
        </ConteudoPrincipal>
      </ConteudoPrincipal>
    </>
  );
}

export default SucessMoney;