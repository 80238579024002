import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeaderStyle = {
  Cabecalho: styled.header`
    position: sticky;
    width: 100vw;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #e1fae2;
    color: #1b2029d5;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 2;

    @media screen and (max-width: 735px) {
      display: none;
    }
  `,

  ImgLogo: styled.img`
    width: 91px;
    transition: all 0.5s;

    &:hover {
      transition: all 0.5s;
      filter: drop-shadow(0 0 2em #26874e);
      cursor: pointer;
    }

    @media screen and (max-width: 1210px) {
      width: 80px;
    }

    @media screen and (max-width: 1060px) {
      width: 70px;
    }
  `,

  Pages: styled.h4`
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    cursor: pointer;

    &:hover {
      color: #26874e;
    }

    &.active {
      color: #26874e;
    }

    @media screen and (max-width: 1210px) {
      font-size: 16px;
    }

    @media screen and (max-width: 1060px) {
      font-size: 14px;
    }
  `,

  BtnBombar: styled.button`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none;
    background: #4da768;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 18px;
    transition: all 0.5s;
    font-weight: 500;

    &:hover {
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }

    @media screen and (max-width: 1210px) {
      font-size: 16px;
    }

    @media screen and (max-width: 1060px) {
      font-size: 14px;
    }

    @media screen and (max-width: 941px) {
      display: none;
    }
  `,

  Login: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,

  UserLoginIcons: styled(FontAwesomeIcon)`
    color: #1b2029d5;
    margin-right: 10px;
    font-size: 20px;
    text-align: center;
  `,

  GreenText: styled.span`
    color: #26874e;
  `,
};

export default HeaderStyle;