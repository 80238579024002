import React, { useState, useEffect } from "react";
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import ExibicaoStyle from "../../../assets/styles/Cardapio/Exibição/ExibicaoStyle";
import HeaderAdmin from "../../Admin/Header/Header_Admin";
import ItemCardapio from "../../Props/ItemCardapio";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomModalInvalidLogin from "../../Common/Modal_Invalid_Login";
import CustomModalOnlyMessage from "../../Common/Modal_Only_Message.jsx";
import formatarDinheiro from "../../../assets/scripts/Formater/MoneyFormater";

const {
  ConteudoPrincipal,
  Titulo,
  ContainerPesquisa,
  InputPesquisa,
  Icon,
  ContainerItensCardapio,
  BtnAdicionarNovoProduto,
  StyleLink,
  Search,
} = ExibicaoStyle;

function Exibicao() {
  const navigate = useNavigate();
  const access_token = localStorage.getItem("token");
  const [valorFiltro, setValorFiltro] = useState({});
  const [itensCardapio, setItensCardapio] = useState([]);
  const [showInvalidLoginModal, setShowInvalidLoginModal] = useState(false);
  const [showErrorSearchModal, setShowErrorSearchModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const showInvalidLogin = () => {
    setShowInvalidLoginModal(true);
  };

  const hideInvalidLogin = () => {
    setShowInvalidLoginModal(false);
  };

  const showErrorSearch = () => {
    setShowErrorSearchModal(true);
  };

  const hideErrorSearch = () => {
    setShowErrorSearchModal(false);
  };

  useEffect(() => {
    axios
      .get("https://www.api.qftech.com.br/api/list/listproduct", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setItensCardapio(response.data);
      })
      .catch((err) => {
        if (
          err.response.data ===
          "Login inválido! Por favor faça o login e tente novamente!"
        ) {
          showInvalidLogin();

          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else if (err.response.data !== "Nenhum produto encontrada!") {
          console.log(err);
          showErrorSearch();
          setErrorMessage(err.response.data);
        }
      });
  }, [access_token, navigate]);

  const renderizarItensCardapio = () => {
    return itensCardapio.map((item, index) => {
      const uint8Array = new Uint8Array(item.buffer.data);
      const blob = new Blob([uint8Array], { type: item.mimeType });
      const imageUrl = URL.createObjectURL(blob);

      return (
        <ItemCardapio
          key={index}
          imgAlimento={imageUrl}
          nomeAlimento={item.nome_produto}
          valorAlimento={formatarDinheiro(
            parseFloat(item.preco.$numberDecimal)
          )}
          descricaoAlimento={
            item.descricao_produto === ""
              ? "Sem descrição..."
              : item.descricao_produto
          }
          categoriaAlimento={item.categoria}
          ativo={item.status === "Ativo" ? true : false}
          idProduto={item.id}
        />
      );
    });
  };

  const filtro = valorFiltro;

  const filtrarProduto = () => {
    axios
      .post(
        `https://www.api.qftech.com.br/api/filter/filterproduct`,
        JSON.stringify({ filtro }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setItensCardapio(response.data);
      })
      .catch((err) => {
        console.log(err);
        showErrorSearch();
        setErrorMessage(err.response.data);
      });
  };

  return (
    <>
      <HeaderAdmin />

      <ConteudoPrincipal>
        <Titulo>Cardápio Cadastrado</Titulo>

        <ContainerPesquisa>
          <InputPesquisa
            type="text"
            placeholder="O que você procura?"
            onChange={(event) => setValorFiltro(event.target.value)}
          />
          <Search icon={faSearch} onClick={() => filtrarProduto()} />
        </ContainerPesquisa>

        <ContainerItensCardapio>
          {renderizarItensCardapio()}
        </ContainerItensCardapio>
        <StyleLink to={"/admin/cadastrar_cardapio"}>
          <BtnAdicionarNovoProduto>
            Adicionar Novo Produto
            <Icon icon={faArrowRight} />
          </BtnAdicionarNovoProduto>
        </StyleLink>
      </ConteudoPrincipal>

      <CustomModalInvalidLogin
        show={showInvalidLoginModal}
        onHide={hideInvalidLogin}
      />

      <CustomModalOnlyMessage
        show={showErrorSearchModal}
        onHide={hideErrorSearch}
        title="Atenção"
        body={errorMessage}
      />
    </>
  );
}

export default Exibicao;