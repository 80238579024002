import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const EdicaoCardapioStyle = {
  ConteudoPrincipal: styled.div`
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,

  Titulo: styled.h2`
    font-family: Raleway, "sans-serif";
    font-weight: 700;
    text-shadow: 0.1em 0.1em 0.2em #1b202927;
    margin: 0;
    margin-top: 5vh;
  `,

  Subtitulo: styled.h4`
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    margin: 0;
    text-align: center;
    font-size: 16px;
  `,

  SubtituloImagem: styled.h4`
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    margin: 0;
    text-align: center;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
  `,

  ContainerInformacoesProduto: styled.div`
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vw;
  `,

  ContainerImagens: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
    margin: 0;
  `,

  ContainerInputsProduto: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
  `,

  ContainerObservacoesProduto: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    height: 100%;
  `,

  UploadImageContainer: styled.div`
    margin-top: 2vh;
    margin-bottom: 4vh;
    height: 300px;
    width: 350px;
    border-radius: 10px;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 5px;
    background-color: rgba(0, 110, 255, 0.041);
  `,

  UploadImageConteudo: styled.div`
    flex: 1;
    width: 100%;
    border: 2px dashed #73c883;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  `,

  DescricaoImage: styled.label`
    margin-top: 10px;
    background-color: #99e89d86;
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: black;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,

  UploadInput: styled.input`
    display: none;
  `,

  BigIcon: styled(FontAwesomeIcon)`
    font-size: 48px;
    color: #4da768;
    margin-bottom: 5px;
  `,

  Icon: styled(FontAwesomeIcon)`
    font-size: 20px;
    margin-left: 8px;
  `,

  SmallIcon: styled(FontAwesomeIcon)`
    font-size: 18px;
    color: #4da768;
  `,

  DefaulLabels: styled.h4`
    font-family: Poppins, "sans-serif";
    font-size: 16px;
    font-weight: 400;
    margin-top: 2vh;
  `,

  DefaultInput: styled.input`
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0.5px solid #99e89d;
    border-radius: 5px;
    padding-left: 10px;
    width: 300px;
    height: 35px;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    font-size: 14px;
    &:focus {
      outline: none;
      border: 1px solid #4da768;
    }
  `,

  SelecaoCategorias: styled.select`
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0.5px solid #99e89d;
    border-radius: 5px;
    padding-left: 10px;
    width: 300px;
    height: 35px;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    font-size: 14px;
    &:focus {
      outline: none;
      border: 1px solid #4da768;
    }
  `,

  Categorias: styled.option``,

  LongInput: styled.textarea`
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0.5px solid #99e89d;
    border-radius: 5px;
    padding-left: 10px;
    width: 300px;
    height: 100px;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    font-size: 14px;
    &:focus {
      outline: none;
      border: 1px solid #4da768;
    }
  `,

  DefaultBtn: styled.button`
    background-color: #4da768;
    border: none;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: Poppins, "sans-serif";
    transition: all 0.5s;
    font-weight: 500;
    margin-top: 10px;
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,

  BtnConcluir: styled.button`
    align-self: flex-end;
    margin-right: 30px;
    margin-top: 30px;
    background-color: #4da768;
    border: none;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: Poppins, "sans-serif";
    transition: all 0.5s;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,

  Images: styled.img`
    cursor: pointer;
    width: 326px;
    height: 213px;
    border-radius: 10px;
    padding: 5px;
  `,

  ImageSelected: styled.img`
    cursor: pointer;
    width: 326px;
    position: absolute;
    height: 213px;
    border-radius: 10px;
  `,
};

export default EdicaoCardapioStyle;