import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const CardAdminStyle = {
  Principal: styled.div`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 300px;
    height: 150px;
    border-radius: 5px;
    margin-right: 20px;
    background: #fff;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,

  Icon: styled(FontAwesomeIcon)`
    color: #7da768;
    margin-right: 50px;
    font-size: 36px;
  `,

  ContainerTextos: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  `,

  Titulo: styled.h6`
    color: #1b2029d5;
    font-family: "Poppins", sans-serif;
    margin: 0;
    text-align: right;
  `,

  Valor: styled.h4`
    color: #1b2029d5;
    font-family: "Poppins", sans-serif;
  `,
};

function CardAdmin({ valor, titulo, icone }) {
  return (
    <>
      <CardAdminStyle.Principal>
        <CardAdminStyle.Icon icon={icone} />
        <CardAdminStyle.ContainerTextos>
          <CardAdminStyle.Titulo>{titulo}</CardAdminStyle.Titulo>
          <CardAdminStyle.Valor>{valor}</CardAdminStyle.Valor>
        </CardAdminStyle.ContainerTextos>
      </CardAdminStyle.Principal>
    </>
  );
}

export default CardAdmin;