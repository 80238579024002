function formatarDinheiro(valor) {
  if (typeof valor !== "number") {
    return "Não é um número válido";
  }

  const valorFormatado = valor.toFixed(2);

  return `R$${valorFormatado}`;
}

module.exports = formatarDinheiro;