import ForgotPassStyle from "../../assets/styles/ForgotPass/ForgotPassStyle.js";
import Img_Fundo from "../../assets/img/ImgDeFundo.png";
import Img_Logo from "../../assets/img/logo.png";
import Img_Pass from "../../assets/img/forgot-password.png";
import Img_Alert from "../../assets/img/alert-icon.png";
import Img_Email from "../../assets/img/email.png";
import Img_Arrow from "../../assets/img/arrow.png";
import { useState } from "react";
import axios from "axios";

const {
  Principal,
  ContainerImagens,
  ImgFundo,
  ImgLogo,
  ImgCadastro,
  ImgEmail,
  ContainerEmail,
  ContainerFormulario,
  Alerta,
  Titulo,
  Texto,
  Formulario,
  Labels,
  Inputs,
  Cadastrar,
  ImgArrow,
  ContainerBack,
  BackToLogin,
} = ForgotPassStyle;

function ForgotPassBody() {
  const [email, setEmail] = useState();

  const handleClick = (event) => {
    event.preventDefault();

    const dados = {
      email,
    };

    axios
      .put("https://www.api.qftech.com.br/api/send/forgotpassword", dados)
      .then((response) => {
        alert("Email enviado com sucesso!");
        console.log(response);
      })
      .catch((error) => {
        alert(error.response.data);
        console.log(error);
      });
  };

  return (
    <Principal>
      <ContainerImagens>
        <ImgFundo src={Img_Fundo} />
        <ImgLogo src={Img_Logo} />
        <ImgCadastro src={Img_Pass} />
      </ContainerImagens>

      <ContainerFormulario>
        <Alerta src={Img_Alert} />
        <Titulo>ESQUECEU SUA SENHA?</Titulo>
        <Texto>
          Escreva seu email e enviaremos uma nova <br /> senha para você!
        </Texto>
        <Formulario>
          <Labels>EMAIL:</Labels>
          <ContainerEmail>
            <ImgEmail src={Img_Email} />
            <Inputs
              type="email"
              autoFocus
              placeholder="quickfood@email.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            ></Inputs>
          </ContainerEmail>
          <Cadastrar onClick={handleClick}>Enviar</Cadastrar>
        </Formulario>
        <ContainerBack>
          <ImgArrow src={Img_Arrow} />
          <BackToLogin>Back to login</BackToLogin>
        </ContainerBack>
      </ContainerFormulario>
    </Principal>
  );
}

export default ForgotPassBody;