import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ExibicaoStyle = {
  ConteudoPrincipal: styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `,

  ContainerItensCardapio: styled.div`
    width: 100vw;
    height: auto;
    margin-top: 6vh;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
  `,

  Titulo: styled.h2`
    font-family: Raleway, "sans-serif";
    font-weight: 700;
    text-shadow: 0.1em 0.1em 0.2em #1b202927;
    margin-top: 5vh;
  `,

  ContainerPesquisa: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
  `,

  InputPesquisa: styled.input`
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 0.5px solid #99e89d;
    border-radius: 5px;
    padding-left: 10px;
    width: 400px;
    height: 40px;
    font-family: Poppins, "sans-serif";
    font-weight: 400;
    font-size: 14px;
    &:focus {
      outline: none;
      border: 1px solid #4da768;
    }
  `,

  Icon: styled(FontAwesomeIcon)`
    margin-left: 10px;
  `,

  StyleLink: styled(Link)`
    align-self: flex-end;
    margin-right: 30px;
  `,

  BtnAdicionarNovoProduto: styled.button`
    background-color: #4da768;
    border: none;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    font-family: Poppins, "sans-serif";
    transition: all 0.5s;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,

  Search: styled(FontAwesomeIcon)`
    position: relative;
    left: -1%;
    top: 0;
    background-color: rgb(77, 167, 104);
    color: white;
    padding: 12px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: rgb(122 195 144);
      transition: all 0.5s;
    }
  `,
};

export default ExibicaoStyle;