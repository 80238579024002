import { faCcVisa, faPix } from "@fortawesome/free-brands-svg-icons";
import StylePagamento from "../../../assets/styles/Pedidos/Pagamento/StylePagamento";
import { faMoneyBill, faPen } from "@fortawesome/free-solid-svg-icons";
import HeaderDetalhes from "../Header/HeaderDetalhes";
import Logo from "../../../assets/img/logoHamburguer.png";
import ImgMasterCard from "../../../assets/img/mastercard.png";
import RadioButton2 from "../../Props/RadioButton2";
import BtnAddCartao from "../../Props/Cartao/BtnAddCartao";
import { useRef, useState } from "react";
import { InputMask } from "@react-input/mask";
import ImgCheckIcon from "../../../assets/img/checkIcon.png";
import ContainerAddCartao from "../../Props/Cartao/ContainerAddCartao";
import CartaoCadastrado from "../../Props/Cartao/CartaoCadastrado";
import { useNavigate } from "react-router-dom";

const {
  ConteudoPrincipal,
  ContainerNome,
  Title,
  InputNome,
  ContainerFormasDePagamento,
  ContainerIcons,
  IconsPagamento,
  ContainerPagamento,
  IconMasterCard,
  ContainerTipoPagamento,
  TextoTipoPagamento,
  ContainerDirectionRow,
  ContainerConfirmarPedido,
  TextosConfirmacao,
  ValorConfirmacao,
  ContainerTextoEPreco,
  SpaceContainer,
  ContainerCpfNaNota,
  ContainerIconETexto,
  ContainerCpf,
  InputCpf,
  BtnFinalizarPedido,
  TextoCpf,
  CheckIcon,
  BtnEditCpf,
  IconEditCpf,
} = StylePagamento;

function Pagamento() {
  const [metodoPagamento, setMetodoPagamento] = useState("");
  const [showModalAddCartao, setShowModalAddCartao] = useState(false);
  const [IsHaveACard, setIsHaveACard] = useState(false);
  const navigate = useNavigate();

  const selectPix = () => {
    setMetodoPagamento("pix");
  };

  const selectCard = () => {
    setMetodoPagamento("cartao");
  };

  const selectMoney = () => {
    setMetodoPagamento("dinheiro");
  };

  const callModalCadastrarCartao = () => {
    setShowModalAddCartao(!showModalAddCartao);
  };

  const verifyHaveACard = () => {
    setShowModalAddCartao(!showModalAddCartao);
    setIsHaveACard(true);
  };

  const finalizarPedido = () => {
    if (metodoPagamento === "pix") {
      navigate("/cliente/pagamento_pix");
    } else if (metodoPagamento === "dinheiro") {
      navigate("/cliente/pagamento_dinheiro");
    } else if (metodoPagamento === "cartao") {
      navigate("/cliente/pagamento_cartao");
    }
  };

  const inputRef = useRef(null);
  const focusInputCpf = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <HeaderDetalhes imgLogo={Logo} backGroundColor={"#FFB629"} />

      <ConteudoPrincipal containerAddCard={showModalAddCartao}>
        <ContainerNome>
          <Title>Como podemos te chamar?</Title>
          <InputNome type="text" placeholder="John Doe" />
        </ContainerNome>
        <ContainerFormasDePagamento>
          <Title>Formas de pagamento</Title>
          <ContainerIcons>
            <IconsPagamento icon={faPix} color="#77B6A8" />
            <IconsPagamento icon={faCcVisa} color="#00569F" />
            <IconMasterCard src={ImgMasterCard} alt="mastercard" />
            <IconsPagamento icon={faMoneyBill} color="#62ad36" />
          </ContainerIcons>
        </ContainerFormasDePagamento>
        <ContainerPagamento>
          <Title>Métodos de pagamento</Title>
          <ContainerTipoPagamento>
            <ContainerDirectionRow>
              <RadioButton2 evento={selectPix} />
              <TextoTipoPagamento>Pix</TextoTipoPagamento>
            </ContainerDirectionRow>
          </ContainerTipoPagamento>
          <ContainerTipoPagamento>
            <ContainerDirectionRow>
              <RadioButton2 evento={selectCard} />
              <TextoTipoPagamento>Cartão de crédito/débito</TextoTipoPagamento>
            </ContainerDirectionRow>
            {metodoPagamento === "cartao" && (
              <BtnAddCartao evento={callModalCadastrarCartao} />
            )}
            {IsHaveACard === true && (
              <CartaoCadastrado
                imgBandeira={ImgMasterCard}
                funcaoEditarDados={callModalCadastrarCartao}
              />
            )}
          </ContainerTipoPagamento>
          <ContainerTipoPagamento>
            <ContainerDirectionRow>
              <RadioButton2 evento={selectMoney} />
              <TextoTipoPagamento>Dinheiro</TextoTipoPagamento>
            </ContainerDirectionRow>
          </ContainerTipoPagamento>
        </ContainerPagamento>
        <ContainerConfirmarPedido>
          <Title>Confirme seu pedido</Title>
          <SpaceContainer>
            <ContainerTextoEPreco>
              <TextosConfirmacao color="#B4B4B4;">Subtotal</TextosConfirmacao>
              <ValorConfirmacao color="#B4B4B4;">R$119,90</ValorConfirmacao>
            </ContainerTextoEPreco>
            <ContainerTextoEPreco>
              <TextosConfirmacao color="#B4B4B4;">
                Cupom de desconto
              </TextosConfirmacao>
              <ValorConfirmacao color="#B4B4B4;">- R$0,00</ValorConfirmacao>
            </ContainerTextoEPreco>
            <ContainerTextoEPreco>
              <TextosConfirmacao>Total</TextosConfirmacao>
              <ValorConfirmacao>R$119,90</ValorConfirmacao>
            </ContainerTextoEPreco>
          </SpaceContainer>
        </ContainerConfirmarPedido>
        <ContainerCpfNaNota>
          <ContainerIconETexto>
            <CheckIcon src={ImgCheckIcon} alt="checkIcon" />
            <TextoCpf>Deseja colocar seu CPF na nota?</TextoCpf>
          </ContainerIconETexto>
          <ContainerCpf>
            <InputMask
              mask="___.___.___-__"
              replacement={{ _: /\d/ }}
              component={InputCpf}
              ref={inputRef}
              type="text"
              //value={cpf}
              //onChange={(event) => setCpf(event.target.value)}
              placeholder="000.000.000-00"
            />
            <BtnEditCpf onClick={focusInputCpf}>
              <IconEditCpf icon={faPen} />
            </BtnEditCpf>
          </ContainerCpf>
        </ContainerCpfNaNota>
        <BtnFinalizarPedido onClick={finalizarPedido}>
          Finalizar Pedido
        </BtnFinalizarPedido>
      </ConteudoPrincipal>
      {showModalAddCartao === true && (
        <ContainerAddCartao
          funcaoClose={callModalCadastrarCartao}
          funcaoCadastrar={verifyHaveACard}
        />
      )}
    </>
  );
}

export default Pagamento;