import styled from "styled-components";

const StyleRadioButton = {
  ContainerButton: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
  `,

  Check: styled.input`
    &:checked {
      background-color: #7da768;
    }
  `,
};

const { ContainerButton, Check } = StyleRadioButton;

function RadioButton({ evento }) {
  return (
    <>
      <ContainerButton className="form-check form-check-inline">
        <Check
          className="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio1"
          value="option1"
          onClick={evento}
        />
      </ContainerButton>
    </>
  );
}

export default RadioButton;