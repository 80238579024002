import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderStyle from "../../assets/styles/Header/HeaderStyle";
import Logo from "../../assets/img/logo.png";
import DropdownLogin from "../Home/DropdownLogin/Dropdown";
import DropdownCadastro from "../Home/DropdownCadastro/Dropdown";
import ResponsiveHeader from "../Home/ResponsiveHeader/ResponsiveHeader";

const { Cabecalho, ImgLogo, Pages, BtnBombar } = HeaderStyle;

function HeaderPrincipal() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    if (route === "/contato" || route === "/sobrenos") {
      const targetRoute = "/";
      const targetSectionId = route === "/contato" ? "contato" : "sobre";
      localStorage.setItem("targetSection", `${targetSectionId}`);

      const targetSection = document.getElementById(targetSectionId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }

      route = targetRoute;
    }

    navigate(route);
  };

  return (
    <>
      <ResponsiveHeader />
      <Cabecalho>
        <Pages
          className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
          onClick={() => handleNavigation("/")}
        >
          <ImgLogo src={Logo} alt="logo" />
        </Pages>
        <Pages
          className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
          onClick={() => handleNavigation("/")}
        >
          Home
        </Pages>
        <Pages
          className={`nav-link ${
            location.pathname === "/sobrenos" ? "active" : ""
          }`}
          onClick={() => handleNavigation("/sobrenos")}
        >
          Sobre nós
        </Pages>
        <Pages
          className={`nav-link ${
            location.pathname === "/contato" ? "active" : ""
          }`}
          onClick={() => handleNavigation("/contato")}
        >
          Contato
        </Pages>
        <Pages
          className={`nav-link ${
            location.pathname === "/planos" ? "active" : ""
          }`}
          onClick={() => handleNavigation("/planos")}
        >
          Planos
        </Pages>
        <BtnBombar onClick={() => handleNavigation("/cadastrorestaurante")}>
          Faça seu restaurante bombar!
        </BtnBombar>
        <DropdownLogin />
        <DropdownCadastro />
      </Cabecalho>
    </>
  );
}

export default HeaderPrincipal;
