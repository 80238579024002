import styled from "styled-components";

const StyleIncrementosProps = {
  ConteudoPrincipal: styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  `,

  Container: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    margin-top: 15px;
  `,

  ContainerImagem: styled.div`
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
  `,

  ContainerInformacoes: styled.div`
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,

  Images: styled.img``,

  Nome: styled.h4`
    color: #1b2029;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 700;
  `,

  Peso: styled.h4`
    color: #b4b4b4;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    margin-top: 5px !important;
  `,

  Preco: styled.h4`
    color: #1b2029;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px !important;
  `,

  BtnAdicionar: styled.button`
    justify-self: end;
    border: none;
    border-radius: 5px;
    background: #73c883;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: Poppins, "sans-serif";
    padding: 3px 15px;
    transition: all 0.5s;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }
  `,
};

const {
  ConteudoPrincipal,
  ContainerImagem,
  ContainerInformacoes,
  Images,
  Nome,
  Peso,
  Preco,
  BtnAdicionar,
  Container,
} = StyleIncrementosProps;

function IncrementosProps({ imagem, nome, peso, preco }) {
  return (
    <>
      <ConteudoPrincipal>
        <ContainerImagem>
          <Images src={imagem} alt="imgProduto" />
        </ContainerImagem>
        <Container>
          <ContainerInformacoes>
            <Nome>{nome}</Nome>
            <Peso>{peso}</Peso>
            <Preco>{preco}</Preco>
          </ContainerInformacoes>
          <BtnAdicionar>Adicionar</BtnAdicionar>
        </Container>
      </ConteudoPrincipal>
    </>
  );
}

export default IncrementosProps;