import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { keyframes } from "styled-components";

const typewriterAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

const blinkAnimation = keyframes`
  from {
    border-right: 12px solid #4DA768;
  }
  to {
    border-right: 12px solid transparent;
  }
`;

export const AirPlaneIcon = styled(FontAwesomeIcon)`
  color: #fff;
  margin-left: 5px;
`;

const HomeStyle = {
  Principal: styled.main`
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16vh;
    margin-top: 5vh;
    overflow-x: hidden;

    @media screen and (max-width: 682px) {
      margin-bottom: 10vh;
    }

    @media screen and (max-width: 393px) {
      padding: 0 2vh;
    }
  `,

  Slogan: styled.section`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;

    @media screen and (max-width: 923px) {
      margin-bottom: 8vh;
    }

    @media screen and (max-width: 682px) {
      margin-bottom: 6vh;
      flex-direction: column;
    }
  `,

  ContainerAds: styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10vw;

    @media screen and (max-width: 682px) {
      margin-right: 0;
      text-align: center;
    }
  `,

  FraseEfeito: styled.h6`
    color: #969696;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;

    @media screen and (max-width: 1000px) {
      font-size: 15px;
    }

    @media screen and (max-width: 822px) {
      font-size: 14px;
    }

    @media screen and (max-width: 682px) {
      font-size: 13px;
    }
  `,

  TituloSlogan1: styled.h2`
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #26874e;
    font-size: 28px;
    margin-top: 20px !important;
    white-space: nowrap;
    overflow: hidden;
    animation: ${typewriterAnimation} 2s steps(13) alternate,
      ${blinkAnimation} 1000ms steps(13) normal;
    animation-delay: 0.5s;
    border-right: 1px solid transparent;

    @media screen and (max-width: 999px) {
      font-size: 24px;
    }

    @media screen and (max-width: 821px) {
      font-size: 20px;
    }

    @media screen and (max-width: 682px) {
      margin-top: 10px;
    }
  `,

  TituloSlogan2: styled.h2`
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #26874e;
    font-size: 28px;
    white-space: nowrap;
    overflow: hidden;
    animation: ${typewriterAnimation} 2s steps(13) alternate,
      ${blinkAnimation} 1000ms steps(13) normal;
    animation-delay: 2.5s;
    border-right: 1px solid transparent;

    @media screen and (max-width: 999px) {
      font-size: 24px;
    }

    @media screen and (max-width: 821px) {
      font-size: 20px;
    }
  `,

  TituloSlogan3: styled.h2`
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #26874e;
    font-size: 28px;
    white-space: nowrap;
    overflow: hidden;
    animation: ${typewriterAnimation} 2s steps(13) alternate,
      ${blinkAnimation} 1000ms steps(13) normal;
    animation-delay: 4.5s;
    border-right: 1px solid transparent;

    @media screen and (max-width: 999px) {
      font-size: 24px;
    }

    @media screen and (max-width: 821px) {
      font-size: 20px;
    }
  `,

  BtnDefault: styled.button`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none;
    background: #4da768;
    color: #fff;
    font-family: "Poppins", sans-serif;
    padding: 5px 25px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.5s;
    margin-top: 20px;

    &:hover {
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029a9;
    }

    @media screen and (max-width: 999px) {
      font-size: 14px;
    }

    @media screen and (max-width: 682px) {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  `,

  TituloDefault: styled.h2`
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
    text-shadow: 0.1em 0.1em 0.2em #1b202927;

    @media screen and (max-width: 1023px) {
      font-size: 25px;
    }

    @media screen and (max-width: 1000px) {
      font-size: 22px;
    }

    @media screen and (max-width: 822px) {
      font-size: 20px;
    }

    @media screen and (max-width: 438px) {
      font-size: 18px;
    }
  `,

  ContainerAllCards: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    width: 100vw;

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 682px) {
      margin-top: 3vh;
    }
  `,

  Apresentacao: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15vh;

    @media screen and (max-width: 923px) {
      margin-top: 4vh;
    }

    @media screen and (max-width: 885px) {
      flex-direction: column;
    }
  `,

  Computer: styled.img`
    width: 520px;
    height: 100%;

    @media screen and (max-width: 1095px) {
      width: 420px;
    }

    @media screen and (max-width: 999px) {
      width: 400px;
    }

    @media screen and (max-width: 821px) {
      width: 300px;
    }
  `,

  ImgEmpresa: styled.img`
    margin-right: 10vw;

    @media screen and (max-width: 1023px) {
      width: 400px;
    }

    @media screen and (max-width: 1000px) {
      width: 330px;
    }

    @media screen and (max-width: 924px) {
      width: 300px;
    }

    @media screen and (max-width: 885px) {
      margin-right: 0;
    }
  `,

  TextoDefault: styled.h4`
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500;

    @media screen and (max-width: 1023px) {
      font-size: 16px;
    }

    @media screen and (max-width: 1000px) {
      font-size: 14px;
    }

    @media screen and (max-width: 885px) {
      text-align: center;
    }
  `,

  Servicos: styled.div`
    margin-top: 12vh;

    @media screen and (max-width: 923px) {
      margin-top: 5vh;
    }
  `,

  ContainerAllItems: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;

    @media screen and (max-width: 885px) {
      display: flex;
      flex-direction: column;
    }
  `,

  ContainerOneItem: styled.div`
    margin-right: 5vw;
    margin-left: 5vw;
  `,

  ImageLoja: styled.img`
    @media screen and (max-width: 1113px) {
      width: 350px;
    }

    @media screen and (max-width: 1033px) {
      width: 300px;
    }
  `,

  Vantagens: styled.div`
    margin-top: 12vh;

    @media screen and (max-width: 923px) {
      margin-top: 3vh;
    }

    @media screen and (max-width: 885px) {
      display: flex;
      flex-direction: column;
    }
  `,

  ImgVantagens: styled.img`
    width: 420px;

    @media screen and (max-width: 1095px) {
      width: 300px;
    }

    @media screen and (max-width: 821px) {
      width: 290px;
    }

    @media screen and (max-width: 846px) {
      margin-bottom: 2vh;
    }
  `,

  AllEmpreendedores: styled.div`
    margin-top: 9vh;
    text-align: center;

    @media screen and (max-width: 846px) {
      margin-top: 3vh;
    }
  `,

  ContainerTextoLista: styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  `,

  DefaultSubtitulo: styled.h4`
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 18px;

    @media screen and (max-width: 1023px) {
      font-size: 16px;
    }

    @media screen and (max-width: 1000px) {
      font-size: 14px;
    }

    @media screen and (max-width: 885px) {
      text-align: center;
    }

    &#space {
      @media screen and (max-width: 436px) {
        margin-top: 1vh;
      }
    }
  `,

  CategoriasEmpresa: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    margin-top: 7vh;

    @media screen and (max-width: 885px) {
      grid-template-columns: repeat(2, 1fr);
    }
  `,

  ListaCategorias: styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @media screen and (max-width: 885px) {
      width: 60%;
    }
  `,

  Propostas: styled.div`
    background-color: #4da768;
    width: 100vw;
    height: 25vh;
    margin-top: 12vh;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 846px) {
      margin-top: 7vh;
    }

    @media screen and (max-width: 744px) {
      height: 20vh;
    }

    @media screen and (max-width: 623px) {
      justify-content: center;
    }

    @media screen and (max-width: 430px) {
      height: 25vh;
      text-align: center;
      flex-direction: column;
    }
  `,

  Pizza: styled.img`
    width: 400px;
    transform: rotate(-25.5deg);
    margin-left: 5vw;

    @media screen and (max-width: 1160px) {
      width: 300px;
    }

    @media screen and (max-width: 978px) {
      width: 270px;
    }

    @media screen and (max-width: 886px) {
      width: 230px;
    }

    @media screen and (max-width: 744px) {
      width: 190px;
    }

    @media screen and (max-width: 623px) {
      display: none;
    }
  `,

  TextoProposta: styled.h1`
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    color: #fff;
    font-size: 36px;

    @media screen and (max-width: 1210px) {
      font-size: 30px;
    }

    @media screen and (max-width: 1137px) {
      font-size: 26px;
    }

    @media screen and (max-width: 911px) {
      font-size: 20px;
    }

    @media screen and (max-width: 722px) {
      font-size: 16px;
    }

    @media screen and (max-width: 430px) {
      margin-bottom: 4vh;
    }
  `,

  FormProposta: styled.form`
    margin-left: 5vw;
  `,

  Email: styled.input`
    border: none;
    border-radius: 5px;
    background-color: #fff;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 240px;
    margin-right: 10px;

    &:focus {
      outline: none;
    }

    @media screen and (max-width: 1060px) {
      width: 200px;
    }

    @media screen and (max-width: 1016px) {
      width: 180px;
    }

    @media screen and (max-width: 992px) {
      width: 150px;
      font-size: 12px;
    }

    @media screen and (max-width: 830px) {
      width: 120px;
      font-size: 10px;
    }

    @media screen and (max-width: 646px) {
      width: 100px;
      font-size: 10px;
    }

    @media screen and (max-width: 646px) {
      width: 160px;
      font-size: 12px;
    }
  `,

  BtnEnviarEmail: styled.button`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: none;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.5s;

    &:hover {
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #26874e;
    }

    @media screen and (max-width: 992px) {
      font-size: 12px;
    }

    @media screen and (max-width: 830px) {
      font-size: 12px;
    }
  `,

  Parceiros: styled.div`
    margin-top: 9vh;

    @media screen and (max-width: 846px) {
      margin-top: 7vh;
    }
  `,

  SaibaMais: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100vw;
  `,

  ContainerParceria: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 885px) {
      flex-direction: column;
    }
  `,

  ImgTogether: styled.img`
    width: 500px;

    @media screen and (max-width: 1095px) {
      width: 400px;
    }

    @media screen and (max-width: 821px) {
      width: 350px;
    }
  `,

  ContainerTextoParceria: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,

  ContainerImgParceiros: styled.div`
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 885px) {
      margin-top: 4vh;
      grid-template-columns: repeat(2, 1fr);
    }
  `,

  ContainerImagensParceiros: styled.div``,

  ImgPayPal: styled.img`
    width: 200px;

    @media screen and (max-width: 1129px) {
      width: 150px;
    }

    @media screen and (max-width: 978px) {
      width: 120px;
    }

    @media screen and (max-width: 846px) {
      margin-top: 2vh;
    }
  `,

  ImgSendGrid: styled.img`
    width: 200px;

    @media screen and (max-width: 1129px) {
      width: 150px;
    }

    @media screen and (max-width: 978px) {
      width: 120px;
    }
  `,

  ImgRegistroBr: styled.img`
    width: 150px;

    @media screen and (max-width: 1129px) {
      width: 120px;
    }

    @media screen and (max-width: 978px) {
      width: 100px;
    }
  `,

  ImgAws: styled.img`
    width: 100px;

    @media screen and (max-width: 1129px) {
      width: 80px;
    }

    @media screen and (max-width: 978px) {
      width: 50px;
    }
  `,

  Contato: styled.div`
    margin-top: 6vh;

    @media screen and (max-width: 846px) {
      margin-top: 3vh;
    }
  `,

  ContainerImagemFormularioContato: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;

    @media screen and (max-width: 923px) {
      margin-top: 8vh;
    }

    @media screen and (max-width: 682px) {
      margin-bottom: 6vh;
    }
  `,

  ImgContato: styled.img`
    width: 500px;

    @media screen and (max-width: 1095px) {
      width: 450px;
    }

    @media screen and (max-width: 885px) {
      display: none;
    }
  `,

  FormContato: styled.form`
    background-color: #99e89db6;
    width: 40vw;
    height: 70vh;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;

    @media screen and (max-width: 885px) {
      width: 65vw;
    }

    @media screen and (max-width: 618px) {
      width: 70vw;
    }

    @media screen and (max-width: 475px) {
      width: 75vw;
    }
  `,

  TituloInputs: styled.label`
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    align-self: flex-start;
    margin-left: 25px;

    @media screen and (max-width: 1023px) {
      font-size: 14px;
    }

    @media screen and (max-width: 681px) {
      margin-left: 20px;
    }

    @media screen and (max-width: 410px) {
      margin-left: 15px;
    }
  `,

  InputsContato: styled.input`
    border-radius: 5px;
    border: none;
    width: 90%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px;
    padding-left: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
      box-shadow: 0 0 5pt 0.5pt #d3d3d3;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 5pt 2pt #d3d3d3;
    }

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  `,

  Mensagem: styled.textarea`
    border-radius: 5px;
    border: none;
    width: 90%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px;
    padding-left: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;

    &:focus {
      outline: none;
      box-shadow: 0 0 5pt 2pt #d3d3d3;
    }

    @media screen and (max-width: 1023px) {
      font-size: 12px;
    }
  `,

  BtnEnviarMensagem: styled.button`
    align-self: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: none;
    background-color: #26874e;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.5s;
    margin-top: 30px;

    &:hover {
      transition: all 0.5s;
      background-color: #e1fae2;
      color: #1b2029d0;
    }

    &:hover ${AirPlaneIcon} {
      color: #1b2029d0;
    }

    @media screen and (max-width: 1023px) {
      font-size: 14px;
    }
  `,

  Camada: styled.div`
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0%;
  `,
};

export default HomeStyle;