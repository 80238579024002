import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StyleHeader from "../../../assets/styles/Admin/Header/StyleHeader";
import Logo from "../../../assets/img/logo.png";
import DropdownAdmin from "../Dropdown/Dropdown.jsx";

const { Cabecalho, ImgLogo, Pages, LinkStyle } = StyleHeader;

function HeaderAdmin() {
  const location = useLocation();

  return (
    <>
      <Cabecalho>
        <ImgLogo src={Logo} alt="logo" to={"/admin"} />
        <LinkStyle to={"/admin"}>
          <Pages
            style={{
              color: `${location.pathname === "/admin" ? "#4da768" : ""}`,
            }}
          >
            Home
          </Pages>
        </LinkStyle>
        <LinkStyle to={"/admin/exibir_cardapio"}>
          <Pages
            style={{
              color: `${
                location.pathname === "/admin/exibir_cardapio" ? "#4da768" : ""
              }`,
            }}
          >
            Cardápio
          </Pages>
        </LinkStyle>
        {/* <Pages>Institucional</Pages>
        <Pages>Controles de filas e carros</Pages> */}
        <DropdownAdmin />
      </Cabecalho>
    </>
  );
}

export default HeaderAdmin;
