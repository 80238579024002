import Computer from "../../assets/img/Img_planos/img_computer.png";
import Check from "../../assets/img/Img_planos/Check.svg";
import Error from "../../assets/img/Img_planos/Error.svg";
import PlanosStyle from "../../assets/styles/Planos/PlanosStyle.js";
import React, { useEffect } from "react";

const {
  Banner,
  Title,
  Imagem,
  SubTitle,
  Titulo,
  Button2,
  Plans,
  PlanoCard,
  Name,
  NameGreen,
  Preco,
  Span,
  DivLista,
  CheckImage,
  ItemLlisa,
  Comment,
  Title2,
  Button3,
  Button4,
  DivCompare,
  Linha1,
  Title3,
  BtnBasico,
  BtnAvancado,
  Texto,
  Button5,
  Button6,
  Linha2,
  Linha3,
  P,
  Verde,
  Red,
  GreenText,
} = PlanosStyle;

function PlanosBody() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner>
        <Title>
          <SubTitle> Seu pedido, nossa demanda. </SubTitle>
          <Titulo>
            {" "}
            Encontre o plano ideal <br /> para o seu negócio.{" "}
          </Titulo>
          <SubTitle>
            {" "}
            Oferecemos opções de menu digital que ajudam a melhorar <br /> suas
            vendas de forma eficiente e a reduzir os gastos.{" "}
          </SubTitle>
          <Button2>Saiba mais</Button2>
        </Title>
        <Imagem src={Computer} />
      </Banner>

      <Plans>
        <PlanoCard>
          <Name> Básico </Name>
          <Preco>
            {" "}
            R$ <Span> 60 </Span>,00/mês{" "}
          </Preco>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Cardápio digital com QR Code </ItemLlisa>
          </DivLista>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Design visual personalizado </ItemLlisa>
          </DivLista>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Registro de pedidos</ItemLlisa>
          </DivLista>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Acrescentar comentários no pedido </ItemLlisa>
          </DivLista>

          {/* Colocar rota para a página de pagamento assim que ela estiver pronta */}
          <Button3>Assinar</Button3>
        </PlanoCard>

        <PlanoCard>
          <NameGreen> Grátis </NameGreen>
          <Preco>
            {" "}
            R$ <Span> 0 </Span>,00/mês{" "}
          </Preco>
          <Comment> Até 30 dias gratuito </Comment>
          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Cardápio digital com QR Code </ItemLlisa>
          </DivLista>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Design visual personalizado </ItemLlisa>
          </DivLista>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Registro de pedidos</ItemLlisa>
          </DivLista>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Foto dos produtos</ItemLlisa>
          </DivLista>

          {/* Colocar rota para a página de pagamento assim que ela estiver pronta */}
          <Button4>Assinar</Button4>
        </PlanoCard>

        <PlanoCard>
          <Name> Avançado </Name>
          <Preco>
            {" "}
            R$ <Span> 150 </Span>,00/mês{" "}
          </Preco>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Cardápio digital com QR Code </ItemLlisa>
          </DivLista>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>
              Personalização de horário dos <br /> produtos
            </ItemLlisa>
          </DivLista>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Solicitar garçom</ItemLlisa>
          </DivLista>

          <DivLista>
            <CheckImage src={Check} alt="Check" />
            <ItemLlisa>Favoritos da casa</ItemLlisa>
          </DivLista>

          {/* Colocar rota para a página de pagamento assim que ela estiver pronta */}
          <Button3>Assinar</Button3>
        </PlanoCard>
      </Plans>

      <Title2>
        {" "}
        Compare os <GreenText>planos</GreenText>{" "}
      </Title2>

      <DivCompare>
        <Linha1>
          <Title3> Recursos e capacidades </Title3>
          <BtnBasico>
            <Texto> Básico </Texto>
            {/* Colocar rota para a página de pagamento assim que ela estiver pronta */}
            <Button5>Assinar</Button5>
          </BtnBasico>
          <BtnAvancado>
            <Texto> Avançado </Texto>

            {/* Colocar rota para a página de pagamento assim que ela estiver pronta */}
            <Button6>Assinar</Button6>
          </BtnAvancado>
        </Linha1>

        <Linha2>
          <P>Cardápio digital com QR Code </P>
          <Verde src={Check} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha2>

        <Linha3>
          <P>Foto dos produtos </P>
          <Verde src={Check} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha3>

        <Linha2>
          <P>Identidade visual personalizada </P>
          <Verde src={Check} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha2>

        <Linha3>
          <P>Registro de pedidos</P>
          <Verde src={Check} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha3>

        <Linha2>
          <P>Adicionar notas no pedido</P>
          <Verde src={Check} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha2>

        <Linha3>
          <P>Gestor de cozinha</P>
          <Verde src={Check} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha3>

        <Linha2>
          <P>Personalização de horário nos produtos</P>
          <Red src={Error} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha2>

        <Linha3>
          <P>Solicitar garçom</P>
          <Red src={Error} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha3>

        <Linha2>
          <P>Divulgações / banners</P>
          <Red src={Error} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha2>

        <Linha3>
          <P>Produtos em destaque </P>
          <Red src={Error} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha3>

        <Linha2>
          <P>Acompanhar status do pedido</P>
          <Red src={Error} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha2>

        <Linha3>
          <P>Pesquisa de satisfação </P>
          <Red src={Error} alt="Check" />
          <Verde src={Check} alt="Check" />
        </Linha3>
      </DivCompare>
    </>
  );
}

export default PlanosBody;