import StylePedidosCliente from "../../assets/styles/Pedidos/StyleHomePedidos";
import HeaderDefault from "./Header/HeaderDefault";
import ImageQrCode from "../../assets/img/qrcode-example.png";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const { ConteudoPrincipal, Image, Descricao, BtnFazerPedido, Icons } =
  StylePedidosCliente;

function HomePedidos() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/cliente/cardapio");
  };

  return (
    <>
      <HeaderDefault
        nomeRestaurante={"QUICKFOOD"}
        backGroundColor={"#4FAE6C"}
        height={"10vh"}
      />
      <ConteudoPrincipal>
        <Image src={ImageQrCode} alt="imageQrCode" />
        <Descricao>
          Você escolhe os itens e o pedido <br /> cai direto na nossa cozinha!
        </Descricao>
        <BtnFazerPedido onClick={handleClick}>
          Fazer pedido <Icons icon={faArrowRight} />
        </BtnFazerPedido>
      </ConteudoPrincipal>
    </>
  );
}

export default HomePedidos;