import BodyAdminPrincipal from "../../components/Admin/Admin_Body";
import HeaderAdmin from "../../components/Admin/Header/Header_Admin";

function AdminPrincipal() {
  return (
    <>
      <HeaderAdmin />
      <BodyAdminPrincipal />
    </>
  );
}

export default AdminPrincipal;